import React from 'react'
import Spinner from '../../images/loading-spinner.png'
import '../../css/Spinner.css'

const FullPageLoader = () => {
  return (
    <div className='spinner-container' style={{ zIndex: '10000'}}>
        <img src={Spinner} alt='loading' />
    </div>
  )
}

export default FullPageLoader