import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import Footer from '../components/Footer';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import SideNav from '../components/SideNav';
import Header from '../components/Header';

const ActivityCompare = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedTag, setSelectedTag] = useState('');
  const [responseData, setResponseData] = useState(location.state?.data.data || []);

  useEffect(() => {
    if (!location.state?.data.data) {
      const storedData = sessionStorage.getItem('responseData');
      if (storedData) {
        setResponseData(JSON.parse(storedData).data);
      }
    }
  }, [location.state?.data.data]);

  const handleTagChange = (tag) => {
    setSelectedTag(tag);
  };

  const rowsWithIds = responseData.map((row, index) => ({
    id: index,
    ...row,
  }));

  const filteredRows = rowsWithIds.filter((row) => {
    if (selectedTag === '') return true;
    const percentChange = row.Percent_change;
    const copPrev = row.count_of_pattern_Previous;

    switch (selectedTag) {
      case 'New':
        return copPrev === 0;
      case 'Increasing':
        return percentChange > 0 && copPrev !== 0;
      case 'Unchanged':
        return percentChange === 0 && copPrev !== 0;
      case 'Decreasing':
        return percentChange < 0 && percentChange !== -100 && copPrev !== 0;
      case 'Disappeared':
        return percentChange === -100;
      default:
        return true;
    }
  });

  const columns = [
    { field: 'Pattern', headerName: 'Pattern', flex: 4, cellClassName: 'word-wrap' },
    { field: 'count_of_pattern_Current', headerName: 'Issue Count', flex: 1 },
    { field: 'count_of_pattern_Previous', headerName: 'OK Count', flex: 1 },
    { field: 'Difference', headerName: 'Difference', flex: 1 },
    {
      field: 'tags',
      headerName: 'Tags',
      flex: 1.1,
      cellClassName: (params) => {
        let className = 'word-wrap ';

        const percentChange = params.row.Percent_change;
        const copPrev = params.row.count_of_pattern_Previous;
        if (copPrev === 0) {
          className += 'tag-increasing';
        } else if (percentChange > 0) {
          className += 'tag-new';
        } else if (percentChange === 0) {
          className += 'tag-unchanged';
        } else if (percentChange < 0 && percentChange !== -100) {
          className += 'tag-decreasing';
        } else if (percentChange === -100) {
          className += 'tag-disappeared';
        }

        return className;
      },
      valueGetter: (params) => {
        const percentChange = params.row.Percent_change;
        const copPrev = params.row.count_of_pattern_Previous;

        if (copPrev === 0) {
          return 'New';
        } else if (percentChange > 0) {
          return 'Increasing';
        } else if (percentChange === 0) {
          return 'Unchanged';
        } else if (percentChange < 0 && percentChange !== -100) {
          return 'Decreasing';
        } else if (percentChange === -100) {
          return 'Disappeared';
        } else {
          return '';
        }
      },
    },
    { field: 'Percent_change', headerName: '% Change', flex: 1 },
  ];

  return (
    <>
      <SessionExpiredAlert />
      <SideNav />
      <Header />
      <div className="content-wrapper">
        <Box sx={{ padding: 2, height: 'calc(100vh - 100px)', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            {/* <Button
              variant='contained'
              sx={{ marginRight: 2 }}
              onClick={() => navigate('/activities')}
            >
              Back
            </Button> */}
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                gap: 0
              }}
            >
              {['All', 'New', 'Increasing', 'Unchanged', 'Decreasing', 'Disappeared'].map((tag) => (
                <Button
                  key={tag}
                  variant={selectedTag === (tag === 'All' ? '' : tag) ? 'contained' : 'outlined'}
                  onClick={() => handleTagChange(tag === 'All' ? '' : tag)}
                  sx={{ borderRadius: 0 }}
                >
                  {tag}
                </Button>
              ))}
            </Box>
          </Box>
          <div style={{ height: '90%', width: '100%' }}>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#060694',
                  color: '#fff',
                },
                '& .MuiDataGrid-row:nth-of-type(even)': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
          </div>
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default ActivityCompare;