import Footer from "../components/Footer";
import SideNav from "../components/SideNav";
import '../App.css';
import LogsHeader from "../components/LogsHeader";
import RealTimeSideNav from "../components/RealTimeSideNav";

const SampleLogs = () => {

    return (
        <>
        <RealTimeSideNav />
        <LogsHeader />
        <Footer />
      </>
    );
  };
  
  export default SampleLogs;