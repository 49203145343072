import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { Email, Lock } from '@mui/icons-material';
import wave1 from '../images/wave_latest.svg';
import login from '../images/login (1).svg';
import wowlogo from '../images/New Wowizer Logo 1.png';
import '../App.css';
import { Link } from 'react-router-dom';
import { Base_URL, Reset_Password } from '../constants';
import swal from 'sweetalert';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import axios from 'axios';

const ResetPassword = () => {
  const [email, setEmail] = useState('');

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  
  const handleSubmit = (e) => {
    showLoader();
    e.preventDefault();
    var data = JSON.stringify({
      "email": email
    });

    var config = {
      method: 'post',
      url: Base_URL + Reset_Password,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      hideLoader();
      swal({
        icon: 'success',
        title: 'Reset Password Link Sent',
        text: 'Please check your email',
      }).then(function() {
        window.location.href = '/'
      });
    })
    .catch(function (error) {
      hideLoader();
      console.log(error);
    });
  };

  return (
    <>
      <img className="wave" src={wave1} />
      <div className="container-login">
        <div className="img">
          <img className='login-image' src={login} />
        </div>
        <div className="login-content">
          <form className='form-class-login' onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <img className='actionate-img' src={wowlogo} width="90%" style={{ marginLeft: '-2%' }} />
              </div>
            </div>
            <div className="row">
              <div className="col">
              <br />
              <div style={{ fontSize: '20px', color: '#000080' }}>Take control of Qlik user experience and drive adoption.</div>
                <br />
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    startAdornment: <Email />,
                  }}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ borderRadius: '50px' }}
                  type='submit'
                >
                  Reset
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                <Link to="/" style={{ textDecoration: 'none', fontWeight: "bolder"}}>
                    Login
                </Link>
              </div>
            </div>
          </form>
        </div>
        {loader}
      </div>
    </>
  );
};

export default ResetPassword;