import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import EqualizerIcon from '@mui/icons-material/Equalizer';
import img from '../../src/images/New Wowizer Logo 1.png'
import AddAlertIcon from '@mui/icons-material/AddAlert';

const AdminSideNav = () => {
  return (
    <>
    <div>
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="/admin/zXcVbNm12" className="brand-link">
        <img src={img} alt="AdminLTE Logo" className="" style={{ opacity: '.8', width: '235px' }} />
      </a>
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li>
              <Link to='/admin/zXcVbNm12' className="nav-link">
                <AddAlertIcon />
                <p>
                  Alerts
                </p>
              </Link>
            </li>
            <li>
              <Link to='/admin/zXcVbNm12' className="nav-link">
                <EqualizerIcon />
                <p>
                  Dashboard
                </p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  </div>
  </>
  )
}

export default AdminSideNav