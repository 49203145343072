import { createChatBotMessage } from 'react-chatbot-kit';
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';

const config = {
  botName: 'Qlik Bot',
  initialMessages: [createChatBotMessage('Hi! How can I assist you today?')],
  customComponents: {},
  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
  state: {},
  widgets: [
    {
      widgetName: 'dashList',
      widgetFunc: (props) => {
        return (
          <ul>
            {props.payload.map((item, index) => (
              <li key={index}>
                <a href="#" onClick={() => props.actionProvider.handleLinkClick(item.link)}>
                  {item.dashboard_title}
                </a>
              </li>
            ))}
          </ul>
        );
      }
    }
  ],
  actionProvider: ActionProvider,
  messageParser: MessageParser,
};

export default config;