import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../components/Footer';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import SelectedDashboard from './SelectedDashboard';
import { Base_URL, Kibana_URL } from '../constants';
import { useCookies } from 'react-cookie';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from '../components/Chatbot/chatbotConfig';
import MessageParser from '../components/Chatbot/MessageParser';
import ActionProvider from '../components/Chatbot/ActionProvider';
import './../css/custom-chatbot.css';
import HistoricalSideNav from '../components/HistoricalSideNav';
import RealTimeSideNav from '../components/RealTimeSideNav';

const AIDriven = () => {
  const [cookies] = useCookies(['access_token', 'sample_token', 'kibana_token']);
  let token = cookies.access_token;
  let kibana_token = cookies.kibana_token;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);

  useEffect(() => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: Base_URL + '/users/dashboard_list',
      headers: { 
        'Cookie': `access_token=${token}`
      }
    };

    axios.request(config)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, [token]);

  const generateLink = (link) => {
    const [base, rest] = link.split('#');
    return `${Kibana_URL}${base}?security_tenant=private&jwt=${kibana_token}#${rest}`;
  };

  const handleLinkClick = (link) => {
    setSelectedLink(generateLink(link));
  };

  const saveMessages = (messages) => {
    localStorage.setItem('chat_messages', JSON.stringify(messages));
  };

  const loadMessages = () => {
    const messages = JSON.parse(localStorage.getItem('chat_messages'));
    return messages || [];
  };

  const contentWrapperStyle = {
    display: 'flex',
    flexDirection: 'row',
  };

  const leftSectionStyle = {
    width: '30%',
    padding: '20px',
    boxSizing: 'border-box',
    borderRight: '1px solid #ccc',
  };

  const rightSectionStyle = {
    width: '70%',
    padding: '20px',
    boxSizing: 'border-box',
  };

  const currentNav = localStorage.getItem('currentNav');
  
  return (
    <>
      {selectedLink ? (
        <SelectedDashboard dashboardLink={selectedLink} />
      ) : (
        <>
          <SessionExpiredAlert />
          {currentNav === 'Real Time' ? <RealTimeSideNav /> : <HistoricalSideNav />}
          <Header />
          <div style={contentWrapperStyle} className="content-wrapper">
            {/* <div style={leftSectionStyle} className="left-section">
              <h2>Common Issues</h2>
              {error && <div>Error: {error.message}</div>}
              {data ? (
                <ul>
                  {data.dash_list.map((item, index) => (
                    <li key={index} style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                      {item.link ? (
                        <a href="#" onClick={() => handleLinkClick(item.link)}>
                          {item.title}
                        </a>
                      ) : (
                        item.title
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <div>Loading...</div>
              )}
            </div> */}
            <div style={rightSectionStyle} className="right-section">
              <Chatbot
                config={{
                  ...config,
                  widgets: [
                    {
                      widgetName: 'dashList',
                      widgetFunc: (props) => {
                        return (
                          <ul>
                            {props.payload.map((item, index) => (
                              <li key={index}>
                                <a href="#" onClick={() => handleLinkClick(item.link)}>
                                  {item.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        );
                      }
                    }
                  ]
                }}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
                messageHistory={loadMessages()}
                saveMessages={saveMessages}
              />
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default AIDriven;