import { useEffect, useState } from "react";
import { FcManager } from "react-icons/fc"
import { FcSettings } from "react-icons/fc";
import axios from 'axios';
import { Base_URL, Logout_User, User } from "../constants";
import { Button, Menu, MenuItem } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import IntroJs from 'intro.js';
import 'intro.js/introjs.css';
import 'intro.js/themes/introjs-modern.css';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import swal from 'sweetalert';
import { NoBackpackSharp } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import useFullPageLoader from "./hooks/useFullPageLoader";

const SocHeader = () => {
  const [firstname, setFirstName] = useState('');

  useEffect(() => {
    fetchData('');
  }, []);

  const fetchData = async => {
      var config = {
          method: 'get',
          url: Base_URL + User,
          headers: { }
        };
        
        axios(config)
        .then(function (response) {
          setFirstName(response.data.data.user.firstName);
          localStorage.setItem('first_name', response.data.data.user.firstName);
          localStorage.setItem('tenant_name', response.data.data.subscription.tenantName);
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const [cookies, setCookie, removeCookie] = useCookies();

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    showLoader();
    var config = {
      method: 'get',
      url: Base_URL + Logout_User,
      headers: {}
    };

    axios(config)
        .then(function (response) {
          hideLoader();
          sessionStorage.clear();
          localStorage.clear();
          // Object.keys(cookies).forEach((cookieName) => {
          //   removeCookie(cookieName);
          // });
          window.location.href = '/';
        })
        .catch(function (error) {
          var config = {
          method: 'get',
          url: Base_URL + '/auth/refresh',
          headers: { }
          };

          axios(config)
          .then(function (response) {
            var config = {
              method: 'get',
              url: Base_URL + Logout_User,
              headers: {}
            };
            axios(config)
            .then(function (response) {
              hideLoader();
              localStorage.clear();
              // Object.keys(cookies).forEach((cookieName) => {
              //   removeCookie(cookieName);
              // });
              handleMenuClose();
              window.location.href = '/';
            })
          })
          .catch(function (error) {
            hideLoader();
            console.log(error);
          });
        });
  };

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
          </li>
          <div className="welcome-div"
            onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
          >
            <FcSettings size={30}/> Welcome, {firstname}
          <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogout} style={{ width: '150px' }}>Logout</MenuItem>
          </Menu>
          </div>
        </ul>
      </nav>
      {loader}
    </div>
  )
}

export default SocHeader