import React from 'react';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FcAddressBook, FcFeedback, FcInfo, FcLink, FcSearch } from 'react-icons/fc';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import { Button } from '@mui/material';
import { Base_URL } from '../constants';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import swal from 'sweetalert';
import RealTimeSideNav from '../components/RealTimeSideNav';
import HistoricalSideNav from '../components/HistoricalSideNav';

const Help = () => {

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [cookies] = useCookies(['access_token', 'sample_token', 'kibana_token']);
  let token = cookies.access_token;

  const handleSupport = () => {
    showLoader();
    let data = '';

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: Base_URL + '/auth/support',
      headers: { 
        'Cookie': token
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      hideLoader();
      if (response.data.user_exists === true) {
        window.open('https://support.wowizer.ai/#login', '_blank');
      } else {
        swal({
          title: "Do you want to create a support account?",
          icon: "info",
          buttons: {
              cancel: "No",
              confirm: "Yes",
          },
      })
      .then((value) => {
          if (value) {
              createSupportAccount();
          }
      });
      }    
    })
    .catch((error) => {
      hideLoader();
      console.log(error);
    });
  };

  const createSupportAccount = () => {
    showLoader();
    let data = '';

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/auth/support',
        headers: { 
            'Cookie': token
        },
        data: data
    };

    axios.request(config)
    .then((response) => {
        hideLoader();
        swal({
          title: "Your Support account is created successfully.",
          text: "Please check your email for login credentials.",
          icon: "success",
        })
    })
    .catch((error) => {
        hideLoader();
        console.log(error);
    });
  };

  const currentNav = localStorage.getItem('currentNav');
  return (
    <>
      <SessionExpiredAlert />
      {currentNav === 'Real Time' ? <RealTimeSideNav /> : <HistoricalSideNav />}
      <Header />
      <div>
        <div className="content-wrapper">
          <br />
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '6%' }}>
            <div className="card" style={{ width: '40%', marginRight: '5%', marginLeft: '5%' }}>
              <div className="card-header border-0">
                <h3 className="card-title"><b>Help</b></h3>
              </div>
              <div className="card-body">
                {/* <div className="d-flex align-items-center border-bottom mb-3">
                  <p className="text-success text-xl">
                    <FcFeedback />
                  </p>
                  <p style={{ marginLeft: '3%' }}>
                    <span className="font-weight-bold">
                      For Technical assistance you can reach us at <br />
                      <a href="mailto:support@wowizer.ai">support@wowizer.ai</a>
                    </span>
                  </p>
                </div> */}
                <div className="d-flex align-items-center border-bottom mb-3">
                  <p className="text-success text-xl">
                    <FcAddressBook />
                  </p>
                  <p style={{ marginLeft: '3%' }}>
                    <span className="font-weight-bold">
                      For Commercial queries please write to <br />
                      <a href="mailto:info@wowizer.ai">info@wowizer.ai</a>
                    </span>
                  </p>
                </div>
                <div className="d-flex align-items-center border-bottom mb-3">
                  <p className="text-success text-xl">
                    <FcLink />
                  </p>
                  <p style={{ marginLeft: '3%' }}>
                    <span className="font-weight-bold">
                      More Info on setup / Configuration & Usage<br />
                      <a href="https://wowizer.ai/wiki/wowizer-tpm/about-wowizer-tpm/" 
                      target="_blank" 
                      rel="noopener noreferrer">visit wiki</a>
                    </span>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-0">
                  <p className="text-success text-xl">
                    <FcInfo />
                  </p>
                  <p style={{ marginLeft: '3%' }}>
                    <span className="font-weight-bold">
                      Version <br />
                      <a href="Release Notes Wowizer TPM Version 5.1.pdf" target="_blank" rel="noopener noreferrer">5.1</a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div style={{ width: '60%' }}>
            <div className="card" style={{ width: '90%' }}>
              <div className="card-header border-0">
                <h3 className="card-title"><b>Support</b></h3>
              </div>
              <div className="card-body">
                <div className="d-flex align-items-center border-bottom mb-3">
                  <p className="text-success text-xl">
                    <FcFeedback />
                  </p>
                  <p style={{ marginLeft: '3%' }}>
                    <span className="font-weight-bold">
                      For Technical assistance you can reach us at <br />
                      <a href="mailto:support@wowizer.ai">support@wowizer.ai</a>
                    </span>
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="text-success text-xl">
                    <FcSearch />
                  </p>
                  <p style={{ marginLeft: '3%' }}>
                    <span className="font-weight-bold">
                      {/* Create & Manage your tickets<br /> */}
                      {/* <a href="https://support.wowizer.ai/#login" target="_blank" rel="noopener noreferrer">Visit here</a> */}
                      <Button variant="contained" onClick={handleSupport}>Create & Manage your tickets here</Button>
                    </span>
                  </p>
                </div>
                {/* <div className="d-flex align-items-center">
                  <p className="text-success text-xl">
                    <FcServices />
                  </p>
                  <p style={{ marginLeft: '3%' }}>
                    <span className="font-weight-bold">
                      Manage ticket<br />
                      <a href="https://support.wowizer.ai/#login" target="_blank" rel="noopener noreferrer">here</a>
                    </span>
                  </p>
                </div> */}
                {/* <div className="d-flex align-items-center mb-0">
                  <p className="text-success text-xl">
                    <FcInfo />
                  </p>
                  <p style={{ marginLeft: '3%' }}>
                    <span className="font-weight-bold">
                      Version <br />
                      <a href="">4.4.0</a>
                    </span>
                  </p>
                </div> */}
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
      <Footer />
    </>
  )
}

export default Help;