import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ClusterDataDemo from './ClusterData';
import Activities from './Activities';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import Footer from '../components/Footer';

const AnomalyTabs = () => {
  const navigate = useNavigate();
  const tenant = localStorage.getItem('tenant_name');
  const initialTabValue = tenant === 'd293axplcju2njyxmdi' ? 1 : 0;
  const [value, setValue] = React.useState(initialTabValue);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <SessionExpiredAlert />
      <SideNav />
      <Header />
      <div className="content-wrapper">
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="anomaly detection tabs"
          >
            <Tab label="Time Based Anomaly" />
            {tenant === 'd293axplcju2njyxmdi' && <Tab label="Event Based Anomaly" />}
          </Tabs>
          <Box sx={{ p: 3 }}>
            {value === 0 && <ClusterDataDemo />}
            {value === 1 && tenant === 'd293axplcju2njyxmdi' && <Activities />}
          </Box>
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default AnomalyTabs;