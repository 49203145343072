import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Footer from '../components/Footer';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import { useCookies } from 'react-cookie';
import { Base_URL } from '../constants';
import swal from 'sweetalert';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import SocSideNav from '../components/SocSideNav';
import SocHeader from '../components/SocHeader';

const MultiTenant = () => {
  const [cookies] = useCookies(['access_token', 'sample_token', 'kibana_token']);
  let token = cookies.access_token;
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState('');
  const [tenantAccessToken, setTenantAccessToken] = useState('');
  const navigate = useNavigate();
    
  useEffect(() => {
    const fetchTenants = async () => {
      showLoader();
      try {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: Base_URL + '/auth/list_tenants',
          headers: { 
            'Cookie': `access_token=${token}`
          },
          data: ''
        };
        
        const response = await axios.request(config);
        setTenants(response.data);
        hideLoader();
      } catch (error) {
        setError(error);
        hideLoader();
      } finally {
        setLoading(false);
        hideLoader();
      }
    };

    fetchTenants();
  }, [token]);

  useEffect(() => {
    if (!loading && tenants.length > 0) {
      const options = tenants.reduce((acc, tenant) => {
        acc[tenant.tenantName] = tenant.companyName;
        return acc;
      }, {});

      let selectElement = document.createElement('select');
      selectElement.innerHTML = Object.entries(options).map(
        ([value, label]) => `<option value="${value}">${label}</option>`
      ).join('');

      selectElement.addEventListener('change', (event) => {
        setSelectedTenant(event.target.value);
      });

      swal({
        title: "Select Customer",
        content: selectElement,
        buttons: {
          confirm: {
            text: "Select",
            closeModal: true
          }
        }
      })
      .then((confirmed) => {
        showLoader();
        if (confirmed && selectElement.value) {
          const selectedValue = selectElement.value;
          setSelectedTenant(selectedValue);
          const data = JSON.stringify({ tenant: selectedValue });

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: Base_URL + '/auth/get_users_token',
            headers: { 
              'Content-Type': 'application/json',
              'Cookie': `access_token=${token}`
            },
            data: data
          };

          axios.request(config)
          .then((response) => {
            hideLoader();
            const { tenant_kibana_access_token } = response.data;
            setTenantAccessToken(tenant_kibana_access_token);
          })
          .catch((error) => {
            hideLoader();
            console.error(error);
          });

        } else {
          swal({
            title: "You need to select a tenant!",
            buttons: {
              cancel: {
                text: "Cancel",
                value: false,
                visible: true,
                closeModal: true
              },
              confirm: {
                text: "OK",
                closeModal: true
              }
            }
          }).then((value) => {
            if (value === false) {
              navigate('/adminportal');
            } else {
              window.location.reload();
            }
          });
        }
      });
    }
  }, [loading, tenants]);

  useEffect(() => {
    if (tenantAccessToken && selectedTenant) {
      navigate('/customerdashboard', {
        state: {
          tenantAccessToken: tenantAccessToken,
          selectedTenant: selectedTenant
        }
      });
    }
  }, [tenantAccessToken, selectedTenant, navigate]);
  
  return (
    <>
      <SessionExpiredAlert />
      <SocSideNav />
      <SocHeader />
      <div>
        <div className="content-wrapper">
          
        </div>
      </div>
      {loader}
      <Footer />
    </>
  );
};

export default MultiTenant;