import React from 'react';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const CustomTooltip = ({ active, payload, label, onClick }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '5px',
        textAlign: 'center',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        cursor: 'pointer',
        position: 'relative'
      }} onClick={onClick}>
        <p className="label">{`Time: ${new Date(dayjs.utc(label, "YYYY-MM-DD HH:mm").toISOString()).toLocaleString()}`}</p>
        <p className="intro">{`Error Count: ${payload[0].value}`}</p>
        <button style={{
          backgroundColor: '#072584',
          color: '#fff',
          border: 'none',
          padding: '5px 10px',
          borderRadius: '3px',
          cursor: 'pointer',
          fontSize: '14px',
          marginTop: '5px'
        }}>
          Set Comparison Point
        </button>
      </div>
    );
  }

  return null;
};

const BarChartComponent = ({ data, onClick }) => {
  const formatTime = (datetime) => {
    return new Date(dayjs.utc(datetime, "YYYY-MM-DD HH:mm").toISOString()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  
  const errorcountlocal = localStorage.getItem("error_count");
  const maxCount = errorcountlocal ? Number(errorcountlocal) : Math.max(...data.map(d => d.error_count));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        barCategoryGap="0%"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="key_as_string" 
          tickFormatter={formatTime}
          label={{ value: 'WoW_Timestamp', position: 'insideBottomRight', offset: -10 }}
        />
        <YAxis label={{ value: 'Error Count', angle: -90, position: 'insideLeft', offset: -10 }}/>
        <Tooltip content={<CustomTooltip onClick={onClick} />} />
        <Legend />
        <Bar 
          dataKey="error_count"
          onClick={onClick}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.error_count === maxCount ? '#fc350a' : '#072584'} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;