import React from 'react';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import axios from 'axios';
import { Base_URL } from '../constants';
import { Box, FormControl, InputLabel, Select, MenuItem, Button, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Footer from '../components/Footer';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import swal from 'sweetalert';
import { DeleteForever } from '@mui/icons-material';
import RealTimeSideNav from '../components/RealTimeSideNav';
import HistoricalSideNav from '../components/HistoricalSideNav';

const Archival = () => {
  const [data, setData] = React.useState([]);
  const [filterStatus, setFilterStatus] = React.useState('');

  const [count, setCount] = React.useState('');

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const archivalretention = localStorage.getItem('archivalretention')
  const liveretention = localStorage.getItem('liveretention')

  const handleRestore = (date) => {
    showLoader();

    if(localStorage.getItem('userRole') === "trialuser")
    {
      hideLoader();
      swal({
        icon: 'info',
        title: 'WoWizer TPM Test Drive does not have the functionality!',
        text: "Kindly proceed with the complete sign-up to unlock all features.",
        buttons: {
          signUp: {
            text: "Complete Sign-up",
            value: "signUp",
            className: "black-text-button",
          },
          cancel: "Cancel",
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      })
        .then((value) => {
          if (value === "signUp") {
            const signUpPageUrl = "/register";
            window.open(signUpPageUrl, '_blank');
          }
        });
        return;
    }

    if (count >= 5 ) {
      hideLoader();
      swal({
        icon: 'warning',
        title: 'Limit Exceeded',
        text: 'The restoration limit of 5 records has been reached. Please delete previously restored records to restore new ones.',
      });
      return;
    }

    var data = JSON.stringify({
    "dates_list": [
        date
    ]
    });

    var config = {
    method: 'post',
    url: Base_URL + '/archival/req_snap_restore/',
    headers: { 
        'Content-Type': 'application/json'
    },
    data : data
    };

    axios(config)
    .then(function (response) {
      hideLoader();
      swal({
        icon: 'success',
        title: 'Index Restoration Initialized.'
      }).then(function() {
        window.location.reload();
      });
    })
    .catch(function (error) {
      hideLoader();
    });

  };

  const handleDelete = (date) => {
    showLoader();

    if(localStorage.getItem('userRole') === "trialuser")
    {
      hideLoader();
      swal({
        icon: 'info',
        title: 'WoWizer TPM Test Drive does not have the functionality!',
        text: "Kindly proceed with the complete sign-up to unlock all features.",
        buttons: {
          signUp: {
            text: "Complete Sign-up",
            value: "signUp",
            className: "black-text-button",
          },
          cancel: "Cancel",
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      })
        .then((value) => {
          if (value === "signUp") {
            const signUpPageUrl = "/register";
            window.open(signUpPageUrl, '_blank');
          }
        });
        return;
    }
    
    swal({
      title: 'Are you sure you want to delete the restored index?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        var data = JSON.stringify({
          "dates_list": [
            date
          ]
        });

        var config = {
          method: 'delete',
          url: Base_URL + '/archival/snap_old_index/',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };

        axios(config)
        .then(function (response) {
          hideLoader();
          swal({
            icon: 'success',
            title: 'Restored Index was deleted'
          }).then(function() {
            window.location.reload();
          });
        })
        .catch(function (error) {
          hideLoader();
          console.log(error);
        });     
      } else {
        swal('Restored Index was not deleted.');
      }
    });
  }

  const columns = [
    // { field: 'id', headerClassName: 'super-app-theme--header', headerName: '#', flex: 0.1, cellClassName: () => 'word-wrap' },
    { field: 'date', headerClassName: 'super-app-theme--header', headerName: 'Index Date', flex: 0.3, cellClassName: () => 'word-wrap' },
    { field: 'status', headerClassName: 'super-app-theme--header', headerName: 'Restore Status', flex: 0.3, cellClassName: () => 'word-wrap',
    renderCell: (params) => (
        <div>
          {params.value === 'snapshot' ? 'Available to restore' :  params.value === "delete_in_progress" ? 'Deletion in Progress' : params.value === 'completed' ? 'Restored' : 'Restore in Progress' }
        </div>
    ),
    },
    { field: 'restored_at', headerClassName: 'super-app-theme--header', headerName: 'Restored At', flex: 0.3, cellClassName: () => 'word-wrap' },
    {
        field: 'action',
        headerClassName: 'super-app-theme--header',
        headerName: 'Action',
        flex: 0.2,
        renderCell: (params) => (
          <div>
            {params.row.status === 'snapshot' ? (
              <Button size="small" variant="contained" color="success" onClick={() => handleRestore(params.row.date)}>
                Restore
              </Button>
            ) : (
              params.row.status === 'completed' ? (
                <Button size="small" variant="outlined" color="error" onClick={() => handleDelete(params.row.date)}>
                  &nbsp;&nbsp;Delete&nbsp;&nbsp;
                </Button>
              ) : 
              params.row.status === 'delete_in_progress' ? (
                'Deletion in Progress'
              )
              :
              (
                'In Progress'
              )
            )}
          </div>
        ),
    },
  ];

  React.useEffect(() => {
    fetchData('');
  }, []);

  const fetchData = async () => {
    showLoader();
    try {
        const response = await axios.get(Base_URL + '/archival/get_snap_list/');
        const ArchivalList = response.data.dates_list.map((element, index) => {
            let formattedCreatedAt = element.restore_status === 'snapshot'
                ? "-"
                : (element.restored_at !== "snapshot"
                    ? new Date(element.restored_at).toLocaleString()
                    : "-");

            return {
                id: index + 1,
                date: element.index_date,
                status: element.restore_status,
                restored_at: formattedCreatedAt
            };
        });

        ArchivalList.sort((a, b) => new Date(b.date) - new Date(a.date));
        const completedCount = ArchivalList.filter(item => item.status === 'completed').length;
        setCount(completedCount);
        setData(ArchivalList);
        hideLoader();
    } catch (error) {
        hideLoader();
    }
};

  const filteredData = filterStatus ? data.filter((item) => item.status === filterStatus) : data;

  const getRowHeight = (params) => {
    return 40;
  };

  const currentNav = localStorage.getItem('currentNav');
  return (
    <>
      <SessionExpiredAlert />
      {currentNav === 'Real Time' ? <RealTimeSideNav /> : <HistoricalSideNav />}
      <Header />
      <div className="content-wrapper">
        <br />
        {/* <FormControl style={{ marginBottom: '10px', marginLeft: '1.5%', minWidth: '175px' }}>
          <InputLabel id="status-filter-label">Restore Status</InputLabel>
          <Select
            labelId="status-filter-label"
            id="status-filter"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="in_progress">In Progress</MenuItem>
            <MenuItem value="snapshot">Available to Restore</MenuItem>
          </Select>
        </FormControl>
        <section className="content">
      <div className="container-fluid">
      <div className="row priority-selection">
      <div className="col-lg-3 col-6" style={{ cursor: 'pointer' }}>
        <div className="small-box bg-danger" style={{ width: '100%', height: '70%' }}>
          <div className="inner">
            <h3>{archivalretention}</h3>
            <p>Archival Retention Days</p>
          </div>
          <div className="icon">
            <i className="ion ion-stats-bars" />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-6" style={{ cursor: 'pointer' }}>
        <div className="small-box bg-warning" style={{ width: '100%', height: '70%' }}>
          <div className="inner">
            <h3>{liveretention}</h3>
            <p>Live Renetention Days</p>
          </div>
          <div className="icon">
            <i className="ion ion-stats-bars" />
          </div>
        </div>
      </div>
      </div>
      </div>
        </section> */}
        <div style={{ display: 'flex' }}>
        <FormControl style={{ marginBottom: '10px', marginLeft: '1.5%', minWidth: '175px' }} variant="outlined">
          <InputLabel id="status-filter-label" shrink>
            Restore Status
          </InputLabel>
          <Select
            labelId="status-filter-label"
            id="status-filter"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            label="Restore Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="completed">Restored</MenuItem>
            <MenuItem value="in_progress">In Progress</MenuItem>
            <MenuItem value="snapshot">Available to Restore</MenuItem>
          </Select>
        </FormControl>
  <section className="content" style={{ marginLeft: '30px' }}>
    <div className="container-fluid">
      <div className="row priority-selection">
        <div className="col-lg-3 col-6">
          <div className="small-box bg-success" style={{ width: '250px', height: '80px' }}>
            <div className="inner">
              <h5>{liveretention}</h5>
              <p>Live Retention Days</p>
            </div>
            <div className="icon">
              <i className="ion ion-stats-bars" style={{ marginTop: '-15px'}}/>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-6" style={{ marginLeft: '125px'}}>
          <div className="small-box bg-info" style={{ width: '250px', height: '80px' }}>
            <div className="inner">
              <h5>{archivalretention}</h5>
              <p>Archival Retention Days</p>
            </div>
            <div className="icon">
              <i className="ion ion-stats-bars" style={{ marginTop: '-15px'}}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

        <Box
          style={{ height: 375, width: '98%', marginLeft: '1%' }}
          sx={{
            '& .super-app-theme--header': {
              backgroundColor: '#060694',
              color: 'white',
              fontSize: '19px',
            },
          }}
        >
          <DataGrid rows={filteredData} columns={columns} getRowHeight={getRowHeight}/>
        </Box>
      </div>
      {loader}
      <Footer />
    </>
  );
};

export default Archival;