import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import { Base_URL, Kibana_URL } from '../constants';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

const Activities = () => {
  const [cookies] = useCookies(['access_token', 'sample_token', 'kibana_token']);
  const [alerts, setAlerts] = useState([]);
  const [criticalEvents, setCriticalEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState('');
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, 'day'));
  const [toDate, setToDate] = useState(dayjs());
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const token = cookies.kibana_token;

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        let data = JSON.stringify({
          "size": 10,
          "page": 0,
          "severity": [1, 2, 3, 4, 5]
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: Base_URL + '/alerts/',
          headers: { 
            'Content-Type': 'application/json', 
            'Cookie': `access_token=${cookies.access_token}`
          },
          data: data
        };

        const response = await axios.request(config);
        setAlerts(response.data.data.alert_data.alerts);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchCriticalEvents = async () => {
      showLoader();
      try {
        let data = JSON.stringify({
          "upload_tag": "realtimelogs",
          "log_type": "WindowsEvents",
          "from_ts": fromDate.toISOString(),
          "to_ts": toDate.toISOString()
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: Base_URL + '/logcluster/event_analysis_summary',
          headers: { 
            'Content-Type': 'application/json', 
            'Cookie': `access_token=${cookies.access_token}`
          },
          data: data
        };

        const response = await axios.request(config);
        setCriticalEvents(response.data.data);
        hideLoader();
      } catch (error) {
        console.log(error);
        hideLoader();
      }
    };

    fetchAlerts();
    fetchCriticalEvents();
  }, [cookies.access_token, fromDate, toDate]);

  const handleTroubleshootClick = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedTimeRange('');
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (selectedEvent) {
      setLoading(true);
      const timestampOffset = selectedTimeRange.toString();
      const apiPayload = JSON.stringify({
        "upload_tag": "realtimelogs",
        "log_type": "WindowsEvents",
        "issues_ts": selectedEvent.timestamp,
        "ts_offset": timestampOffset
      });

      const apiConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/logcluster/event_analysis',
        headers: { 
          'Content-Type': 'application/json', 
          'Cookie': `access_token=${cookies.access_token}`
        },
        data: apiPayload
      };

      try {
        const response = await axios.request(apiConfig);
        sessionStorage.setItem('responseData', JSON.stringify(response.data));
        window.open('/activity-compare', '_blank');
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      handleModalClose();
    }
  };

  const alertColumns = [
    {
      field: 'created_at',
      headerName: 'Date-Time',
      cellClassName: 'word-wrap',
      flex: 0.6,
      valueFormatter: (params) => new Date(params.value).toLocaleString(),
    },
    { field: 'alertName', headerName: 'Alert Name', flex: 3, cellClassName: 'word-wrap' },
    {
      field: 'severity',
      headerClassName: 'super-app-theme--header',
      headerName: 'Severity',
      flex: 1,
      cellClassName: 'word-wrap',
      renderCell: (params) => {
        const severityValue = params.value;
        let severityText = '';

        switch (severityValue) {
          case 1:
          case 2:
            severityText = 'High';
            break;
          case 3:
            severityText = 'Medium';
            break;
          case 4:
            severityText = 'Low';
            break;
          case 5:
            severityText = 'Info';
            break;
          default:
            severityText = 'Unknown';
        }
        return <span>{severityText}</span>;
      },
    },
    {
      field: 'hostName',
      headerName: 'Host Name',
      cellClassName: 'word-wrap',
      flex: 1,
      valueGetter: (params) => params.row.hostName.join(', '),
    },
  ];

  const criticalEventsColumns = [
    { field: 'timestamp', headerName: 'Date-Time', cellClassName: 'word-wrap', flex: 0.6,
      valueFormatter: (params) => new Date(params.value).toLocaleString(),
    },
    { field: 'event_id', headerName: 'Event ID', cellClassName: 'word-wrap', flex: 0.4 },
    { field: 'description', headerName: 'Description', cellClassName: 'word-wrap', flex: 3 },
    {
      field: 'troubleshoot',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{ backgroundColor: '#060694' }}
          onClick={() => handleTroubleshootClick(params.row)}
          sx={{ textTransform: 'none' }}
        >
          AI Troubleshoot
        </Button>
      ),
    },
  ];

  const iframeUrl = `${Kibana_URL}app/dashboards?security_tenant=private&jwt=${token}#/view/5699a3c0-44df-11ef-aa7a-4dc5619a996f?embed=true&hide-filter-bar=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${fromDate.toISOString()}',to:'${toDate.toISOString()}'))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!t,title:'Error%20count',viewMode:view)`;

  return (
    <>
      <div>
        <div style={{ marginTop: '0%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <DateTimePicker
                label="From"
                value={fromDate}
                onChange={(newValue) => setFromDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DateTimePicker
                label="To"
                value={toDate}
                onChange={(newValue) => setToDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={criticalEvents}
              columns={criticalEventsColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              getRowId={(row) => row.event_id}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#060694',
                  color: '#fff',
                },
                '& .MuiDataGrid-row:nth-of-type(even)': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
          </div>
        </div>
        <iframe
          src={iframeUrl}
          width="100%"
          height="900"
          style={{ border: 'none', marginTop: '2%' }}
          title="Error Count Dashboard"
        ></iframe>
        <div style={{ marginTop: '2%' }}>
          <h5>Latest Alerts</h5>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={alerts}
              columns={alertColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#060694',
                  color: '#fff',
                },
                '& .MuiDataGrid-row:nth-of-type(even)': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
          </div>
        </div>
        {loader}
      </div>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="troubleshoot-modal-title"
        aria-describedby="troubleshoot-modal-description"
      >
        <Box sx={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          width: 400, 
          bgcolor: 'background.paper', 
          border: '2px solid #000', 
          boxShadow: 24, 
          p: 4 
        }}>
          <Typography id="troubleshoot-modal-title" variant="h6" component="h2">
            Troubleshoot Event
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="time-range-select-label">Time Range</InputLabel>
            <Select
              labelId="time-range-select-label"
              id="time-range-select"
              value={selectedTimeRange}
              label="Time Range"
              onChange={(e) => setSelectedTimeRange(e.target.value)}
            >
              <MenuItem value="1">Before and After 1 hour</MenuItem>
              <MenuItem value="6">Before and After 6 hours</MenuItem>
              <MenuItem value="12">Before and After 12 hours</MenuItem>
              <MenuItem value="24">Before and After 24 hours</MenuItem>
            </Select>
          </FormControl>
          <Button 
            variant="contained" 
            onClick={handleSubmit} 
            sx={{ mt: 2, backgroundColor: '#060694' }}
          >
            Submit
          </Button>
          {loading && <CircularProgress sx={{ mt:2 , ml: 8 }} />}
        </Box>
      </Modal>
    </>
  );
};

export default Activities;