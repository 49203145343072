import Footer from "../components/Footer";
import SideNav from "../components/SideNav";
import '../App.css';
import LogsHeader from "../components/LogsHeader";
import HistoricalSideNav from "../components/HistoricalSideNav";
import HistoricalLogsHeader from "../components/HistoricalLogsHeader";


const HistoricalSampleLogs = () => {

    return (
        <>
        <HistoricalSideNav />
        <HistoricalLogsHeader />
        <Footer />
      </>
    );
  };
  
  export default HistoricalSampleLogs;