import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import SessionExpiredAlert from '../components/SessionExpiredAlert'
import SideNav from '../components/SideNav'
import Header from '../components/Header'
import axios from 'axios';
import { Base_URL } from '../constants'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Button, FormControl, InputLabel, Modal, TextField, Typography } from '@mui/material'
import { FcInfo } from 'react-icons/fc'
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import swal from 'sweetalert';
import cronstrue from 'cronstrue';
import TimezoneSelect from 'react-timezone-select';
import { FcInvite, FcSupport } from 'react-icons/fc';
import RealTimeSideNav from '../components/RealTimeSideNav'

const Reporting = () => {

    const [rows, setRows] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
    const [paginationModel, setPaginationModel] = React.useState({
      page: 0,
      pageSize: 10,
    });
    
    function handleStatusChange(row) {
      
      swal({
        title: "Are you sure to update the setting?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          let status = row.status;
          
          status = !status;
          
          var data = JSON.stringify({
            "report_id": row.key,
            "status": status
          });
          
          var config = {
            method: 'post',
            url: Base_URL + '/reports/status',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            
          })
          .catch(function (error) {
            console.log(error);
          });      
          swal("Setting Updated Successfully!", {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal("Settings not changed!");
        }
      });
    }

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginLeft: '7%',
      height: 200,
      width: '80%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      overflow:"auto",
      p: 4,
    };

    const emailstyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginLeft: '7%',
      height: 200,
      width: '50%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      overflow:"auto",
      p: 4,
    };

    const addreportstyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginLeft: '7%',
      height: 375,
      width: '80%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      overflow:"auto",
      p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const [viewreportinfodata, setViewReportInfoData] = React.useState({});

    function handleOpen(alert_info){
      setViewReportInfoData(alert_info);
      setOpen(true);
    }

    const handleClose = () => setOpen(false);

    const [openaddreport, setOpenAddReport] = React.useState(false);
    const [openupdatereport, setOpenUpdateReport] = React.useState(false);
    
    const [cronExpression, setCronExpression] = React.useState(false);

    const [reportId, setReportId] = React.useState("");

    const [defaulttimezone, setDefaultTimezone] = React.useState("");

    const handleTimezoneChange = (timezone) => {
      setDefaultTimezone(timezone);
    };

    useEffect(() => {
      setDefaultTimezone(defaulttimezone);
    }, []);
  
    function handleAddReport(row){
      setDefaultTimezone(row.timezone)
      setReportId(row.key);
      setOpenAddReport(true);
      setCronExpression(row.cron);
    }

    const handleCloseAddReport = () => setOpenAddReport(false);

    function handleUpdateReport(row){
      setDefaultTimezone(row.timezone)
      setReportId(row.key);
      setOpenUpdateReport(true);
      setCronExpression(row.cron);
    }

    const handleCloseUpdateReport = () => setOpenUpdateReport(false);

    function cronToHumanReadable(cronExpression) {
      try {
        const humanReadable = cronstrue.toString(cronExpression);
        return humanReadable;
      } catch (error) {
        return 'Invalid CRON';
      }
    }

    const columns = [
      // { field: 'key', headerClassName: 'super-app-theme--header', headerName: 'ID', flex: 0.3, cellClassName: (params) => {
      //   return 'word-wrap';
      // } },
      { field: 'title', headerClassName: 'super-app-theme--header', headerName: 'Title', flex: 1, cellClassName: (params) => {
        return 'word-wrap';
      } },
      {
        field: 'cron',
        headerClassName: 'super-app-theme--header',
        headerName: 'CRON',
        flex: 1,
        cellClassName: (params) => {
          return 'word-wrap';
        },
        renderCell: (params) => (
          <>{cronToHumanReadable(params.value)}</>
        ),
      },
      { field: 'next_run', headerClassName: 'super-app-theme--header', headerName: 'Next Run', flex: 1, cellClassName: (params) => {
        return 'word-wrap';
      },
      renderCell: (params) => {
        let formattedTimestamp=params.value;
        if (params.value!="Report Disabled" && params.value!='-'){
        const utcTimestamp = new Date(params.value);
        
        const targetTimezone = params.row.timezone;
        
        const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
          timeZone: targetTimezone,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
        
        formattedTimestamp = dateTimeFormatter.format(utcTimestamp) + " " + targetTimezone;
        
      }
        return <>{formattedTimestamp=='-'?"Scheduling":formattedTimestamp}</>;
      },
     },
      { field: 'current_status', headerClassName: 'super-app-theme--header', headerName: 'Status', flex: 1, cellClassName: (params) => {
        return 'word-wrap';
      }, 
      renderCell: (params) => {
        const status = params.value;
        
        if (status === false) {
          return "Disabled"
        } else if (status === true) {
          return "Enabled"
        } else {
          return "Report Disabled"
        }
      }},
      { field: 'desc', headerClassName: 'super-app-theme--header', headerName: 'Description', flex: 0.7, 
      cellClassName: (params) => {
        return 'word-wrap';
      },
      renderCell: (params) => (
        <><FcInfo onClick={() => handleOpen(params.value)} size={30} style={{ cursor: 'pointer' }}/></>
      ),
      },
      {
        field: 'status',
        headerClassName: 'super-app-theme--header',
        headerName: 'Action',
        flex: 1,
        sortable: false,
        cellClassName: (params) => {
          return 'word-wrap';
        },
        renderCell: (params) => {
          const isReportEnabled = params.value;

          const handleTrialUserClick = () => {
            swal({
              icon: 'info',
              title: 'WoWizer TPM Test Drive does not have the functionality!',
              text: "Kindly proceed with the complete sign-up to unlock all features.",
              buttons: {
                signUp: {
                  text: "Complete Sign-up",
                  value: "signUp",
                  className: "black-text-button",
                },
                cancel: "Cancel",
              },
              closeOnClickOutside: false,
              closeOnEsc: false,
              dangerMode: true,
            })
              .then((value) => {
                if (value === "signUp") {
                  const signUpPageUrl = "/register";
                  window.open(signUpPageUrl, '_blank');
                }
              });
          };

          if (isReportEnabled === false) {
            return (
              <div>
                {/* <Button onClick={() => handleUpdateReport(params.row)} size="small" variant="contained" color="primary">
                  Update Config
                </Button> */}
                {
                  localStorage.getItem('userRole') === "trialuser" ?
                  <>
                  <FcSupport style={{ fontSize: '32px', cursor: 'pointer', marginRight: '5px' }} onClick={handleTrialUserClick} />
                  <span style={{ margin: '0 5px' }}></span>
                  <Button size="small" onClick={handleTrialUserClick} variant="contained" color="success">
                    Activate
                  </Button>
                  </>
                  :
                  <>
                  <FcSupport style={{ fontSize: '32px', cursor: 'pointer', marginRight: '5px' }} onClick={() => handleUpdateReport(params.row)} />
                  <span style={{ margin: '0 5px' }}></span>
                  <Button size="small" onClick={() => handleStatusChange(params.row)} variant="contained" color="success">
                    Activate
                  </Button>
                  </>
                }
              </div>
            );
          } else if (isReportEnabled === true) {
            return (
              <div>
                {/* <Button onClick={() => handleUpdateReport(params.row)} size="small" variant="contained" color="primary">
                  Update Config
                </Button> */}
                {
                  localStorage.getItem('userRole') === "trialuser" ?
                  <>
                  <FcSupport style={{ fontSize: '32px', cursor: 'pointer', marginRight: '5px' }} onClick={handleTrialUserClick} />
                  <span style={{ margin: '0 5px' }}></span>
                  <Button size="small" onClick={handleTrialUserClick} variant="outlined" color="error">
                    Deactivate
                  </Button>
                  </>
                  :
                  <>
                  <FcSupport style={{ fontSize: '32px', cursor: 'pointer', marginRight: '5px' }} onClick={() => handleUpdateReport(params.row)} />
                  <span style={{ margin: '0 5px' }}></span>
                  <Button size="small" onClick={() => handleStatusChange(params.row)} variant="outlined" color="error">
                    Deactivate
                  </Button>
                  </>
                }
              </div>
            );
          } else {
            return (
              <Button size="small" onClick={() => handleAddReport(params.row)} variant="contained" color="primary">
                Report Config
              </Button>
            );
          }
        },
      },
      // {
      //   field: 'cron',
      //   headerClassName: 'super-app-theme--header',
      //   headerName: 'Report',
      //   flex: 0.2,
      //   sortable: false,
      //   cellClassName: (params) => {
      //     return 'word-wrap';
      //   },
      //   renderCell: (params) => {
      //     console.log(params.row.cron);
      //     return (
      //       <Button size="small" variant="outlined" color="success">Update</Button>
      //     );
      //   },
      // },
    ];

    React.useEffect(() => {
        fetchReports();
    }, []);

    const fetchReports = () => {
        var data = '';

        var config = {
        method: 'get',
        url:  Base_URL + '/reports/',
        headers: { },
        data : data
        };

        axios(config)
        .then(function (response) {
          const responseData = response.data;
          
          if (typeof responseData === 'object' && responseData !== null) {
            const ReportHistory = Object.keys(responseData).map((key, index) => {
              const reportData = responseData[key];
              return {
                "id": index + 1,
                "key" : key,
                "title": reportData.title || 'No Title',
                "desc": reportData.desc || 'No Description',
                "status": reportData.status,
                "current_status": reportData.status,
                "cron" : reportData.user_configs?reportData.user_configs.schedule_interval:reportData.cron_val,
                "timezone": reportData.user_configs?reportData.user_configs.time_zone:reportData.time_zone,
                "next_run" : reportData.next_run || 'Report Disabled'
              };
            });
            setRows(ReportHistory);
            setRowCountState(ReportHistory.length);
          } else {
            console.error("Invalid response data:", responseData);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    const [showDropdown, setShowDropdown] = useState(false);

    const handleSettingsClick = () => {
      setShowDropdown(!showDropdown);
    };

    const [openemailpage, setOpenEmailPage] = React.useState(false);
    const handleCloseEmailPage = () => setOpenEmailPage(false);

    const [emails, setEmails] = useState('');

    const handleEmailClick = () => {
      setOpenEmailPage(true);
      var data = '';

      var config = {
        method: 'get',
        url:  Base_URL + '/reports/mail_settings',
        headers: { },
        data : data
      };

      axios(config)
      .then(function (response) {
        const emailList = response.data.mail_list;
        const formattedEmails = emailList.join(', ');
        setEmails(formattedEmails);
      })
      .catch(function (error) {
        console.log(error);
      });
    };

    const [error, setError] = useState('');

    const handleSaveClick = () => {
      
      if (emails.trim() === '') {
        swal({
          icon: 'error',
          title: 'No Emails Entered',
          text: 'Please enter at least one valid email address.',
        });
        return;
      }

      const emailRegex = /^[\w+.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

      const editedEmails = emails.split(',').map(email => email.trim());

      const validEmails = editedEmails.filter(email => emailRegex.test(email));

      if (validEmails.length !== editedEmails.length) {
        swal({
          icon: 'error',
          title: 'Invalid Emails',
          text: 'Please enter valid email addresses separated by commas.',
        });
        return;
      }

      const data = {
        mail_list: validEmails,
      };

      var config = {
        method: 'post',
        url:  Base_URL + '/reports/mail_settings',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        swal("Emails Updated Successfully!", {
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    };

    const handleSaveReport = () => {
      try {
        cronstrue.toString(cronExpression);
    
        var data = JSON.stringify({
          "report_id": reportId,
          "cron_val": cronExpression,
          "time_zone": defaulttimezone.value
        });
    
        var config = {
          method: 'post',
          url: Base_URL + '/reports/',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
    
        axios(config)
          .then(function (response) {
            swal('Success', 'Report added successfully', 'success').then(() => {
              window.location.reload();
            });
          })
          .catch(function (error) {
            swal('Error', 'An error occurred while saving the report', 'error');
          });
      } catch (error) {
        swal('Invalid Cron Expression', 'Please enter a valid cron expression', 'error');
      }
    }
    
    const handleSaveUpdateReport = () => {
      
      try {
        cronstrue.toString(cronExpression);
    
        var data = JSON.stringify({
          "report_id": reportId,
          "cron_val": cronExpression,
          "time_zone": defaulttimezone.value?defaulttimezone.value:defaulttimezone
        });
        
        var config = {
          method: 'patch',
          url: Base_URL + '/reports/',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
    
        axios(config)
          .then(function (response) {
            swal('Success', 'Report updated successfully', 'success').then(() => {
              window.location.reload();
            });
          })
          .catch(function (error) {
            swal('Error', 'An error occurred while updating the report', 'error');
          });
      } catch (error) {
        swal('Invalid Cron Expression', 'Please enter a valid cron expression', 'error');
      }
    }  

    const [openCronModal, setOpenCronModal] = React.useState(false);
    const [cronContent, setCronContent] = React.useState('');

    const handleOpenCronModal = async () => {
      try {
        const response = await fetch('/crontab_setup.txt');
        console.log(response);
        const text = await response.text();
        setCronContent(text);
        setOpenCronModal(true);
      } catch (error) {
        console.error('Error fetching cron setup file:', error);
      }
    };

    const handleCloseCronModal = () => {
      setOpenCronModal(false);
    };


  return (
    <>
    <SessionExpiredAlert />
    <RealTimeSideNav />
    <Header />
    <div>
      <div className="content-wrapper">
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <FcInvite style={{ fontSize: '32px', cursor: 'pointer', marginRight: '5px' }} onClick={handleEmailClick} />
        <span style={{ fontSize: '20px', fontWeight: 'bold', cursor: 'pointer', marginRight: '20px' }} onClick={handleEmailClick} >Email Config</span>
        </div>
        {showDropdown && (
            <div style={{ position: 'absolute', marginLeft: '70%', backgroundColor: '#f9f9f9', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)', padding: '12px', zIndex: '10', width: '140px' }}>
              <div className="dropdownOption" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={handleEmailClick} ><b>Email</b></div>
            </div>
        )}
        <Box style={{ height: 350, width: '98%', marginLeft: '1%', marginTop: '1%' }}
            sx={{
            '& .super-app-theme--header': {
              backgroundColor: '#060694',
              color: 'white',
              fontSize: '19px'
              },
            }}
        >
        <DataGrid
          rows={rows}
          columns={columns}
          rowCount={rowCountState}
          loading={isLoading}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          paginationMode="server"
          pagination={true}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          getRowHeight={() => 80}
        />
        </Box>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
              Report Info <CloseIcon style={{marginLeft: '88%', cursor:'pointer'}} onClick={handleClose}/><hr />
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {viewreportinfodata}
              </Typography>
            </Box>
        </Modal>
        <Modal
        open={openemailpage}
        onClose={handleCloseEmailPage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={emailstyle}>
              <CloseIcon style={{marginLeft: '97%', marginTop: '-2%',cursor:'pointer'}} onClick={handleCloseEmailPage}/>
              <TextField
                id="outlined-multiline-static"
                label="Emails"
                multiline
                rows={1}
                fullWidth
                value={emails}
                onChange={(e) => setEmails(e.target.value)}
                variant="outlined"
              />
              <div style={{ marginTop: 20 }}>
              <div style={{ fontWeight: 'bold' }}>Note : Please enter valid email addresses separated by commas.</div>
              <Button variant="contained" color="success" style={{ float: 'right', marginRight: '1%', marginTop: '-2%' }} onClick={handleSaveClick}>
                Save
              </Button>
              </div>
            </Box>
        </Modal>
        <Modal
        open={openaddreport}
        onClose={handleCloseAddReport}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={addreportstyle}>
              Add Report <CloseIcon style={{marginLeft: '88%', cursor:'pointer'}} onClick={handleCloseAddReport}/><hr />
              <TextField
                label="CRON Expression"
                variant="outlined"
                fullWidth
                value={cronExpression}
                onChange={(e) => setCronExpression(e.target.value)}
              />
              <br /><br /><br />
              <TimezoneSelect
                value={defaulttimezone}
                onChange={handleTimezoneChange}
                className="timezone-select"
              />
              <Button onClick={handleSaveReport} variant='contained' color='success' style={{ marginTop: '5%', marginLeft: '85%' }}>Add Report</Button>
            </Box>
        </Modal>
        <Modal
        open={openupdatereport}
        onClose={handleCloseUpdateReport}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={addreportstyle}>
              Update Report <CloseIcon style={{marginLeft: '86%', cursor:'pointer'}} onClick={handleCloseUpdateReport}/><hr />
              <TextField
                label="CRON Expression"
                variant="outlined"
                fullWidth
                value={cronExpression}
                onChange={(e) => setCronExpression(e.target.value)}
              />
              <br /><br /><br />
              <TimezoneSelect
                value={defaulttimezone}
                onChange={handleTimezoneChange}
                className="timezone-select"
              />
              <Button onClick={handleOpenCronModal} variant='outlined' color='success' style={{ marginTop: '5%' }}>How to set Cron?</Button>
              <Button onClick={handleSaveUpdateReport} variant='contained' color='success' style={{ marginTop: '5%', marginLeft: '67%' }}>Update Report</Button>
            </Box>
        </Modal>
        <Modal
          open={openCronModal}
          onClose={handleCloseCronModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={addreportstyle}>
            <CloseIcon style={{ marginLeft: '96%', cursor: 'pointer' }} onClick={handleCloseCronModal} />
            <Typography id="modal-modal-description" sx={{ mt: 0 }}>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{cronContent}</pre>
            </Typography>
          </Box>
        </Modal>
    </div>
    <Footer />
    </>
  )
}

export default Reporting