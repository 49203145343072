import React from 'react'
import AdminSideNav from '../components/AdminSideNav'
import { Kibana_URL } from '../constants'
import Footer from '../components/Footer'
import SessionExpiredAlert from '../components/SessionExpiredAlert'
import SideNav from '../components/SideNav'
import Header from '../components/Header'
import SocSideNav from '../components/SocSideNav'
import SocHeader from '../components/SocHeader'

const AdminAlerts = () => {
  let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbiIsInJvbGVzIjoiYWRtaW4iLCJpYXQiOjE1MTYyMzkwMjJ9.JGkKeO6t8x8_j0h8jem-GmNJlpOONtU1-f-NCgWBrts'
  let src = `${Kibana_URL}app/dashboards?security_tenant=global&jwt=${token}#/view/f36f5e90-1c23-11ef-aa7a-4dc5619a996f?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Alert%20Summary',viewMode:view)&show-time-filter=true`
  return (
    <>
      <SessionExpiredAlert />
      <SocSideNav />
      <SocHeader />
      <div className="content-wrapper">
        <iframe
            src={src}
            style={{ width: '100%', height: '100vh', border: 'none' }}
            title="Kibana Dashboard"
        />
      </div>
      <Footer />
    </>
  )
}

export default AdminAlerts