import { Box, Button, Menu, MenuItem } from "@mui/material"
import { useEffect, useState } from "react";
import { Kibana_URL, Logout_User, User } from '../constants'
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { FcManager } from "react-icons/fc";
import SessionExpiredAlert from "./SessionExpiredAlert";
import axios from 'axios';
import { Base_URL } from '../constants';
import swal from 'sweetalert';
import SendIcon from '@mui/icons-material/Send';
import IntroJs from 'intro.js';
import 'intro.js/introjs.css';
import 'intro.js/themes/introjs-modern.css';
import { useNavigate } from "react-router-dom";
import { FcSettings } from "react-icons/fc";
import useFullPageLoader from "./hooks/useFullPageLoader";

const LogsHeader = () => {
    const navigate = useNavigate();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [anchorEl, setAnchorEl] = useState(null);

    const [cookies, removeCookie ] = useCookies(['access_token', 'sample_token','kibana_token']);
    var last_url = window.sessionStorage.getItem("lastUrl:/kibana:dashboard");
    const logtype = window.localStorage.getItem("logtype");
    let params = new URLSearchParams(last_url);
    if(logtype != params.get('logtype')){
      sessionStorage.clear();
      params = new URLSearchParams("last_url");
    }
    const [urlfilter, setUrlFilter] = useState(params.get("_g") ? params.get("_g") : params.get("#/list?_g"));

    useEffect(() => {
    if (!cookies.refresh_token) {
        if (!cookies.refresh_token) {
            swal({
              title: 'Session Expired',
              text: 'Please log in again.',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Log In',
            }).then((result) => {
              if (result) {
                localStorage.clear();
                // Object.keys(cookies).forEach((cookieName) => {
                // removeCookie(cookieName);
                // });
                window.location.href = '/';
              }
            });
            return;
        }
    }

    if (!cookies.kibana_token) {
        var config = {
        method: 'get',
        url: Base_URL + '/auth/refresh',
        headers: { }
        };

        axios(config)
        .then(function (response) {
        
        })
        .catch(function (error) {
        console.log(error);
        });
    }
    }, []);

    const [firstname, setFirstName] = useState('');
    const [tenantname, setTenantName] = useState('');

    const { uploadTag, qlikversion } = useParams();
    
    useEffect(() => {
      dashboardCall('');
    }, []);

    const dashboardCall = async => {
      var data = JSON.stringify({
        "dash_type": (
          uploadTag === 'Sample Data' ? 'sample_dashboard' : 
          uploadTag === 'realtimelogs' ? 'realtime_dashboard' :
          'static_dashboard'),
        "upload_tag": (
          uploadTag === 'Sample Data' ? cookies.sample_upload_tag : 
          uploadTag === 'realtimelogs' ? 'realtimelogs' :
          uploadTag)
      });

      var config = {
        method: 'post',
        url: Base_URL + '/users/dashboard',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        
      })
      .catch(function (error) {
        
      });
    }

    useEffect(() => {
      fetchData('');
    }, []);
  
    const fetchData = async => {
        var config = {
            method: 'get',
            url: Base_URL + User,
            headers: { }
          };
          
          axios(config)
          .then(function (response) {
            setFirstName(response.data.data.user.firstName);
            setTenantName(response.data.data.subscription.tenantName);
            localStorage.setItem('first_name', response.data.data.user.firstName);
            localStorage.setItem('tenant_name', response.data.data.subscription.tenantName);
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    // const firstname = localStorage.getItem('first_name');

    const {id} = useParams();
    
    const [activeTab, setActiveTab] = useState(parseInt(id));

    const handleTabChange = (index) => {
        var last_url = window.sessionStorage.getItem("lastUrl:/kibana:dashboard");
        let params = new URLSearchParams(last_url);
        // if(logtype != params.get('logtype')){
        //   params = new URLSearchParams("last_url");
        // }
        setUrlFilter(params.get("_g") ? params.get("_g") : params.get("#/list?_g"));
        setActiveTab(index);
    };

    const handleFullscreen = () => {
        const iframe = document.getElementById('fullscreen-iframe');
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen();
        } else if (iframe.mozRequestFullScreen) {
          iframe.mozRequestFullScreen();
        } else if (iframe.webkitRequestFullscreen) {
          iframe.webkitRequestFullscreen();
        } else if (iframe.msRequestFullscreen) {
          iframe.msRequestFullscreen();
        }
    };
  
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let token = cookies.kibana_token;

    let src = `${Kibana_URL}app/data-explorer/discover?security_tenant=private&jwt=${token}#/?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(columns:!(_source),filters:!(),index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',interval:auto,query:(language:kuery,query:''),sort:!())`
    if (uploadTag === 'Sample Data') {
        token = cookies.sample_token;
        src = `${Kibana_URL}app/data-explorer/discover?security_tenant=private&jwt=${token}#/?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21)))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(columns:!(_source),filters:!(),index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',interval:auto,query:(language:kuery,query:''),sort:!())`
    } else if (uploadTag === 'realtimelogs') {
        token = cookies.kibana_token;
        src = `${Kibana_URL}app/data-explorer/discover?security_tenant=private&jwt=${token}#/?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs)))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(columns:!(_source),filters:!(),index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',interval:auto,query:(language:kuery,query:''),sort:!())`
    }

    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [iframeWidth, setIframeWidth] = useState(1115);
    const defaultWidth = 1115;

    const handleButtonClick = () => {
    setIsButtonClicked(!isButtonClicked);
    if (isButtonClicked) {
        setIframeWidth(defaultWidth);
    } else {
        setIframeWidth(1300);
    }
    };

    const iframeStyle = {
      width: '100%',
      height: '100vh',
    };

    const iframeStyleCPUFirst = {
      width: '30%',
      height: '100vh',
    };

    const iframeStyleCPUSecond = {
      width: '70%',
      height: '100vh',
    };

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    const handleMySubscriptions = () => {
      navigate('/subscription')
      handleMenuClose();
    };
  
    const handleHelp = () => {
      navigate('/help')
      handleMenuClose();
    };
  
    const handleLogout = () => {
      showLoader();
      var config = {
        method: 'get',
        url: Base_URL + Logout_User,
        headers: {}
      };
  
      axios(config)
          .then(function (response) {
            hideLoader();
            sessionStorage.clear();
            localStorage.clear();
            // Object.keys(cookies).forEach((cookieName) => {
            //   removeCookie(cookieName);
            // });
            window.location.href = '/';
          })
          .catch(function (error) {
            var config = {
            method: 'get',
            url: Base_URL + '/auth/refresh',
            headers: { }
            };
  
            axios(config)
            .then(function (response) {
              var config = {
                method: 'get',
                url: Base_URL + Logout_User,
                headers: {}
              };
              axios(config)
              .then(function (response) {
                hideLoader();
                localStorage.clear();
                // Object.keys(cookies).forEach((cookieName) => {
                //   removeCookie(cookieName);
                // });
                handleMenuClose();
                window.location.href = '/';
              })
            })
            .catch(function (error) {
              hideLoader();
              console.log(error);
            });
          });
    };

    // const handleAIDriven = () => {
    //   navigate('/aidrivendashboards');
    // };

    return (
        <>
        <SessionExpiredAlert />
      <div>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="#" role="button" onClick={handleButtonClick}><i className="fas fa-bars" /></a>
            </li>
            <ul class="nav nav-pills">
                <li class="nav-item"><a class={id == 3 ? "nav-link active" : "nav-link"} href="#" data-toggle="tab" onClick={() => handleTabChange(3)}>Performance Monitoring</a></li>
                <li class="nav-item"><a class={id == 4 ? "nav-link active" : "nav-link"} href="#" data-toggle="tab" onClick={() => handleTabChange(4)}>User Telemetry</a></li>
                {/* <li class="nav-item"><a class="nav-link" href="#" data-toggle="tab" onClick={() => handleTabChange(2)}>Reload Insights</a></li> */}
                <li class="nav-item"><a class={id == 2 ? "nav-link active" : "nav-link"} href="#" data-toggle="tab" onClick={() => handleTabChange(2)}>Reload Insights</a></li>
                {/* <li class="nav-item"><a class="nav-link" href="#" data-toggle="tab" onClick={() => handleTabChange(1)}>Script Log Analysis</a></li> */}
                <li class="nav-item"><a class={id == 0 ? "nav-link active" : "nav-link"} href="#" data-toggle="tab" onClick={() => handleTabChange(0)}>Log Search</a></li>
                {/* <Button variant="outlined" onClick={handleAIDriven}>AI Driven</Button> */}
                {/* <li class="nav-item">
                <Button color="inherit" onClick={() => handleTabChange(0)}>{activeTab === 0 ? <div style={{ color: 'blue', textTransform: 'none' }}>Log Insights</div> : <div style={{ textTransform: 'none' }}>Log Insights</div>}</Button>
                </li>
                <li class="nav-item">
                    <Button color="inherit" onClick={() => handleTabChange(1)}>{activeTab === 1 ? <div style={{ color: 'blue', textTransform: 'none' }}>CPU Analysis</div> : <div style={{ textTransform: 'none' }}>CPU Analysis</div>}</Button>
                </li>
                <li class="nav-item">
                    <Button color="inherit" onClick={() => handleTabChange(2)}>{activeTab === 2 ? <div style={{ color: 'blue', textTransform: 'none' }}>App & User Wise Analysis</div> : <div style={{ textTransform: 'none' }}>App & User Wise Analysis</div>}</Button>
                </li>
                <li class="nav-item">
                    <Button color="inherit" onClick={() => handleTabChange(3)}>{activeTab === 3 ? <div style={{ color: 'blue', textTransform: 'none' }}>User Telemetry Details</div> : <div style={{ textTransform: 'none' }}>User Telemetry Details</div>}</Button>
                </li> */}
            </ul>
          </ul>
            <a className="nav-link" href="#" role="button" onClick={handleFullscreen}>
                <i className="fas fa-expand-arrows-alt" />
            </a>
            <div className="welcome-div"
            onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
            >
              <FcSettings size={30}/> Welcome, {firstname}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMySubscriptions}>
                My Subscriptions
              </MenuItem>
              {/* <MenuItem onClick={handleHelp}>
                Help & Support
              </MenuItem> */}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            </div>
        </nav>
        <div className="content-wrapper">
            {activeTab === 0 && 
            <iframe
            id="fullscreen-iframe"
            src={src}
            style={iframeStyle}
            />     
            }
            {/* {activeTab === 1 && 
            <Box id="fullscreen-iframe">
            <div>
                <iframe
                // id="fullscreen-iframe"
                src={
                    Kibana_URL + "app/dashboards?security_tenant=private&jwt=" + 
                    (uploadTag === 'Sample Data' ? cookies.sample_token : cookies.kibana_token) + 
                    (uploadTag === 'Sample Data' ? 
                    "#/view/c37b29f0-5f4e-11ed-b809-37dd3426b103?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag:SampleData-4-17-2023-10-07-50-AM-QV21)))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'HealthData%20Analysis',viewMode:view)&show-time-filter=true"
                    :
                    uploadTag === 'realtimelogs' ? 
                    "#/view/c37b29f0-5f4e-11ed-b809-37dd3426b103?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag:realtimelogs)))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'HealthData%20Analysis',viewMode:view)&show-time-filter=true"
                    :
                    "#/view/c37b29f0-5f4e-11ed-b809-37dd3426b103?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'HealthData%20Analysis',viewMode:view)&show-time-filter=true"
                    )
                }
                style={iframeStyleCPUFirst}/>
                <iframe
                // id="fullscreen-iframe"
                src={
                    Kibana_URL + "app/dashboards?security_tenant=private&jwt=" +
                    (uploadTag === 'Sample Data' ? cookies.sample_token : cookies.kibana_token) + 
                    (uploadTag === 'Sample Data' ?
                    `#/view/45f3ef60-a6d6-11ed-a8b4-373d40244082?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag:SampleData-4-17-2023-10-07-50-AM-QV21)))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:WOWizer,viewMode:view)&show-time-filter=true`
                    :
                    uploadTag === 'realtimelogs' ?
                    `#/view/45f3ef60-a6d6-11ed-a8b4-373d40244082?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag:realtimelogs)))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:WOWizer,viewMode:view)&show-time-filter=true`
                    :
                    (qlikversion === 'QV21' ? 
                    `#/view/45f3ef60-a6d6-11ed-a8b4-373d40244082?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:WOWizer,viewMode:view)&show-time-filter=true`
                    :
                    `#/view/4e1e959c-9a75-4316-9555-6ffbecb171b7?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:WOWizer,viewMode:view)&show-time-filter=true`
                    )
                    )
                    }
                    style={iframeStyleCPUSecond}/>
            </div>
            </Box>
            } */}
            {activeTab === 1 && 
            <iframe 
                id="fullscreen-iframe"
                src={
                Kibana_URL + "app/dashboards?security_tenant=private&jwt=" + 
                (uploadTag === 'Sample Data' ? cookies.sample_token : cookies.kibana_token) + 
                (uploadTag === 'Sample Data' ? 
                "#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),query:(language:kuery,query:''),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(),index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',interval:auto,query:(language:kuery,query:''),sort:!())&show-time-filter=true"
                // `#/view/16618fc0-a6d9-11ed-a8b4-373d40244082?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:'SampleData-4-17-2023-10-07-50-AM-QV21'),type:phrase),query:(match_phrase:(upload_tag.keyword:'SampleData-4-17-2023-10-07-50-AM-QV21')))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true`
                // `#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!t,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true`
                :
                uploadTag === 'realtimelogs' ? 
                // `#/view/16618fc0-a6d9-11ed-a8b4-373d40244082?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:'realtimelogs'),type:phrase),query:(match_phrase:(upload_tag.keyword:'realtimelogs')))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true`
                // `#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!t,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true`
                "#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),query:(language:kuery,query:''),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(),index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',interval:auto,query:(language:kuery,query:''),sort:!())&show-time-filter=true"
                :
                // "#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true"
                // `#/view/16618fc0-a6d9-11ed-a8b4-373d40244082?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true`
                // `#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + "))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!t,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true`
                "#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query: " + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword: " + uploadTag + "))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),query:(language:kuery,query:''),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(),index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',interval:auto,query:(language:kuery,query:''),sort:!())&show-time-filter=true"
                )
                }
                style={iframeStyle}
                />
            }
            {activeTab === 2 && 
            <iframe 
                id="fullscreen-iframe"
                src={
                Kibana_URL + "app/dashboards?security_tenant=private&jwt=" + 
                (uploadTag === 'Sample Data' ? cookies.sample_token : cookies.kibana_token) + 
                (uploadTag === 'Sample Data' ? 
                // "#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag:SampleData-4-17-2023-10-07-50-AM-QV21)))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true"
                // `#/view/16618fc0-a6d9-11ed-a8b4-373d40244082?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:'SampleData-4-17-2023-10-07-50-AM-QV21'),type:phrase),query:(match_phrase:(upload_tag.keyword:'SampleData-4-17-2023-10-07-50-AM-QV21')))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true`
                // `#/view/04255490-3e82-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!t,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'%20Reload%20Insights',viewMode:view)&show-time-filter=true`
                // `#/view/04255490-3e82-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'%20Reload%20Insights',viewMode:view)&show-time-filter=true`
                // `#/view/04255490-3e82-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'%20Reload%20Insights',viewMode:view)&show-time-filter=true`
                `#/view/04255490-3e82-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21)))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'%20Reload%20Insights',viewMode:view)&show-time-filter=true`
                :
                uploadTag === 'realtimelogs' ? 
                // `#/view/16618fc0-a6d9-11ed-a8b4-373d40244082?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:'realtimelogs'),type:phrase),query:(match_phrase:(upload_tag.keyword:'realtimelogs')))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true`
                // `#/view/04255490-3e82-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!f,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'%20Reload%20Insights',viewMode:view)&show-time-filter=true`
                // `#/view/04255490-3e82-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'%20Reload%20Insights',viewMode:view)&show-time-filter=true`
                // `#/view/04255490-3e82-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'%20Reload%20Insights',viewMode:view)&show-time-filter=true`
                `#/view/${(tenantname === 'd293axplcju2njyxmdi' || tenantname === 'cglyyw1hbdewnda0mda' || tenantname === 'chjlzg9vbguxmdyxmde2')? '5d489b60-d46c-11ee-ab80-d7610dedc4ad' : '5d489b60-d46c-11ee-ab80-d7610dedc4ad'}?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs)))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'%20Reload%20Insights',viewMode:view)&show-time-filter=true`
                :
                // "#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true"
                // `#/view/16618fc0-a6d9-11ed-a8b4-373d40244082?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true`
                // `#/view/04255490-3e82-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + "))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!t,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'%20Reload%20Insights',viewMode:view)&show-time-filter=true`
                // `#/view/04255490-3e82-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + "))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'%20Reload%20Insights',viewMode:view)&show-time-filter=true`
                `#/view/${(tenantname === 'd293axplcju2njyxmdi' || tenantname === 'cglyyw1hbdewnda0mda' || tenantname === 'chjlzg9vbguxmdyxmde2')? '5d489b60-d46c-11ee-ab80-d7610dedc4ad' : '5d489b60-d46c-11ee-ab80-d7610dedc4ad'}?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'%20Reload%20Insights',viewMode:view)&show-time-filter=true`
                )
                }
                style={iframeStyle}
                />
            }
            {activeTab === 3 && 
            <iframe 
                id="fullscreen-iframe"
                src={
                Kibana_URL + "app/dashboards?security_tenant=private&jwt=" + 
                (uploadTag === 'Sample Data' ? cookies.sample_token : cookies.kibana_token) + 
                (uploadTag === 'Sample Data' ? 
                // "#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag:SampleData-4-17-2023-10-07-50-AM-QV21)))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true"
                // `#/view/e88dcbc0-ea55-11ed-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:'SampleData-4-17-2023-10-07-50-AM-QV21'),type:phrase),query:(match_phrase:(upload_tag.keyword:'SampleData-4-17-2023-10-07-50-AM-QV21')))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Stats%20Details%20Dashboard',viewMode:view)&show-time-filter=true`
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!t,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!t,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&logtype=sample&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21)))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                :
                uploadTag === 'realtimelogs' ?
                // `#/view/e88dcbc0-ea55-11ed-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:'realtimelogs'),type:phrase),query:(match_phrase:(upload_tag.keyword:'realtimelogs')))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Stats%20Details%20Dashboard',viewMode:view)&show-time-filter=true`
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!f,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&logtype=realtime&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs)))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                :
                // "#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true"
                // `#/view/e88dcbc0-ea55-11ed-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Stats%20Details%20Dashboard',viewMode:view)&show-time-filter=true`
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!t,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + "))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + "))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Performance%20Monitor',viewMode:view)&show-time-filter=true`
                )
                }
                style={iframeStyle}
                />
            }
            {activeTab === 4 && 
            <iframe 
                id="fullscreen-iframe"
                src={
                Kibana_URL + "app/dashboards?security_tenant=private&jwt=" + 
                (uploadTag === 'Sample Data' ? cookies.sample_token : cookies.kibana_token) + 
                // Kibana_URL + "app/dashboards?security_tenant=private&jwt=" + cookies.sample_token + 
                (uploadTag === 'Sample Data' ? 
                // "#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag:SampleData-4-17-2023-10-07-50-AM-QV21)))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true"
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:'SampleData-4-17-2023-10-07-50-AM-QV21'),type:phrase),query:(match_phrase:(upload_tag.keyword:'SampleData-4-17-2023-10-07-50-AM-QV21')))),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Stats%20Details%20Dashboard',viewMode:view)&show-time-filter=true`
                // `#/view/854d6d40-3e6f-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!t,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Telemetry',viewMode:view)&show-time-filter=true`
                // `#/view/854d6d40-3e6f-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Telemetry',viewMode:view)&show-time-filter=true`
                // `#/view/854d6d40-3e6f-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Telemetry',viewMode:view)&show-time-filter=true`
                `#/view/854d6d40-3e6f-11ee-90f9-69815593cb4f?embed=true&logtype=sample&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:SampleData-4-17-2023-10-07-50-AM-QV21),type:phrase),query:(match_phrase:(upload_tag.keyword:SampleData-4-17-2023-10-07-50-AM-QV21)))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Telemetry',viewMode:view)&show-time-filter=true`
                :
                uploadTag === 'realtimelogs' ?
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:'SampleData-4-17-2023-10-07-50-AM-QV21'),type:phrase),query:(match_phrase:(upload_tag.keyword:'SampleData-4-17-2023-10-07-50-AM-QV21')))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Stats%20Details%20Dashboard',viewMode:view)&show-time-filter=true`
                // `#/view/854d6d40-3e6f-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!f,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Telemetry',viewMode:view)&show-time-filter=true`
                // `#/view/854d6d40-3e6f-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Telemetry',viewMode:view)&show-time-filter=true`
                // `#/view/854d6d40-3e6f-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Telemetry',viewMode:view)&show-time-filter=true`
                `#/view/854d6d40-3e6f-11ee-90f9-69815593cb4f?embed=true&logtype=realtime&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:realtimelogs),type:phrase),query:(match_phrase:(upload_tag.keyword:realtimelogs)))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Telemetry',viewMode:view)&show-time-filter=true`
                :
                // "#/view/b46f0a60-c9e2-11eb-8238-df506d4b3569?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Script%20Log%20Analysis',viewMode:view)&show-time-filter=true"
                // `#/view/8e066ab0-1be5-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:'SampleData-4-17-2023-10-07-50-AM-QV21'),type:phrase),query:(match_phrase:(upload_tag.keyword:'SampleData-4-17-2023-10-07-50-AM-QV21')))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Stats%20Details%20Dashboard',viewMode:view)&show-time-filter=true`
                // `#/view/854d6d40-3e6f-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + "))),('$state':(store:globalState),meta:(alias:LIVE,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:query,negate:!t,type:custom,value:'%7B%22range%22:%7B%22wow_timestamp%22:%7B%22gte%22:%22now-1h%2Fm%22,%22lt%22:%22now%2Fm%22%7D%7D%7D'),query:(range:(wow_timestamp:(gte:now-1h%2Fm,lt:now%2Fm)))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Telemetry',viewMode:view)&show-time-filter=true`
                // `#/view/854d6d40-3e6f-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + "))),('$state':(store:globalState),meta:(alias:'System%20Tasks',disabled:!t,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:ActiveUserDirectory.keyword,negate:!t,params:!(INTERNAL,Personal),type:phrases,value:'INTERNAL,%20Personal'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(ActiveUserDirectory.keyword:INTERNAL)),(match_phrase:(ActiveUserDirectory.keyword:Personal))))))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Telemetry',viewMode:view)&show-time-filter=true`
                `#/view/854d6d40-3e6f-11ee-90f9-69815593cb4f?embed=true&_g=${urlfilter ? urlfilter : "(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:" + uploadTag + "),type:phrase),query:(match_phrase:(upload_tag.keyword:" + uploadTag + ")))),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))"}&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'User%20Telemetry',viewMode:view)&show-time-filter=true`
                )
                }
                style={iframeStyle}
                />
            }
            </div>
            {loader}
      </div>
      </>
    )
  }
  
  export default LogsHeader