import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Switch, Tooltip, Typography } from '@mui/material';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import remediationData from '../../src/Remediation.json';
import { Link } from 'react-router-dom';
import RealTimeSideNav from '../components/RealTimeSideNav';
import HistoricalSideNav from '../components/HistoricalSideNav';

const Remediations = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const formattedRows = remediationData.map((item, index) => ({
      id: index,
      alert: item.alert,
      problemCause: item.problemCause,
      shortDescription: item.shortDescription,
      longDescription: item.longDescription,
      toggle: false,
    }));
    setRows(formattedRows);
  }, []);

  const handleToggleChange = (id) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, toggle: !row.toggle } : row
      )
    );
  };

  const columns = [
    {
      field: 'alert',
      headerName: 'Alert',
      headerClassName: 'super-app-theme--header',
      flex: 0.1,
      cellClassName: 'word-wrap',
    },
    {
      field: 'problemCause',
      headerName: 'Problem Cause',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      cellClassName: 'word-wrap',
    },
    {
        field: 'shortDescription',
        headerName: 'Wowizer Smart Fix',
        headerClassName: 'super-app-theme--header',
        flex: 0.3,
        cellClassName: 'word-wrap',
        renderCell: (params) => (
          <Tooltip title={params.row.longDescription} arrow placement="top">
            <span>
              {params.row.shortDescription}{" "}
              <Link href="#" onClick={(e) => e.preventDefault()}>
                Read more...
              </Link>
            </span>
          </Tooltip>
        ),
    },
    {
      field: 'toggle',
      headerName: 'Fix',
      headerClassName: 'super-app-theme--header',
    //   flex: 0.1,
    //   cellClassName: 'word-wrap',
      renderCell: (params) => (
        <Switch
          checked={params.row.toggle}
          onChange={() => handleToggleChange(params.row.id)}
        />
      ),
    },
  ];

  const currentNav = localStorage.getItem('currentNav');
  return (
    <>
      <SessionExpiredAlert />
      {currentNav === 'Real Time' ? <RealTimeSideNav /> : <HistoricalSideNav />}
      <Header />
      <div className="content-wrapper">
        <br />
        <Typography style={{ marginLeft: '15px' }}>
            <b>Wowizer's Smart Fix functionality empowers admins to configure standard practices for automatically addressing critical alert conditions. These automated actions mirror the steps an admin would typically take in response to such alerts, ensuring prompt and effective remediation.</b>
        </Typography>
        <br />
        <Box
          style={{ height: 450, width: '98%', marginLeft: '1%' }}
          sx={{
            '& .super-app-theme--header': {
              backgroundColor: '#060694',
              color: 'white',
              fontSize: '19px',
            },
            '& .word-wrap': {
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            },
          }}
        >
          <DataGrid rows={rows} columns={columns} pageSize={5} rowHeight={67} />
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default Remediations;