import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box, IconButton, TextField, CircularProgress, Button } from '@mui/material';
import { FcDownload } from "react-icons/fc";
import { Base_URL } from '../constants';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import RealTimeSideNav from '../components/RealTimeSideNav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const GeneratedReports = () => {
  const [reports, setReports] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  const navigate = useNavigate();  // Initialize useNavigate

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true); // Set loading to true before the request
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: Base_URL + '/reports/generated_reports',
          headers: {}
        };

        const response = await axios.request(config);
        setReports(response.data);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false); // Set loading to false after the request is complete
      }
    };

    fetchReports();
  }, []);

  const handleDownload = async (fileName) => {
    try {
      const data = JSON.stringify({
        "report_filename": fileName
      });

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/reports/generated_reports/download',
        headers: { 
          'Content-Type': 'application/json', 
        },
        data: data,
        responseType: 'blob'  // Ensure that the response is handled as a blob
      };

      const response = await axios.request(config);

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error during file download:', error);
    }
  };

  const filteredReports = reports.filter((report) =>
    report.fileName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 3,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 2,
      valueGetter: (params) => {
        const date = new Date(params.value);
        const options = {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          fractionalSecondDigits: 3,
          hour12: true
        };
        return new Intl.DateTimeFormat('en-US', options).format(date).replace(', ', ' @ ');
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'fileSize',
      headerName: 'File Size (KB)',
      flex: 1,
      valueGetter: (params) => (params.value / 1024).toFixed(2),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <IconButton onClick={() => handleDownload(params.row.fileName)}>
          <FcDownload />
        </IconButton>
      )
    }
  ];

  return (
    <>
      <SessionExpiredAlert />
      <RealTimeSideNav />
      <Header />
      <div className="content-wrapper">
        <br />
        <Box 
          style={{ height: 'auto', width: '98%', marginLeft: '1%' }}
          sx={{
            '& .super-app-theme--header': {
              backgroundColor: '#060694',
              color: 'white',
              fontSize: '19px'
            },
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', // Adjusted for spacing
              alignItems: 'center',
              marginBottom: '16px'
            }}
          >
            <TextField
              label="Search by File Name"
              variant="outlined"
              sx={{ width: '50%' }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button 
              variant="outlined"
              color="primary"
              onClick={() => navigate('/reporting')}
            >
              Reports Configuration
            </Button>
          </Box>

          {loading ? (
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: 350 
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <div style={{ height: 350, width: '100%' }}>
              <DataGrid
                rows={filteredReports}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row.fileName}
              />
            </div>
          )}
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default GeneratedReports;