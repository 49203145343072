import { FcSms } from "react-icons/fc";;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const handleSupport = () => {
    window.open('/aidrivendashboards', '_blank');
  };

  return (
    <div>
      <footer className="main-footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <strong>Copyright © {currentYear}. All rights reserved.</strong>
        <div className="chat-icon-container">
          <FcSms style={{ fontSize: '30px', cursor: 'pointer' }} onClick={handleSupport}/>
        </div>
      </footer>
    </div>
  )
}

export default Footer;
