import React from 'react';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Kibana_URL } from '../constants';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const MLDiscover = () => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const [cookies, removeCookie ] = useCookies(['access_token', 'sample_token','kibana_token']);
    let token = cookies.kibana_token;

    const uploadTag = queryParams.get('uploadtag');
    const startDate = queryParams.get('startDate');
    const endDate = queryParams.get('endDate');
    const hostname = queryParams.get('hostname');
    const logsource = queryParams.get('logsource');
    const level = queryParams.get('level');

    // URL-encode parameters for safe concatenation
    const encodedUploadTag = encodeURIComponent(uploadTag);
    const encodedHostname = encodeURIComponent(hostname);
    const encodedLogSource = encodeURIComponent(logsource);
    const encodedLevel = encodeURIComponent(level);
    
    const iframeSrc =
    Kibana_URL +
    // `app/data-explorer/discover?security_tenant=private&jwt=${token}#?_a=(discover:(columns:!(_source),isDirty:!f,sort:!()),metadata:(indexPattern:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',view:discover))&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:${encodedUploadTag}),type:phrase),query:(match_phrase:(upload_tag.keyword:${encodedUploadTag}))),('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:wow_hostname.keyword,negate:!f,params:(query:''),type:phrase),query:(match_phrase:(wow_hostname.keyword:''))),('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:log_source.keyword,negate:!f,params:(query:''),type:phrase),query:(match_phrase:(log_source.keyword:''))),('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:Level.keyword,negate:!f,params:(query:''),type:phrase),query:(match_phrase:(Level.keyword:'')))),refreshInterval:(pause:!t,value:0),time:(from:'${startDate}',to:'${endDate}'))&_q=(filters:!(),query:(language:kuery,query:''))&embed=true`;
    `app/data-explorer/discover?security_tenant=private&jwt=${token}#?_a=(discover:(columns:!(_source),isDirty:!f,sort:!()),metadata:(indexPattern:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',view:discover))&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'09a20f54-3fbf-4a8d-bbc5-3b3c14059223',key:upload_tag.keyword,negate:!f,params:(query:${encodedUploadTag}),type:phrase),query:(match_phrase:(upload_tag.keyword:${encodedUploadTag})))),refreshInterval:(pause:!t,value:0),time:(from:'${startDate}',to:'${endDate}'))&_q=(filters:!(),query:(language:kuery,query:''))&embed=true`;
    return (
        <>
            <SessionExpiredAlert />
            <SideNav />
            <Header />
            <div className="content-wrapper">
                <br />
                <iframe
                    src={iframeSrc}
                    width="100%"
                    height="500px"
                ></iframe>
            </div>
            <Footer />
        </>
    );
};

export default MLDiscover;