import { useEffect, useState } from "react";
import { FcManager } from "react-icons/fc"
import { FcSettings } from "react-icons/fc";
import axios from 'axios';
import { Base_URL, Logout_User, User } from "../constants";
import { Button, Menu, MenuItem } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import IntroJs from 'intro.js';
import 'intro.js/introjs.css';
import 'intro.js/themes/introjs-modern.css';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import swal from 'sweetalert';
import { NoBackpackSharp } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import useFullPageLoader from "./hooks/useFullPageLoader";

const startManageUsersOnboarding = (navigate) => {
  const intro = IntroJs();

  intro.setOptions({
    steps: [
      {
        element: '.sixth-step',
        intro: 'Here you can do admin configurations like User & Data Managenment, Alert Configurations.',
      },
      // {
      //   element: '.invite-btn',
      //   intro: 'Invite your team members from here.',
      // }
    ],
    exitOnOverlayClick: true,
    exitOnEsc: true,
  });

  intro.oncomplete(() => {
    swal({
      title: 'Journey Completed!',
      text: 'Thank you for exploring.',
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then(() => {
      navigate('/home');
    });
  });

  intro.start();
};

const startReportingOnboarding = (navigate) => {
  const intro = IntroJs();

  intro.setOptions({
    steps: [
      {
        element: '.fifth-step',
        intro: 'View & Manage Reports here.',
      },
    ],
    exitOnOverlayClick: true,
    exitOnEsc: true,
  });

  intro.oncomplete(() => {
    navigate('/manageusers');
    setTimeout(() => {
      startManageUsersOnboarding(navigate);
    }, 100);
  });

  intro.start();
};

const startAlertingOnboarding = (navigate) => {
  const intro = IntroJs();

  intro.setOptions({
    steps: [
      {
        element: '.fourth-step',
        intro: "Here, you'll discover details about generated alerts, alert rulesets, and customization options",
      },
      {
        element: '.priority-selection',
        intro: 'Gain an overview of alerts with different priorities and filter them based on their priority level.',
      },
      {
        element: '.hostname-dropdown',
        intro: 'Choose to view alerts specific to a particular hostname.',
      },
      {
        element: '.alerts-dropdown',
        intro: 'Choose to view alerts specific to a particular alert.',
      },
    ],
    exitOnOverlayClick: true,
    exitOnEsc: true,
  });

  intro.oncomplete(() => {
    navigate('/reporting');
    setTimeout(() => {
      startReportingOnboarding(navigate);
    }, 100);
  });

  intro.start();
};

const startRealTimeOnboarding = (navigate) => {
  const intro = IntroJs();

  intro.setOptions({
    steps: [
      {
        element: '.third-step',
        intro: 'Here you will Analyze your streaming realtime data.',
      }
    ],
    exitOnOverlayClick: true,
    exitOnEsc: true,
  });

  intro.oncomplete(() => {
    navigate('/alerting');
    setTimeout(() => {
      startAlertingOnboarding(navigate);
    }, 100);
  });

  intro.start();
};

const startAnalyzeOnboarding = (navigate) => {
  const intro = IntroJs();

  intro.setOptions({
    steps: [
      {
        element: '.second-step',
        intro: 'You can analyze any of your past uploads here and delete your uploaded data from Wowizer.',
      },
    ],
    exitOnOverlayClick: true,
    exitOnEsc: true,
  });

  intro.oncomplete(() => {
    navigate('/analyzelogs/realtimelogs/QV00/3');
    setTimeout(() => {
      startRealTimeOnboarding(navigate);
    }, 100);
  });

  intro.start();
};

const Header = () => {
  
  const navigate = useNavigate();
  const location = useLocation();

  const [firstname, setFirstName] = useState('');

  useEffect(() => {
    fetchData('');
  }, []);

  const fetchData = async => {
      var config = {
          method: 'get',
          url: Base_URL + User,
          headers: { }
        };
        
        axios(config)
        .then(function (response) {
          setFirstName(response.data.data.user.firstName);
          localStorage.setItem('first_name', response.data.data.user.firstName);
          localStorage.setItem('tenant_name', response.data.data.subscription.tenantName);
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  const startOnboarding = () => {
    const intro = IntroJs();
  
    intro.setOptions({
      steps: [
        {
          element: '.first-step',
          intro: 'Upon logging in, you will be directed to this default landing page, providing a starting point for your analysis journey.',
        },
        {
          element: '.sample-btn',
          intro: 'Experience Wowizer by exploring pre-prepared analyses and insights with sample data, providing a glimpse of its capabilities.',
        },
        {
          element: '.uploader',
          intro: 'You can import your historical data by uploading the Qlik Sense Collector extract archive here.',
        }
      ],
      exitOnOverlayClick: true,
      exitOnEsc: true,
    });
  
    let currentStepIndex = 0;
  
    intro.onbeforechange((targetElement) => {
      if (targetElement.classList.contains('sample-btn')) {
        currentStepIndex = 0;
      } else if (targetElement.classList.contains('uploader')) {
        currentStepIndex = 1;
      }
    });
  
    intro.oncomplete(() => {
      if (currentStepIndex === 1) {
        navigate('/analyze');
        setTimeout(() => {
          startAnalyzeOnboarding(navigate);
        }, 100);
      }
    });
  
    intro.start();
  };

  const useCaseRedirect = () => {
    navigate('/usecase');
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const [cookies, setCookie, removeCookie] = useCookies();

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMySubscriptions = () => {
    navigate('/subscription')
    handleMenuClose();
  };

  const handleHelp = () => {
    navigate('/help')
    handleMenuClose();
  };

  const handleLogout = () => {
    showLoader();
    var config = {
      method: 'get',
      url: Base_URL + Logout_User,
      headers: {}
    };

    axios(config)
        .then(function (response) {
          hideLoader();
          sessionStorage.clear();
          localStorage.clear();
          // Object.keys(cookies).forEach((cookieName) => {
          //   removeCookie(cookieName);
          // });
          window.location.href = '/';
        })
        .catch(function (error) {
          var config = {
          method: 'get',
          url: Base_URL + '/auth/refresh',
          headers: { }
          };

          axios(config)
          .then(function (response) {
            var config = {
              method: 'get',
              url: Base_URL + Logout_User,
              headers: {}
            };
            axios(config)
            .then(function (response) {
              hideLoader();
              localStorage.clear();
              // Object.keys(cookies).forEach((cookieName) => {
              //   removeCookie(cookieName);
              // });
              handleMenuClose();
              window.location.href = '/';
            })
          })
          .catch(function (error) {
            hideLoader();
            console.log(error);
          });
        });
  };

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
          </li>
          {location.pathname === '/home' && (
            <>
            <Button variant="outlined" endIcon={<SendIcon />} onClick={startOnboarding}>
              Demo Tour
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant="outlined" endIcon={<SendIcon />} onClick={useCaseRedirect}>
              Use Case
            </Button>
            </>
          )}
          <div className="welcome-div"
            onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
          >
            <FcSettings size={30}/> Welcome, {firstname}
          <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMySubscriptions}>
              My Subscriptions
            </MenuItem>
            {/* <MenuItem onClick={handleHelp}>
              Help & Support
            </MenuItem> */}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
          </div>
        </ul>
      </nav>
      {loader}
    </div>
  )
}

export default Header