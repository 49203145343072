import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { Email, Lock } from '@mui/icons-material';
import wave1 from '../images/wave_latest.svg';
import login from '../images/login (1).svg';
import wowlogo from '../images/New Wowizer Logo 1.png';
import '../App.css';
import { Link, useNavigate } from 'react-router-dom';
import { Base_URL, Login_User } from '../constants';
import axios from 'axios';
import swal from 'sweetalert';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import SendIcon from '@mui/icons-material/Send';

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [agreeToTerms, setAgreeToTerms] = useState(false);

    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const handleLoginSubmit = (e) => {
      showLoader();
      e.preventDefault();
  
      if (agreeToTerms) {
          var data = JSON.stringify({
              "email": email,
              "password": password
          });
  
          var config = {
              method: 'post',
              url: Base_URL + Login_User,
              headers: {
                  'Content-Type': 'application/json',
              },
              data: data
          };
  
          axios(config)
              .then(function (response) {
                  var config = {
                      method: 'get',
                      url: Base_URL + '/users/me',
                      headers: {}
                  };
  
                  axios(config)
                      .then(function (response) {
                        hideLoader();
                          const planId = response.data.data.subscription.planId;
                          const role = response.data.data.user.role;
                          if (role === "soc_user") {
                            window.location.href = '/adminportal';
                          } else if (planId === "Standard-Realtime") {
                              window.location.href = '/analyzelogs/realtimelogs/QV00/3';
                          } else {
                              window.location.href = '/analyze';
                          }
                      })
                      .catch(function (error) {
                          console.log(error);
                      });
              })
              .catch(function (error) {
                  hideLoader();
                  swal({
                      icon: 'error',
                      title: 'Login Failed',
                      text: 'Please check your credentials and try again',
                  });
              });
      } else {
          alert('Please agree to the Privacy Policy and License Terms & Conditions to log in.');
          hideLoader();
      }
  };  

    const navigate = useNavigate();

    const handleTrialRedirect = () => {
      navigate('/checkoutwowizer');
    };
  return (
    <>
      <img className="wave" src={wave1} />
      <div className="container-login">
        <div className="img">
          <img className='login-image' src={login} />
        </div>
        <div className="login-content">
          <form className='form-class-login' onSubmit={handleLoginSubmit}>
            <div className="row">
              <div className="col">
                <img className='actionate-img' src={wowlogo} width="90%" style={{ marginLeft: '-2%' }} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                <div style={{ fontSize: '20px', color: '#000080' }}>Take control of Qlik user experience and drive adoption.</div>
                <br />
                <div className="row">
                <div className="col">
                  {/* <Button variant="contained" endIcon={<SendIcon />} onClick={handleTrialRedirect}>
                    Try without Sign Up
                  </Button> */}
                </div>
                </div>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  InputProps={{
                    startAdornment: <Email />,
                  }}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    startAdornment: <Lock />,
                  }}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={agreeToTerms}
                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                    style={{ marginRight: '8px' }}
                  />
                  <span>
                    By logging in, you agree to the <a href='https://wowizer.ai/privacy-policy/' target="_blank">Privacy Policy</a> and <a href='https://wowizer.ai/eula/' target="_blank">License Terms & Conditions</a>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ borderRadius: '50px' }}
                  type='submit'
                >
                  Login
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                {/* <Link to="/resetpassword" style={{ textDecoration: 'none', fontWeight: "bolder"}}>
                    Forgot Password?
                </Link> */}
              </div>
              {/* <div className="col">
                <br />
                <Link to="/testdrive" style={{ textDecoration: 'none', fontWeight: "bolder"}}>
                    Try without Sign Up
                </Link>
              </div> */}
              <div className="col">
                <br />
                {/* <Link to="/register" style={{ textDecoration: 'none', fontWeight: "bolder"}}>
                    Register User
                </Link> */}
              </div>
            </div>
          </form>
        </div>
        {loader}
      </div>
    </>
  );
};

export default Login;
