import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { Base_URL } from '../constants';
import swal from 'sweetalert';

const SessionExpiredAlert = () => {
  const [cookies, setCookie, removeCookie ] = useCookies(['access_token']);

  useEffect(() => {
    if (!cookies.refresh_token) {
        if (!cookies.refresh_token) {
            swal({
              title: 'Session Expired',
              text: 'Please log in again.',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Log In',
            }).then((result) => {
              if (result) {
                localStorage.clear();
                // Object.keys(cookies).forEach((cookieName) => {
                // removeCookie(cookieName);
                // });
                window.location.href = '/';
              }
            });
            return;
        }
    }

    if (!cookies.access_token) {
        var config = {
        method: 'get',
        url: Base_URL + '/auth/refresh',
        headers: { }
        };

        axios(config)
        .then(function (response) {
            window.location.reload();
        })
        .catch(function (error) {
        console.log(error);
        });
    }
  }, []);

  return <div></div>;
};

export default SessionExpiredAlert;