class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  parse(message) {
    const lowerCaseMessage = message.toLowerCase();

    if (lowerCaseMessage.includes('hello')) {
      this.actionProvider.handleHello();
    } else if (lowerCaseMessage.includes('system performance check')) {
      this.actionProvider.handleQuery('give me system performance check');
    } else {
      this.actionProvider.handleQuery(message);
    }
  }
}

export default MessageParser;