import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Base_URL } from '../constants';
import axios from 'axios';
import swal from 'sweetalert';

const TestDriveVerify = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let token=searchParams.get("access_auth");
    
    var data = JSON.stringify({
    "access_auth": token
    });

    var config = {
    method: 'post',
    url: Base_URL + '/trialdrive/verify_trial/',
    headers: { 
        'Content-Type': 'application/json'
    },
    data : data
    };

    axios(config)
    .then(function (response) {
        var config = {
        method: 'get',
        url: Base_URL + '/users/me',
        headers: { }
        };
        axios(config)
        .then(function (response) {
            localStorage.setItem('userEmail', response.data.data.user.email);
            localStorage.setItem('userRole', response.data.data.user.role);
            window.location.href = '/welcome';
        })
        .catch(function (error) {
            console.log(error);
        });
    })
    .catch(function (error) {
        swal("Error", error.response.data.message, "error").then(function () {
            window.location.href = '/'
          });
    });

    return (
        <>
        </>
    )
}

export default TestDriveVerify