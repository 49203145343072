import axios from 'axios';
import { Base_URL } from '../../constants';

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  handleHello = () => {
    const message = this.createChatBotMessage('Hello. How can I help you?');
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleQuery = (query) => {
    const data = JSON.stringify({ query });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Base_URL + '/users/dashboard/search',
      headers: { 
        'Content-Type': 'application/json',
      },
      data: data
    };

    const typingMessage = this.createChatBotMessage('Typing...');
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, typingMessage],
    }));
  
    axios.request(config)
      .then((response) => {
        this.setState((prev) => ({
          ...prev,
          messages: prev.messages.filter((message) => message !== typingMessage),
        }));
  
        if (response.data.status === "success") {
          const dashList = response.data.dash_list;
          const message = this.createChatBotMessage(
            'Here are the list of helpful dashboards:',
            {
              widget: 'dashList',
              payload: dashList
            }
          );
          this.setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
          }));
        } else {
          const message = this.createChatBotMessage('Sorry, something went wrong. Please try again.');
          this.setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
          }));
        }
      })
      .catch((error) => {
        this.setState((prev) => ({
          ...prev,
          messages: prev.messages.filter((message) => message !== typingMessage),
        }));
  
        const message = this.createChatBotMessage('Sorry, something went wrong. Please try again.');
        this.setState((prev) => ({
          ...prev,
          messages: [...prev.messages, message],
        }));
      });
  };
}

export default ActionProvider;