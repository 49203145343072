import React, { useState } from 'react';
import { Button, Checkbox, Container, FormControlLabel, Grid, Typography } from '@mui/material';
import wowlogo from '../images/New Wowizer Logo 1.png';

const TestDriveLanding = () => {
    const email = localStorage.getItem('userEmail');
    const [agreeToTerms, setAgreeToTerms] = useState(false);

    const handleTestDrive = () => {
        if (!agreeToTerms) {
            alert('Please agree to the Privacy Policy and License Terms & Conditions to sign up.');
            return;
        }

        window.location.href = '/analyzelogs/Sample%20Data/QV00/3';
    };

    return (
        <Container maxWidth="md" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                <Grid item xs={12} textAlign="center">
                    <img src={wowlogo} alt="Wowizer Logo" style={{ width: '300px', height: 'auto' }} />
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <Typography variant="h4" gutterBottom>
                        Welcome to Wowizer!
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        Test drive our amazing features now.
                    </Typography>
                    <br />
                    <Typography variant="h5" gutterBottom>
                        Email: {email}
                    </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <FormControlLabel
                        control={<Checkbox checked={agreeToTerms} onChange={() => setAgreeToTerms(!agreeToTerms)} />}
                        label={
                            <span>
                                By clicking here, you agree to the{' '}
                                <a href='https://wowizer.ai/privacy-policy/' target="_blank" style={{ cursor: 'pointer' }}>
                                    Privacy Policy
                                </a>{' '}
                                and{' '}
                                <a href='https://wowizer.ai/eula/' target="_blank" style={{ cursor: 'pointer' }}>
                                    License Terms & Conditions
                                </a>
                            </span>
                        }
                    />
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleTestDrive}
                        disabled={!agreeToTerms}
                    >
                        Test Drive Now !
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default TestDriveLanding;