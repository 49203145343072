import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SideNav from '../components/SideNav';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Button, Box, CircularProgress } from '@mui/material'; // Import CircularProgress
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import axios from 'axios';
import { Base_URL } from '../constants';
import { styled } from '@mui/material/styles';

const ClusterSearch = () => {
  const [searchQuery, setSearchQuery] = useState('memory');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSearchResults = (query) => {
    setLoading(true);
    var data = JSON.stringify({
      "query_text": query
    });

    var config = {
      method: 'post',
      url: Base_URL + '/logcluster/relevant_search',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (Array.isArray(response.data.data)) {
          setSearchResults(response.data.data);
        } else {
          console.error("Search results are not in the expected format");
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSearchResults(searchQuery);
  }, []);
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });
  const columns = [
    { field: 'formattedts', headerName: 'Timestamp', headerClassName: 'super-app-theme--header', width: 150 ,
    renderCell: (params) => (
      <Tooltip title={params.value} >
      <span className="table-cell-trucate"  style={{ fontSize:"12px" }}>{params.value}</span>
      </Tooltip>
    ),
  },
    { 
      field: 'Message', 
      headerName: 'Message',
      headerClassName: 'super-app-theme--header',
      width: 800,
      renderCell: (params) => (
        <CustomWidthTooltip title={params.value} placement="bottom-start" >
        <span className="table-cell-trucate"  style={{ fontSize:"12px" }}>{params.value}</span>
        </CustomWidthTooltip>
      ),
    },
    { 
      field: 'log_source', 
      headerName: 'Log Source',
      headerClassName: 'super-app-theme--header',
      flex: 0.1,
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.value} >
        <span className="table-cell-trucate" style={{ fontSize:"12px" }}>{params.value}</span>
        </Tooltip>
      ),
    },
  ];

  const rows = searchResults.map((result, index) => {
    const wow_timestamp = new Date(result._source.wow_timestamp);
    const formattedts = wow_timestamp.toLocaleString();
    return {id: index + 1,
      formattedts,
    ...result._source}
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchClick = () => {
    fetchSearchResults(searchQuery);
  };

  return (
    <>
      <SessionExpiredAlert />
      <SideNav />
      <Header />
      <div className="content-wrapper">
        <br />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Anomaly Search"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ marginLeft: '1%', width: '300px' }}
          />
          <Button variant="contained" onClick={handleSearchClick} color="primary" style={{ marginLeft: '1%' }}>
            Search
          </Button>
        </div>
        <br />
        <Box style={{ height: '70vh', width: '98%', marginLeft: '1%' }}
            sx={{
              '& .super-app-theme--header': {
                backgroundColor: '#060694',
                color: 'white',
                fontWeight:"bold !important",
                fontSize: '14px'
              },
            }}
            >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            loading={loading}
            loadingOverlay={
              <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                <CircularProgress color="primary" />
              </div>
            }
          />
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default ClusterSearch;