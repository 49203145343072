import React, { useState } from 'react';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Box, Button } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const UseCase = () => {
  const [expanded, setExpanded] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);

  const handleToggle = (event, nodeId) => {
    setExpanded(nodeId === expanded ? null : nodeId);
    setSelectedNode(nodeId);
  };

  const renderImage = () => {
    switch (selectedNode) {
      case '2':
        return (
          <>
            <img src="/Engine Service Was Down Alert.png" alt="Engine Service Was Down Alert" style={{ width: '100%', height: '85%' }} />
            <div>
              <b>Engine Service Was Down Alert</b>
              <br />
              Alert that is generated when the core engine service of a system is not functioning correctly or is temporarily unavailable. 
              <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '-1%' }} onClick={() => setSelectedNode('3')}>
                Next
              </Button>
            </div>
          </>
        );
        case '3':
        return (
          <>
            <img src="/High Memory Spike Up.png" alt="High Memory Spike Up" style={{ width: '100%', height: '75%' }} />
            <div>
              <b>High Memory Spike Up</b>
              <br />
              Notification or warning generated by a computer system or monitoring tool when there is a sudden and significant increase in the utilization of RAM.
              <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('4')}>
                Next
              </Button>
            </div>
          </>
        );
        case '4':
        return (
          <>
            <img src="/Engine Service Was Down Alert Due to Memory Issue.png" alt="Engine Service Was Down Alert Due to Memory Issue" style={{ width: '100%', height: '80%' }} />
            <div>
              <b>Engine Service Was Down Alert Due to Memory Issue</b>
              <br />
              Notification indicating that a critical software or system service, referred to as the "engine service," has experienced downtime or disruption. 
              {/* <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('6')}>
                Next
              </Button> */}
            </div>
          </>
        );
        case '6':
        return (
          <>
            <img src="/Alert Certain Nodes consuming continuously High CPU.png" alt="Alert Certain Nodes consuming continuously High CPU" style={{ width: '100%', height: '75%' }} />
            <div>
              <b>Alert Certain Nodes consuming continuously High CPU</b>
              <br />
              Notification that indicates specific nodes within a computing environment are consistently utilizing a significant portion of the CPU resources.
              <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('7')}>
                Next
              </Button>
            </div>
          </>
        );
        case '7':
        return (
          <>
            <img src="/High CPU & RAM Utilization.png" alt="High CPU & RAM Utilization" style={{ width: '100%', height: '78%' }} />
              <b>High CPU & RAM Utilization</b>
              <br />
              Notification that informs system administrators or users that the CPU and RAM usage on a computer or server has reached elevated levels. 
              <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('8')}>
                Next
              </Button>
          </>
        );
        case '8':
        return (
          <>
            <img src="/Resource Utilization Performance App Wise RAM Consumption.png" alt="Resource Utilization Performance App Wise RAM Consumption" style={{ width: '100%', height: '80%' }} />
            <b>Resource Utilization Performance App Wise RAM Consumption</b>
            <br />
            Notification that indicates specific applications within a system are consuming an elevated amount of RAM. 
            <div>
              <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('9')}>
                Next
              </Button>
            </div>
          </>
        );
        case '9':
        return (
          <>
            <img src="/Reload Insights App wise RAM Consumption.png" alt=" Reload Insights App wise RAM Consumption" style={{ width: '100%', height: '80%' }} />
              <b>Reload Insights App wise RAM Consumption</b>
              <br />
              Notification that specifically relates to the "Reload Insights" application and signals an elevated consumption of random access memory RAM by this particular application.
              {/* <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('11')}>
                Next
              </Button> */}
          </>
        );
        case '11':
        return (
          <>
            <img src="/Feedback Input.png" alt="Feedback Input" style={{ width: '100%', height: '75%' }} />
            <div>
              <b>Feedback Input</b>
              <br />
              Serves as a mechanism to highlight the importance of user feedback and encourages a responsive and customer-centric approach to system management and development.
              <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('12')}>
                Next
              </Button>
            </div>
          </>
        );
        case '12':
        return (
          <>
            <img src="/Feedback Input App wise RAM Utilization.png" alt="Feedback Input App wise RAM Utilization" style={{ width: '100%', height: '80%' }} />
            <div>
              <b>Feedback Input App wise RAM Utilization</b>
              <br />
              Notification that informs system administrators or relevant personnel about elevated RAM
              <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('13')}>
                Next
              </Button>
            </div>
          </>
        );
        case '13':
        return (
          <>
            <img src="/Heavey RAM Utilization by Reload App.png" alt="Heavey RAM Utilization by Reload App" style={{ width: '100%', height: '85%' }} />
            <div>
              <b>Heavy RAM Utilization by Reload App</b>
              <br />
              Indicates the Reload application is utilizing a substantial amount of RAM
              {/* <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('15')}>
                Next
              </Button> */}
            </div>
          </>
        );
        case '15':
        return (
          <>
            <img src="/Heavy Apps Consuming more then 1 GB Size.png" alt="Heavy Apps Consuming more then 1 GB Size" style={{ width: '100%', height: '78%' }} />
            <div>
              <b>Heavy Apps Consuming more then 1 GB Size</b>
              <br />
              Notification that indicates specific applications within the system are consuming an unusually large amount of storage space, exceeding the threshold of 1 GB.
              <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('16')}>
                Next
              </Button>
            </div>
          </>
        );
        case '16':
        return (
          <>
            <img src="/Heavy Apps Consuming more then 1 GB Size Object Optmization.png" alt="Heavy Apps Consuming more then 1 GB Size Object Optmization" style={{ width: '100%', height: '80%' }} />
            <div>
              <b>Heavy Apps Consuming more then 1 GB Size Object Optmization</b>
              <br />
              Notification that indicates specific applications within the system are consuming an unusually large amount of storage space, exceeding the threshold of 1 GB.
              {/* <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('16')}>
                Next
              </Button> */}
            </div>
          </>
        );
      default:
        return (
          <>
          {/* <Button variant='contained' style={{ position: 'absolute', right: 10, marginTop: '1%' }} onClick={() => setSelectedNode('2')}>
            Next
          </Button> */}
          <div style={{ height: '450px', overflowY: 'auto' }}>
          <br />
          <ol>
          <li><b onClick={() => setSelectedNode('2')} style={{ cursor: 'pointer', color: 'blue' }}>Engine Down</b> - The Engine Down Alert in Wowizer triggers when there is a disruption in the Qlik Sense Engine service, indicating a potential critical issue. This alert provides real-time notification, allowing administrators to swiftly address the problem, minimize downtime, and ensure continuous performance of the Qlik Sense environment.</li>
          <br />
          <li><b onClick={() => setSelectedNode('6')} style={{ cursor: 'pointer', color: 'blue' }}>CPU Utilization Alert</b> - The High CPU/RAM Utilization Alert in Wowizer is activated when CPU or RAM usage surpasses predefined thresholds. This alert serves as an early warning system, notifying administrators of potential resource bottlenecks that could impact Qlik Sense performance. By promptly addressing these alerts, teams can optimize resource allocation, prevent system slowdowns, and maintain a responsive BI platform.</li>
          <br />
          <li><b onClick={() => setSelectedNode('11')} style={{ cursor: 'pointer', color: 'blue' }}>Feedback</b> - The Feedback Use Case in Wowizer involves leveraging user insights collected through the Feedback Collection extension. This feature seamlessly integrates with Qlik Sense apps, allowing users to provide real-time feedback on performance issues or challenges encountered while using the application. Additionally, Wowizer enables users to superimpose this feedback data onto performance charts, creating a valuable correlation between user-reported problems and corresponding system metrics. This integration facilitates a deeper understanding of the user experience, empowering organizations to prioritize and address issues that have a direct impact on user satisfaction and overall BI platform performance.</li>
          <br />
          <li><b onClick={() => setSelectedNode('15')} style={{ cursor: 'pointer', color: 'blue' }}>Resource Usage</b> - The Apps or Objects Level High Resource Consumption Use Case in Wowizer addresses scenarios where specific applications or objects within Qlik Sense exhibit unusually high resource usage, such as CPU or RAM. Wowizer provides detailed insights into the resource consumption patterns of individual apps and objects, allowing users to identify potential bottlenecks or performance issues. This information enables organizations to optimize resource allocation, enhance system performance, and ensure efficient utilization of resources within the Qlik Sense environment. Wowizer's granular analysis at the app and object level facilitates targeted optimization efforts, leading to a more streamlined and high-performing BI platform.</li>
          </ol>
          </div>
          </>
        );
    }
  };  

  return (
    <>
      <SessionExpiredAlert />
      <SideNav />
      <Header />
      <div className='content-wrapper' style={{ display: 'flex', height: '100%' }}>
        <br />
        <Box sx={{ width: '20%', padding: '1rem', borderRight: '1px solid #ccc' }}>
          <TreeView
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
            expanded={expanded ? [expanded] : []}
            onNodeToggle={(_, nodeIds) => setExpanded(nodeIds.length > 0 ? nodeIds[0] : null)}
          >
            <TreeItem
              nodeId="1"
              label="Engine Down"
              // onClick={(event) => handleToggle(event, "1")}
            >
              <TreeItem nodeId="2" label={<span><span style={{ fontWeight: 'bold' }}>1.</span> Engine Service Was Down Alert</span>} onClick={(event) => handleToggle(event, "2")} />
              <TreeItem nodeId="3" label={<span><span style={{ fontWeight: 'bold' }}>2.</span> High Memory Spike Up</span>} onClick={(event) => handleToggle(event, "3")} />
              <TreeItem nodeId="4" label={<span><span style={{ fontWeight: 'bold' }}>3.</span> Engine Service Was Down Alert Due to Memory Issue</span>} onClick={(event) => handleToggle(event, "4")} />
            </TreeItem>
            <TreeItem
              nodeId="5"
              label="CPU Utilization Alert"
              // onClick={(event) => handleToggle(event, "5")}
            >
              <TreeItem nodeId="6" label={<span><span style={{ fontWeight: 'bold' }}>1.</span> Alert Certain Nodes consuming continuously High CPU</span>} onClick={(event) => handleToggle(event, "6")} />
              <TreeItem nodeId="7" label={<span><span style={{ fontWeight: 'bold' }}>2.</span> High CPU & RAM Utilization</span>} onClick={(event) => handleToggle(event, "7")} />
              <TreeItem nodeId="8" label={<span><span style={{ fontWeight: 'bold' }}>3.</span> Resource Utilization Performance App Wise RAM Consumption</span>} onClick={(event) => handleToggle(event, "8")} />
              <TreeItem nodeId="9" label={<span><span style={{ fontWeight: 'bold' }}>4.</span> Reload Insights App wise RAM Consumption</span>} onClick={(event) => handleToggle(event, "9")} />
            </TreeItem>
            <TreeItem
              nodeId="10"
              label="Feedback"
              // onClick={(event) => handleToggle(event, "10")}
            >
              <TreeItem nodeId="11" label={<span><span style={{ fontWeight: 'bold' }}>1.</span> Feedback Input</span>} onClick={(event) => handleToggle(event, "11")} />
              <TreeItem nodeId="12" label={<span><span style={{ fontWeight: 'bold' }}>2.</span> Feedback Input App wise RAM Utilization</span>} onClick={(event) => handleToggle(event, "12")} />
              <TreeItem nodeId="13" label={<span><span style={{ fontWeight: 'bold' }}>3.</span> Heavy RAM Utilization by Reload App</span>} onClick={(event) => handleToggle(event, "13")} />
            </TreeItem>
            <TreeItem
              nodeId="14"
              label="Resource Usage"
              // onClick={(event) => handleToggle(event, "14")}
            >
              <TreeItem nodeId="15" label={<span><span style={{ fontWeight: 'bold' }}>1.</span> Heavy Apps Consuming more than 1 GB Size</span>} onClick={(event) => handleToggle(event, "15")} />
              <TreeItem nodeId="16" label={<span><span style={{ fontWeight: 'bold' }}>2.</span> Heavy Apps Consuming more than 1 GB Size Object Optimization</span>} onClick={(event) => handleToggle(event, "16")} />
            </TreeItem>
          </TreeView>
        </Box>
        {/* <Box sx={{ width: '80%' }}>
          <img
            src="/Engine Service Was Down Alert.png"
            alt="Your Image"
            style={{ width: '100%', height: '85%' }}
          />
          Here the text will be shown
        </Box> */}
        <Box sx={{ width: '80%' }}>
          {renderImage()}
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default UseCase;