import React, { useState } from 'react'
import SessionExpiredAlert from '../components/SessionExpiredAlert'
import SideNav from '../components/SideNav'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Base_URL } from '../constants'
import axios from 'axios';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Box, Button, Checkbox, FormControl, FormLabel, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography,Card,CardContent, IconButton } from '@mui/material'
import { Chip } from '@mui/material'

import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import swal from 'sweetalert';
import { BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, PieChart, Pie } from 'recharts';
import { FcInfo } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'

const AlertDataBlock=(props)=>{
  return( <>
  <TableContainer><Table><TableBody>
  {
      Object.keys(props.alertData).map((x)=>
      {
        if(x=='facts'){
         
          
          return (<Card><CardContent>
            {
            props.alertData['facts'].map((dataVal)=>{
              // const regex_sel = /[^0-9.]/g;
              // let tval=dataVal.value.replace(regex_sel, ``);   
              // if(!isNaN(tval) && tval!="")
              // {
              //   let formatedValue=parseFloat(dataVal.value).toFixed(2);
              //   dataVal.value=dataVal.value.replace(tval,formatedValue)
              // }
                  return (
                   <div style={{display:'block'}}>
                    <div style={{display:'inline-block'}}> <b>{dataVal.name}</b>:</div>
                    <div style={{display:'inline-block'}}>  {dataVal.value}  </div> 
                    </div>
                  )
                })
            // props.alertData['facts'].map((dataVal) => {
            //   if (dataVal.name === 'RAM') {
            //     const formattedValue = parseFloat(dataVal.value).toFixed(0);
            //     return (
            //       <div style={{ display: 'block' }}>
            //         <div style={{ display: 'inline-block' }}>
            //           <b>{dataVal.name}</b>:
            //         </div>
            //         <div style={{ display: 'inline-block' }}>{formattedValue} MB</div>
            //       </div>
            //     );
            //   } else {
            //     return (
            //       <div style={{ display: 'block' }}>
            //         <div style={{ display: 'inline-block' }}>
            //           <b>{dataVal.name}</b>:
            //         </div>
            //         <div style={{ display: 'inline-block' }}>{dataVal.value}</div>
            //       </div>
            //     );
            //   }
            // })            
            }
          </CardContent></Card>);
        }
        else if(x=='msg')      
        {
          return (<Card><CardContent>MSG:{props.alertData[x]}</CardContent></Card>)
        }
      }
      )
  } 
  </TableBody></Table></TableContainer>
  </>
  )
}

const AlertInfoDataBlock=(props)=>{
  return( <>
  <TableContainer><Table><TableBody>
  {
      Object.keys(props.alertInfoData).map((x)=>
      {
          return (<Card><CardContent>
            {
              <div style={{display:'block', marginBottom: '-2%'}}>
              <div style={{display:'inline-block'}}> <b>{x}</b>:</div>
              <div style={{display:'inline-block'}}>  {props.alertInfoData[x]}  </div> 
              </div> 
            }
          </CardContent></Card>);
      }
      )
  } 
  </TableBody></Table></TableContainer>
  </>
  )
}

const AlertingDemo = () => {
    
    const [data, setData] = React.useState({});
    const [monitordata, setMonitorData] = React.useState({});

    const [alertinfoopen, setAlertInfoOpen] = React.useState(false);
    
    const [viewalertinfodata, setViewAlertInfoData] = React.useState({});

    const [webhookstatus, setWebhookStatus] = React.useState();
    
    function handleAlertInfoOpen(alert_info){
      setViewAlertInfoData({
        'data': alert_info
      });

      setAlertInfoOpen(true);
    }

    const handleAlertInfoClose = () => setAlertInfoOpen(false);

    const columns = [
      { field: 'created_at', headerClassName: 'super-app-theme--header', headerName: 'Date-Time', flex: 0.5, cellClassName: (params) => {
        return 'word-wrap';
      } },
      { field: 'hostname', headerClassName: 'super-app-theme--header', headerName: 'Host Name', flex: 0.5, cellClassName: (params) => {
        return 'word-wrap';
      } },
      { field: 'alert_name', headerClassName: 'super-app-theme--header', headerName: 'Alert Name', flex: 1, cellClassName: (params) => {
        return 'word-wrap';
      } },
      {
        field: 'severity',
        headerClassName: 'super-app-theme--header',
        headerName: 'Severity',
        flex: 0.5,
        cellClassName: 'word-wrap',
        renderCell: (params) => {
          const severityValue = params.value;
          let severityText = '';
    
          switch (severityValue) {
            case 1:
              severityText = 'High';
              break;
            case 2:
              severityText = 'High';
              break;
            case 3:
              severityText = 'Medium';
              break;
            case 4:
              severityText = 'Low';
              break;
            case 5:
              severityText = 'Info';
              break;
            default:
              severityText = 'Unknown';
          }
          return <span>{severityText}</span>;
        },
      },
      { field: 'data', headerClassName: 'super-app-theme--header', headerName: 'Alert Data', flex: 0.5, cellClassName: (params) => {
        return 'word-wrap';
      },
      renderCell: (params) => (
        <div>
          <Button onClick={() => handleOpenViewData(params.value, params.row.created_at, params.row.severity)} variant="contained" color="success">View Data</Button>
        </div>
      ) }
    ];

    const monitorcolumns = [    
      // { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'ID', flex: 0.1, cellClassName: (params) => {
      //   return 'word-wrap';
      // } },
      { field: 'monitor_name', headerClassName: 'super-app-theme--header', headerName: 'Ruleset Name', flex: 0.9, cellClassName: (params) => {
        return 'word-wrap';
      } },  
      { field: 'monitor_severity', headerClassName: 'super-app-theme--header', headerName: 'Severity', flex: 0.2, cellClassName: (params) => {
        return 'word-wrap';
      } },  
      { field: 'status', headerClassName: 'super-app-theme--header', headerName: 'Ruleset Status', flex: 0.4, cellClassName: (params) => {
        return 'word-wrap';
      },
      renderCell: (params) => {
        const isMonitorEnabled = params.value;
        return isMonitorEnabled ? (
          <span style={{ color: 'green'}} >Enabled</span>
        ) : (
          <span style={{ color: 'red'}} >Disabled</span>
        );
      }, },
      {
        field: 'alert_info',
        headerClassName: 'super-app-theme--header',
        headerName: 'Info',
        flex: 0.3,
        cellClassName: (params) => {
          return 'word-wrap';
        },
        renderCell: (params) => (
          <><FcInfo onClick={() => handleAlertInfoOpen(params.value)} size={30} style={{ cursor: 'pointer' }}/>
          {/* <Button onClick={() => handleAlertInfoOpen(params.value)} variant="contained" color="success">View Data</Button> */}
          </>
        ),
      },
      {
        field: 'monitor_status',
        headerClassName: 'super-app-theme--header',
        headerName: 'Action',
        flex: 0.4,
        sortable: false,
        cellClassName: (params) => {
          return 'word-wrap';
        },
        renderCell: (params) => {
          const isMonitorEnabled = params.value;
          return isMonitorEnabled ? (
            <Button size="small" onClick={() => handleStatusChange(params.row)} variant="outlined" color="error">Disable</Button>
          ) : (
            <Button size="small" onClick={() => handleStatusChange(params.row)} variant="contained" color="success">Enable</Button>
          );
        },
      },
      {
        field: 'webhook_status',
        headerClassName: 'super-app-theme--header',
        headerName: 'Webhook',
        flex: 0.4,
        sortable: false,
        cellClassName: (params) => {
          return 'word-wrap';
        },
        renderCell: (params) => {
          const isWebhookEnabled = params.value;
          return isWebhookEnabled ? (
            <Button size="small" onClick={() => handleWebhookStatusChange(params.row)} variant="outlined" color="error">Deactivate</Button>
          ) : (
            <Button size="small" onClick={() => handleWebhookStatusChange(params.row)} variant="contained" color="success">Activate</Button>
          );
        },
      }
    ];

    function handleStatusChange(row) {
      swal({
        title: "Are you sure to update the setting?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          let status = row.monitor_status;
      
          status = !status;

          var data = JSON.stringify({
            "monitor_status": status,
            "monitor_id": row.monitor_id
          });
          
          var config = {
            method: 'post',
            url: Base_URL + '/alerts/monitor/status/',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            
          })
          .catch(function (error) {
            console.log(error);
          });      
          swal("Setting Updated Successfully!", {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal("Settings not changed!");
        }
      });
    }

    function handleWebhookStatusChange(row) {
      swal({
        title: "Are you sure to update the webhook?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          let status = row.webhook_status;
          
          status = !status;
          
          var data = JSON.stringify({
              "webhook_name": webhookname,
              "webhook_status": status,
              "monitor_id": row.monitor_id
          });
          
          var config = {
            method: 'patch',
            url: Base_URL + '/alerts/webhook/status',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            
          })
          .catch(function (error) {
            console.log(error);
          });      
          swal("Setting Updated Successfully!", {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal("Settings not changed!");
        }
      });
    }

    function handleWebhookStatusChangeAll() {
      swal({
        title: "Are you sure to update the webhook?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          let status = webhookstatus;
          status = !status;
          
          var data = JSON.stringify({
              "webhook_name": webhookname,
              "webhook_status": status,
          });
          
          var config = {
            method: 'patch',
            url: Base_URL + '/alerts/webhook/status',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            
          })
          .catch(function (error) {
            console.log(error);
          });      
          swal("Setting Updated Successfully!", {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal("Settings not changed!");
        }
      });
    }

    function UpdateAll(status) {
      swal({
        title: "Are you sure to update settings for all?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          var data = JSON.stringify({
            "monitor_status": status
          });
          
          var config = {
            method: 'post',
            url:  Base_URL + '/alerts/monitors/status/',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            
          })
          .catch(function (error) {
            console.log(error);
          });
          swal("Settings Updated Successfully!", {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal("Settings not changed!");
        }
      });
    }

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(8);

    const handleChangePage = (newPage) => {
      setPage(newPage);
    };

    const handleChangePageSize = (newPageSize) => {
      setPageSize(newPageSize);
      setPage(0);
    };

    const totalRows = data.length;
    
    const [open, setOpen] = useState(false);
    const [openmonitor, setOpenMonitor] = useState(false);

    const [showDropdown, setShowDropdown] = useState(false);
    
    const handleSettingsClick = () => {
      setShowDropdown(!showDropdown);
    };

    const handleAlertsClick = () => {
      setOpen(true);
      var config = {
        method: 'get',
        url:  Base_URL + '/alerts/monitors/',
        headers: { }
      };
      
      axios(config)
      .then(function (response) {
        const MonitorData = response.data.data.map((element, index) => {
          return {
            "id" : index + 1,
            "monitor_id" : element.monitor_id,
            "monitor_name": element.monitor_name,
            "monitor_severity": element.monitor_severity,
            "monitor_status": element.monitor_status,
            "alert_info": element.wow_alert_info,
            "status": element.monitor_status,
            "webhook_status": element.webhook_status
          };
          });
          MonitorData.sort((a, b) => a.monitor_severity - b.monitor_severity);
          setMonitorData(MonitorData);
      })
      .catch(function (error) {
        console.log(error);
      });
    };

    const [opensummary, setOpenSummary] = React.useState(false);
    const handleOpenSummary = () => setOpenSummary(true);
    const handleCloseSummary = () => setOpenSummary(false);

    const handleSummaryClick = () => {
      setOpenSummary(true);
    };

    const [webhookValue, setWebhookValue] = useState('');

    const handleWebhookSubmit = () => {
      var data = JSON.stringify({
        "webhook_url": webhookValue
      });

      var config = {
        method: 'post',
        url: Base_URL + '/alerts/webhook/',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        swal("Webhook Added!", "", "success").then(function () {
          window.location.reload();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const handleWebhookUpdate = () => {
      var data = JSON.stringify({
        "webhook_name": webhookname,
        "webhook_url": webhookValue
      });

      var config = {
        method: 'patch',
        url: Base_URL + '/alerts/webhook/',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        swal("Webhook URL Updated!", "", "success").then(function () {
          window.location.reload();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const handleMonitorsClick = () => {
      setOpenMonitor(true);
    };

    const handleCloseModal = () => {
      setOpen(false);
    };

    const handleMonitorCloseModal = () => {
      setOpenMonitor(false);
    };
  
    const [modalpage, setModalPage] = useState(0);
    const handlePageChange = (params) => {
      setPage(params.page);
    };

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginLeft: '7%',
      height: 500,
      width: '75%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const webhookstyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginLeft: '7%',
      height: 300,
      width: '75%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const viewdatastyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginLeft: '7%',
      height: 500,
      width: '75%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      overflow:"auto",
      p: 4,
    };

    const summarystyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginLeft: '7%',
      height: 500,
      width: '75%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const SERVER_OPTIONS = {
      useCursorPagination: false,
    };

    const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

    const [paginationModel, setPaginationModel] = React.useState({
      page: 0,
      pageSize: 10,
    });
  
    const [rows, setRows] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    const [webhookurlexists, setWebhookUrlExists] = React.useState();
    const [webhookname, setWebhookName] = React.useState();

    React.useEffect(() => {
      fetchWebhook();
    }, []);

    const fetchWebhook = () => {
      var config = {
        method: 'get',
        url: Base_URL + '/alerts/webhook',
        headers: { }
      };

      axios(config)
      .then(function (response) {
        setWebhookUrlExists(response.data.data.webhookconfig[0]['webhookUrl']);
        setWebhookName(response.data.data.webhookconfig[0]['webhookName']);
        setWebhookStatus(response.data.data.webhookconfig[0]['status']);
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const [modalalertdata, setModalAlertData] = useState({});
    const [hostnames, setHostnames] = useState([]);
    const [selectedHostname, setSelectedHostname] = useState('');
    const [severity, setSeverity] = useState([1,2]);

    const handleCriticalSeverityClick = () => {
      setSeverity([1,2]);
    };

    const handleHighSeverityClick = () => {
      setSeverity([3]);
    };

    const handleLowSeverityClick = () => {
      setSeverity([4]);
    };

    const handleInfoSeverityClick = () => {
      setSeverity([5]);
    };

    React.useEffect(() => {
      getFilter('');
    }, []);

    const getFilter = async => {
      var data = JSON.stringify({
        "from_ts": "",
        "to_ts": ""
      });
      
      var config = {
        method: 'post',
        url: Base_URL + '/alerts/get_filters',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        setHostnames(response.data.data.hostnames);
      })
      .catch(function (error) {
        console.log(error);
      });      
    }
  
    const handleHostnameChange = (event) => {
      setSelectedHostname(event.target.value);
      setIsLoading(true);
  
      const requestData = JSON.stringify({
        size: paginationModel.pageSize,
        page: paginationModel.page,
        hostname: event.target.value ? [event.target.value] : [],
        alertname: [],
        severity: severity,
        from_ts: "",
        to_ts: ""
      });
      
      const config = {
        method: 'post',
        url:  Base_URL + '/alerts',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: requestData,
      };
  
      axios(config)
        .then(function (response) {
          const AlertHistory = response.data.data.alert_data.alerts.map((element, index) => {
            const createdAtDate = new Date(element.created_at);
            const formattedCreatedAt = createdAtDate.toLocaleString();
            const formattedHostname = Array.isArray(element.hostName) ? element.hostName.join(", ") : element.hostName;
            
            return {
              "id": index + 1,
              "alert_id": element.alertId,
              "created_at": formattedCreatedAt,
              "alert_name": element.alertName,
              "data": element.alertData.sections ? element.alertData.sections : [{ 'msg': element.alertData }],
              "severity": element.severity,
              "hostname": formattedHostname
            };
          });
          setRows(AlertHistory);
          setRowCountState(response.data.data.alert_data.totalItems);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  
    React.useEffect(() => {
      fetchDat();
    }, [paginationModel, severity]);

    const fetchDat = () => {
      setIsLoading(true);
  
      const requestData = JSON.stringify({
        size: paginationModel.pageSize,
        page: paginationModel.page,
        hostname: [],
        alertname: [],
        severity: severity,
        from_ts: "",
        to_ts: ""
      });
  
      const config = {
        method: 'post',
        url:  Base_URL + '/alerts',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: requestData,
      };
  
      axios(config)
        .then(function (response) {
          const AlertHistory = response.data.data.alert_data.alerts.map((element, index) => {
            const createdAtDate = new Date(element.created_at);
            const formattedCreatedAt = createdAtDate.toLocaleString();
            const formattedHostname = Array.isArray(element.hostName) ? element.hostName.join(", ") : element.hostName;
            
            return {
              "id": index + 1,
              "alert_id": element.alertId,
              "created_at": formattedCreatedAt,
              "alert_name": element.alertName,
              "data": element.alertData.sections ? element.alertData.sections : [{ 'msg': element.alertData }],
              "severity": element.severity,
              "hostname": formattedHostname
            };
          });
          setRows(AlertHistory);
          setRowCountState(response.data.data.alert_data.totalItems);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const [openviewdata, setOpenViewData] = React.useState(false);
    
    const [viewdata, setViewData] = useState({});
  
    function handleOpenViewData(data, data2, data4) {
      let severityLabel = '';
      switch (data4) {
        case 1:
          severityLabel = 'High';
          break;
        case 2:
          severityLabel = 'High';
          break;
        case 3:
          severityLabel = 'Medium';
          break;
        case 4:
          severityLabel = 'Low';
          break;
        case 5:
          severityLabel = 'Info';
          break;
        default:
          severityLabel = 'Unknown';
          break;
      }
    
      setViewData({
        'DateTime': data2,
        'Severity': severityLabel,
        'data':data
      });
    
      setOpenViewData(true);
    }

    const handleCloseViewData = () => setOpenViewData(false);
  
    const SetupLink = () => {
      window.open('https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=dotnet', '_blank');
    };

    const [severitycount, setSeverityCount] = useState({});
    const [alertsnamecount, setAlertsNameCount] = useState({});
    const [hostsnamecount, setHostsNameCount] = useState({});

    const dataForBarChart = Object.entries(alertsnamecount).map(([name, value], index) => ({
      name,
      value: parseInt(value, 10),
      fill: index
    }));
    const dataForLineChart = Object.entries(alertsnamecount).map(([name, value], index) => ({
      name,
      value: parseFloat(value) * 2,
      stroke: index
    }));

    const dataForBarChartHostname = Object.entries(hostsnamecount).map(([name, value], index) => ({
      name,
      value: parseInt(value, 10),
      fill: index
    }));
    const dataForLineChartHostname = Object.entries(hostsnamecount).map(([name, value], index) => ({
      name,
      value: parseFloat(value) * 2,
      stroke: index
    }));
  
    const colorScale = [
      '#FF5733',
      '#FFBD33',
      '#FFEB33',
      '#33FFA8',
      '#33C4FF',
      '#5733FF',
      '#B233FF',
      '#FF33EA',
      '#FF3367',
      '#33FFD6',
      '#FFD133',
      '#A833FF',
    ];    

    const CustomXAxisTick = ({ x, y, payload }) => {
      const label = "Triggered Rulesets";
      return (
        <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
          {label}
        </text>
      );
    };

    const CustomXAxisTickHostname = ({ x, y, payload }) => {
      const label = "Hosts";
      return (
        <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
          {label}
        </text>
      );
    };

    React.useEffect(() => {
      fetchSummary();
    }, []);

    const fetchSummary = () => {
      var data = JSON.stringify({
        "from_ts": "",
        "to_ts": "",
        "trend_by": "week"
      });

      var config = {
        method: 'post',
        url: Base_URL + '/alerts/summary',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        setSeverityCount(response.data.data.severity.counts);
        setAlertsNameCount(response.data.data.alertname.counts);
        setHostsNameCount(response.data.data.hostname.counts);
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    // function getSeverityColor(severityKey) {
    //   switch (severityKey) {
    //     case '1':
    //       return 'red';
    //     case '2':
    //       return 'green';
    //     case '3':
    //       return 'purple';
    //     case '4':
    //       return 'orange';
    //     case '5':
    //       return 'blue';
    //     default:
    //       return 'black';
    //   }
    // }
    
    // function getSeverityLabel(severityKey) {
    //   switch (severityKey) {
    //     case '1':
    //       return 'CRITICAL';
    //     case '2':
    //       return 'HIGH';
    //     case '3':
    //       return 'MEDIUM';
    //     case '4':
    //       return 'LOW';
    //     case '5':
    //       return 'INFO';
    //     default:
    //       return 'UNKNOWN';
    //   }
    // }
    const counts = severitycount;
    
    const highcount = (parseInt(counts['1']) || 0) + (parseInt(counts['2']) || 0);

    const mediumcount = parseInt(counts['3']) || 0

    const lowcount = parseInt(counts['4']) || 0

    const infocount = parseInt(counts['5']) || 0

    const navigate = useNavigate();

    const AlertHubSetting = () => {
      navigate('/alerting/hubsettings');
    };

  return (
    <>
    <SessionExpiredAlert />
    <SideNav />
    <Header />
    <div>
      <div className="content-wrapper">
      <br />
      <section className="content">
      <div className="container-fluid">
      <div className="row">
      <div className="col-lg-3 col-6" style={{ cursor: 'pointer' }} onClick={handleCriticalSeverityClick}>
        <div className="small-box bg-danger" style={{ width: '100%', height: '70%' }}>
          <div className="inner">
            <h3>{highcount}</h3>
            <p>High</p>
          </div>
          <div className="icon">
            <i className="ion ion-stats-bars" />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-6" style={{ cursor: 'pointer' }} onClick={handleHighSeverityClick}>
        <div className="small-box bg-warning" style={{ width: '100%', height: '70%' }}>
          <div className="inner">
            <h3>{mediumcount}</h3>
            <p>Medium</p>
          </div>
          <div className="icon">
            <i className="ion ion-stats-bars" />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-6" style={{ cursor: 'pointer' }} onClick={handleLowSeverityClick}>
        <div className="small-box bg-success" style={{ width: '100%', height: '70%' }}>
          <div className="inner">
            <h3>{lowcount}</h3>
            <p>Low</p>
          </div>
          <div className="icon">
            <i className="ion ion-stats-bars" />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-6" style={{ cursor: 'pointer', height: '50px' }} onClick={handleInfoSeverityClick}>
        <div className="small-box bg-info" style={{ width: '100%', height: '95px' }}>
          <div className="inner">
            <h3>{infocount}</h3>
            <p>Info</p>
          </div>
          <div className="icon">
            <i className="ion ion-stats-bars" />
          </div>
        </div>
      </div>
      </div>
      </div>
      </section>

      {/* <div className="card-footer">
  <div className="row">
    <div className="col-sm-6">
      <div className="description-block" style={{ marginLeft: '20%', marginTop: '-5%', width: '100%', height: '60px' }}>
        <h5 className="description-header"></h5>
        <br />
        <span
          className="description-text"
          style={{
            color: 'red',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'block'
          }}
        >
          {alertCount}
        </span>
        <span
          className="description-text"
          style={{
            color: 'red',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'block'
          }}
        >
          ALERTS
        </span>
      </div>
    </div>
    <div className="col-sm-6">
      <div className="description-block" style={{ marginLeft: '-15%', marginTop: '-5%', width: '100%', height: '60px' }}>
        <h5 className="description-header"></h5>
        <br />
        <span
          className="description-text"
          style={{
            color: 'blue',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'block'
          }}
        >
          {notificationCount}
        </span>
        <span
          className="description-text"
          style={{
            color: 'blue',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'block'
          }}
        >
          NOTIFICATIONS
        </span>
      </div>
    </div>
  </div>
</div> */}
        {/* <div className="card-footer">
              <div className="row">
                {[1, 2, 3, 4, 5].map((severityKey) => (
                  <div className="col-sm-2 col-6" key={severityKey}>
                    <div className="description-block" style={{ marginLeft: '50%', marginTop: '-20%', width: '100%', height: '60px' }}>
                      <h5 className="description-header"></h5>
                      <br />
                      <span
                        className="description-text"
                        style={{
                          color: getSeverityColor(severityKey.toString()),
                          fontWeight: 'bold',
                          textAlign: 'center',
                          display: 'block'
                        }}
                      >
                        {severitycount[severityKey] || 0}
                      </span>
                      <span
                        className="description-text"
                        style={{
                          color: getSeverityColor(severityKey.toString()),
                          fontWeight: 'bold',
                          textAlign: 'center',
                          display: 'block'
                        }}
                      >
                        {getSeverityLabel(severityKey.toString())} ALERTS
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
        <Box style={{ height: 350, width: '98%', marginLeft: '1%', marginTop: '-2%' }}
          sx={{
          '& .super-app-theme--header': {
            backgroundColor: '#060694',
            color: 'white',
            fontSize: '19px'
            },
          }}
          >
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginTop: '5px' }}>
            <div style={{ marginRight: '47%' }}>
            <FormControl>
              <InputLabel>Hostname</InputLabel>
              <Select value={selectedHostname} onChange={handleHostnameChange} style={{ minWidth: '200px' }}>
                <MenuItem value="">All Hosts</MenuItem>
                {hostnames.map((hostname) => (
                  <MenuItem key={hostname} value={hostname}>
                    {hostname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </div>
            {/* <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '2px', marginRight: '2%' }}>
              <Button variant="outlined" color="success" style={{ marginRight: '10px' }} onClick={handleSummaryClick}>Summary</Button>
              <Button variant="outlined" color="error" style={{ marginRight: '10px' }} onClick={handleAlertSeverityClick}>Alerts</Button>
              <Button variant="outlined" color="primary" onClick={handleNotificationSeverityClick}>Notifications</Button>
            </div> */}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <div style={{ marginTop: '-7%' }}>
            <SettingsIcon style={{ fontSize: '24px', marginRight: '5px', cursor: 'pointer' }} onClick={handleSettingsClick}/>
            <span style={{ fontSize: '20px', fontWeight: 'bold', cursor: 'pointer' }} onClick={handleSettingsClick}>Settings</span>
            </div>
          </div>
          {showDropdown && (
            <div style={{ position: 'absolute', marginLeft: '70%', backgroundColor: '#f9f9f9', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)', padding: '12px', zIndex: '10', width: '140px' }}>
              <div className="dropdownOption" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={handleAlertsClick}><b>Alerts Ruleset</b></div>
              <hr />
              <div className="dropdownOption" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={handleMonitorsClick}><b>Teams Alert</b></div>
            </div>
          )}
        
        <DataGrid
        rows={rows}
        columns={columns}
        rowCount={rowCountState}
        loading={isLoading}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationMode="server"
        pagination={true}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        />
        
        </Box>
        <Modal open={open} onClose={handleCloseModal}>
              <>
              <Box sx={style}>
                  <div>
                  Alerts Ruleset
                  <Button size="small" variant="outlined" onClick={() => AlertHubSetting()} style={{ marginLeft: '45%' }} color="primary">Alert Hub Settings</Button>&nbsp;&nbsp;&nbsp;
                  <Button size="small" onClick={() => UpdateAll(true)} variant="contained" color="success">Enable All</Button>&nbsp;&nbsp;&nbsp;
                  <Button size="small" onClick={() => UpdateAll(false)} variant="outlined" color="error">Disable All</Button>
                  <CloseIcon style={{marginLeft: '1%', cursor:'pointer'}} onClick={handleCloseModal}/><hr />
                  </div>
                  <div style={{ height: '375px', width: '100%' }}>
                  <DataGrid
                    rows={monitordata}
                    columns={monitorcolumns}
                    pagination
                    page={modalpage}
                    onPageChange={handlePageChange}
                    components={{
                      Pagination: GridPagination,
                    }}
                  />
                </div>
                </Box>
                </>
        </Modal>
        <Modal
          open={alertinfoopen}
          onClose={handleAlertInfoClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={viewdatastyle}>
          Alert Info <CloseIcon style={{marginLeft: '90%', cursor:'pointer'}} onClick={handleAlertInfoClose}/><hr />
              
                
                  <Typography variant="body2" color="text.secondary">
                    
                  {viewalertinfodata && Object.keys(viewalertinfodata).map((key, i) => 
                  {
                    if(key=='data')
                    {
                      return <AlertInfoDataBlock alertInfoData={viewalertinfodata[key]}></AlertInfoDataBlock>
                    }
                     
                  }) 
                  }
                  </Typography>
                
              
        
        </Box>
        </Modal>
        <Modal open={openmonitor} onClose={handleMonitorCloseModal}>
        {webhookurlexists ? 
        <Box sx={webhookstyle}>
        Update Webhook
        <CloseIcon style={{ marginLeft: '85%', cursor: 'pointer' }} onClick={handleMonitorCloseModal} />
        <hr />
        In this section, you can configure notification for user feedback from the Qlik app to be sent on your teams channel whenever any feedback / Notify-Admin request is received.
        <TextField
          style={{ width: '100%', marginTop: '20px' }}
          placeholder="Webhook URL"
          // value={webhookurlexists}
          defaultValue={webhookurlexists}
          onChange={(event) => setWebhookValue(event.target.value)}
        />
        <br />
        <br />
        <Button variant="contained" color="success" onClick={handleWebhookUpdate}>
          Update
        </Button>
        {
          webhookstatus == true ? 
          <Button variant="outlined" color="error" style={{ marginLeft: '2%'}} onClick={() => handleWebhookStatusChangeAll()}>
            Deactivate
          </Button>
          :
          <Button variant="outlined" color="success" style={{ marginLeft: '2%'}} onClick={() => handleWebhookStatusChangeAll()}>
            Activate
          </Button>
        }
        <Button variant="outlined" color="primary" style={{ marginLeft: '45%'}} onClick={SetupLink}>
          How to setup webhook channel ?
        </Button>
        </Box> : 
        <Box sx={webhookstyle}>
        Add Webhook
        <CloseIcon style={{ marginLeft: '87%', cursor: 'pointer' }} onClick={handleMonitorCloseModal} />
        <hr />
        In this section, you can configure notification for user feedback from the Qlik app to be sent on your teams channel whenever any feedback / Notify-Admin request is received.
        <TextField
          style={{ width: '100%', marginTop: '20px' }}
          placeholder="Webhook URL"
          value={webhookValue}
          onChange={(event) => setWebhookValue(event.target.value)}
        />
        <br />
        <br />
        <Button variant="contained" color="success" onClick={handleWebhookSubmit}>
          Add
        </Button>
        <Button variant="outlined" color="primary" style={{ marginLeft: '60%'}} onClick={SetupLink}>
          How to setup webhook channel ?
        </Button>
        </Box>
        }
        </Modal>
        <Modal
        open={openviewdata}
        onClose={handleCloseViewData}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow:'scroll'}}
      >
        <Box sx={viewdatastyle}>
          Alert Data <CloseIcon style={{marginLeft: '90%', cursor:'pointer'}} onClick={handleCloseViewData}/><hr />
              <Card >
              <CardContent>
                  <Typography variant="body1" color="text.primary">
                    <div style={{ display: 'flex' }}>
                      <div style={{ display: 'inline-block' }}><b>DateTime:</b> <Chip label={viewdata['DateTime']} color="primary" /></div>
                      <div style={{ display: 'inline-block', marginLeft: '5%' }}> <b>Severity:</b> <Chip label={viewdata['Severity']} color="primary" /></div>
                    </div>
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    
                  {viewdata && Object.keys(viewdata).map((key, i) => 
                  {
                    if(key=='data')
                    {
                     return Object.keys(viewdata[key]).map((key1, i) => {
                        return <AlertDataBlock alertData={viewdata[key][key1]}></AlertDataBlock>
                        
                     })
                    }
                     
                  }) 
                  }
                  </Typography>
                </CardContent>
              </Card>
        
        </Box>
        </Modal>
        <Modal
          open={opensummary}
          onClose={handleCloseSummary}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={summarystyle}>
            {/* <div className="card-footer">
              <div className="row">
                {[1, 2, 3, 4, 5].map((severityKey) => (
                  <div className="col-sm-2 col-6" key={severityKey}>
                    <div className="description-block" style={{ marginLeft: '50%', marginTop: '-10%', width: '100%' }}>
                      <h5 className="description-header"></h5>
                      <br />
                      <span
                        className="description-text"
                        style={{
                          color: getSeverityColor(severityKey.toString()),
                          fontWeight: 'bold',
                          textAlign: 'center',
                          display: 'block'
                        }}
                      >
                        {severitycount[severityKey] || 0}
                      </span>
                      <span
                        className="description-text"
                        style={{
                          color: getSeverityColor(severityKey.toString()),
                          fontWeight: 'bold',
                          textAlign: 'center',
                          display: 'block'
                        }}
                      >
                        {getSeverityLabel(severityKey.toString())} ALERTS
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
            <br />
            <div style={{ display: 'flex' }}>
              <BarChart width={350} height={350} data={dataForBarChart} style={{ marginLeft: '5%', marginTop: '3%'}}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={<CustomXAxisTick />}/>
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8">
                  {dataForBarChart.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colorScale[entry.fill % colorScale.length]} />
                  ))}
                </Bar>
                {/* <Line type="monotone" dataKey="value" stroke="#FF8042">
                  {dataForLineChart.map((entry, index) => (
                    <Cell key={`cell-${index}`} stroke={colorScale[entry.stroke % colorScale.length]} />
                  ))}
                </Line> */}
              </BarChart>
              <PieChart width={350} height={350} style={{ marginLeft: '10%', marginTop: '3%' }}>
      <Pie dataKey="value" data={dataForBarChartHostname} cx="50%" cy="50%" outerRadius={80} fill="#8884d8">
        {dataForBarChartHostname.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colorScale[index % colorScale.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
            </div>
          </Box>
        </Modal>
        {/* <Modal
          open={alertinfoopen}
          onClose={handleAlertInfoClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          HI
        </Modal> */}
        </div>
    </div>   
    <Footer />
    </>
  )
}

export default AlertingDemo