import React, { useEffect, useState } from 'react';
import { Box, Button, FormControlLabel, Grid, MenuItem, Modal, Switch, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Base_URL } from '../constants';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import CloseIcon from '@mui/icons-material/Close';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import { styled } from '@mui/material/styles';
import moment from 'moment'
const ClusterDataDemo = () => {

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const tenant = localStorage.getItem('tenant_name');

  const { uploadTag } = useParams();
  const [tag, setTag] = useState(uploadTag === 'Sample Data' ? 'SampleData-4-17-2023-10-07-50-AM-QV21' : uploadTag);
  const [usertenant, setUserTenant] = useState(uploadTag === 'Sample Data' ? 'd293axplcjg3mzazmty' : tenant)

  const [data, setData] = React.useState({});
  const [newdata, setNewData] = React.useState({});

  const [selectedOption, setSelectedOption] = useState('Repository');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [openModal, setOpenModal] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      flex: 0.1,
      cellClassName: (params) => {
        return 'word-wrap';
      },
    },   
    {
      field: 'pattern',
      headerName: 'Pattern',
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      cellClassName: (params) => {
        return 'word-wrap';
      },
    },      
    { 
      field: 'count', 
      headerName: 'Count', 
      headerClassName: 'super-app-theme--header',
      flex: 0.5, 
      renderCell: (params) => {
        return <span className="badge bg-success" style={{ fontSize: '15px' }}>{params.value}</span>;
      }
    },    
    {
      field: 'actions',
      headerName: 'Actions',
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
      cellClassName: 'word-wrap',
      renderCell: (params) => (
        <Button onClick={() => handleButtonClick(params)}>Details</Button>
      ),
    }
  ];

  const newdatacolumns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      flex: 0.1,
      cellClassName: (params) => {
        return 'word-wrap';
      },
    },
    {
      field: 'pattern',
      headerName: 'Pattern',
      headerClassName: 'super-app-theme--header',
      flex: 5,
      cellClassName: (params) => {
        return 'word-wrap';
      },
    },
    {
      field: 'cop_curr',
      headerName: 'Current Count',
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      cellClassName: (params) => {
        return 'word-wrap';
      },
    },
    {
      field: 'cop_prev',
      headerName: 'Previous Count',
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      cellClassName: (params) => {
        return 'word-wrap';
      },
    },
    {
      field: 'diff',
      headerName: 'Difference',
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      cellClassName: (params) => {
        return 'word-wrap';
      },
    },
    {
      field: 'per_change',
      headerName: '% Change',
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      cellClassName: (params) => {
        return 'word-wrap';
      },
    }
  ];

  const detailcolumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.1,
      cellClassName: (params) => {
        return 'word-wrap';
      },
    },      
    {
      field: 'message',
      headerName: 'Unique & Related Pattern Logs',
      flex: 3,
      cellClassName: (params) => {
        return 'word-wrap';
      },
    },      
    { 
      field: 'count', 
      headerName: 'Count', 
      flex: 0.5, 
      renderCell: (params) => {
        return (
          <span className="badge bg-success" style={{ fontSize: '15px' }}>
            {params.value}
          </span>
        );
      }
    }    
  ];

  const [selectedTimeRange, setSelectedTimeRange] = useState('');
  const [selectedTimeRangePrevious, setSelectedTimeRangePrevious] = useState('');
  const [startdate, setStartDate] = useState(null);
  const [startdatep, setStartDatep] = useState(null);
  const [startdateprevious, setStartDatePrevious] = useState(null);
  const [currentstartdate, setCurrentStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [enddatep, setEndDatep] = useState(null);
  const [enddateprevious, setEndDatePrevious] = useState(null);
  const [currentenddate, setCurrentEndDate] = useState(null);
  const [shownewgrid, setShowNewGrid] = useState(false);

  let initialStartDate = new Date();
  initialStartDate.setHours(0, 0, 0, 0);
  initialStartDate=moment(initialStartDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  let initialEndDate = new Date();
  initialEndDate.setHours(23, 59, 59, 511);
  initialEndDate=moment(initialEndDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')

  const [startdetaildate, setStartDetailDate] = useState(initialStartDate);
  const [enddetaildate, setEndDetailDate] = useState(initialEndDate);

  function handleButtonClick(params) {
    showLoader();
    var data = JSON.stringify({
      "upload_tag": tag,
      "log_type": selectedOption,
      "to_ts": enddetaildate,
      "from_ts": startdetaildate,
      "p_num": params.row.id.toString()
      });
      
      var config = {
      method: 'post',
      url: Base_URL + '/logcluster/' + usertenant+'/current/',
      headers: { 
          'Content-Type': 'application/json'
      },
      data : data
      };
      
      axios(config)
      .then(function (response) {
        hideLoader();
          setApiResponse(response.data.data.map((element, index) => {
          hideLoader();
          setOpenModal(true);
          return {
            "id" : index + 1,
            "message" : element.Message,
            "count" : element.doc_count
          }
        }))
      })
      .catch(function (error) {
        hideLoader();
        console.log(error);
      });
  }
  
  const timeRangeOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 7 days', value: 'last_7_days' },
    { label: 'Last 30 days', value: 'last_30_days' },
    { label: 'Custom range', value: 'custom' },
  ];

  const timeRangeOptionsPrevious = [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 7 days', value: 'last_7_days' },
    { label: 'Last 30 days', value: 'last_30_days' },
    { label: 'Custom range', value: 'custom' },
  ];

  const handleChange = (event) => {
    setSelectedTimeRange(event.target.value);
  };

  const handleChangePrevious = (event) => {
    setSelectedTimeRangePrevious(event.target.value);
  };

  const handleTimeRangeSubmit = async () => {
    showLoader();
    let startDate, endDate;
    
    if (selectedTimeRange === 'today') {
      startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setCurrentStartDate(startDate)
      endDate = new Date();
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate);
      setCurrentEndDate(endDate);
    } else if (selectedTimeRange === 'yesterday') {
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 1);
      startDate.setHours(0, 0, 0, 0);
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setCurrentStartDate(startDate);
      endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      endDate.setHours(23, 59, 59, 511);
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate);
      setCurrentEndDate(endDate)
    } else if (selectedTimeRange === 'last_7_days') {
      endDate = new Date();
      endDate.setHours(23, 59, 59, 511);
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate)
      setCurrentEndDate(endDate)
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 6);
      startDate.setHours(0, 0, 0, 0);
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setCurrentStartDate(startDate)
    } else if (selectedTimeRange === 'last_30_days') {
      endDate = new Date();
      endDate.setHours(23, 59, 59, 511);
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate);
      setCurrentEndDate(endDate)
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 29);
      startDate.setHours(0, 0, 0, 0);
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setCurrentStartDate(startDate)
    } else if (selectedTimeRange === '') {
      startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setCurrentStartDate(startDate)
      endDate = new Date();
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate);
      setCurrentEndDate(endDate)
    } else {
      startDate = new Date(startdate);
      endDate = new Date(enddate);
      startDate.setDate(startDate.getDate());
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setCurrentStartDate(startDate)
      endDate.setHours(23, 59, 59, 511);
      endDate.setDate(endDate.getDate());
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate);
      setCurrentEndDate(endDate)
    }

    var data = JSON.stringify({
    "upload_tag": tag,
    "log_type": selectedOption,
    "to_ts": endDate,
    "from_ts": startDate
    });
    
    var config = {
    method: 'post',
    url: Base_URL + '/logcluster/' + usertenant+'/current/',
    headers: { 
        'Content-Type': 'application/json'
    },
    data : data
    };
    
    axios(config)
    .then(function (response) {
      hideLoader();
      setData(response.data.data.map(element => {
        return {
          "id" : element.pattern_number,
          "pattern" : element.patterns,
          "count" : element.total_doc_count
        }
      }))
    })
    .catch(function (error) {
      hideLoader();
      console.log(error);
    });
  };

  const handleTimeRangeSubmitPrevious = async () => {
    showLoader();
    let startDate, endDate;
    
    if (selectedTimeRangePrevious === 'today') {
      startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setStartDatePrevious(startDate)
      endDate = new Date();
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate);
      setEndDatePrevious(endDate)
      console.log(endDate)
    } else if (selectedTimeRangePrevious === 'yesterday') {
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 1);
      startDate.setHours(0, 0, 0, 0);
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setStartDatePrevious(startDate)
      endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      endDate.setHours(23, 59, 59, 511);
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate);
      setEndDatePrevious(endDate)
    } else if (selectedTimeRangePrevious === 'last_7_days') {
      endDate = new Date();
      endDate.setHours(23, 59, 59, 511);
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate)
      setEndDatePrevious(endDate)
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 6);
      startDate.setHours(0, 0, 0, 0);
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setStartDatePrevious(startDate)
    } else if (selectedTimeRangePrevious === 'last_30_days') {
      endDate = new Date();
      endDate.setHours(23, 59, 59, 511);
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate);
      setEndDatePrevious(endDate)
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 29);
      startDate.setHours(0, 0, 0, 0);
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setStartDatePrevious(startDate)
    } else if (selectedTimeRangePrevious === '') {
      startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setStartDatePrevious(startDate)
      endDate = new Date();
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate);
      setEndDatePrevious(endDate)
    } else {
      startDate = new Date(startdatep);
      endDate = new Date(enddatep);
      startDate.setDate(startDate.getDate());
      startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setStartDetailDate(startDate);
      setStartDatePrevious(startDate)
      endDate.setDate(endDate.getDate());
      endDate.setHours(23, 59, 59, 511);
      endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setEndDetailDate(endDate);
      setEndDatePrevious(endDate)
    }

    var data = JSON.stringify({
    "upload_tag": tag,
    "log_type": selectedOption,
    "from_ts": startDate,
    "to_ts": endDate,
    "c_to_ts": currentenddate,
    "c_from_ts": currentstartdate,
    });
    
    var config = {
    method: 'post',
    url: Base_URL + '/logcluster/' + usertenant+'/previous/',
    headers: { 
        'Content-Type': 'application/json'
    },
    data : data
    };
    axios(config)
    .then(function (response) {
    hideLoader();
    setShowNewGrid(true);
    setNewData(response.data.data.map((element, index) => {
      return {
        "id" : index + 1,
        "cop_prev" : element.count_of_pattern_Previous,
        "cop_curr" : element.count_of_pattern_Current,
        "pattern" : element.Pattern,
        "diff" : element.Difference,
        "per_change": element.Percent_change
      }
    }))
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const CustomFooter = () => null;

  React.useEffect(() => {
    fetchData('');
    
  }, []);

  const fetchData = async page => {
    showLoader();
    let startDate, endDate;
    startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    endDate = new Date();

    startDate=moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    setCurrentStartDate(startDate)
    endDate=moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    setCurrentEndDate(endDate)

    var data = JSON.stringify({
      "upload_tag": tag,
      "log_type": selectedOption,
      "to_ts": endDate,
      "from_ts": startDate
    });
      
      var config = {
      method: 'post',
      url: Base_URL + '/logcluster/' + usertenant+'/current/',
      headers: { 
          'Content-Type': 'application/json'
      },
      data : data
      };
      
      axios(config)
      .then(function (response) {
        setStartDetailDate(startDate);
        setEndDetailDate(endDate);
        
        if (response.data.data === null) {
          hideLoader();
        } else {
          setData(response.data.data.map((element, index) => {
            return {
              "id" : element.pattern_number,
              "pattern" : element.patterns,
              "count" : element.total_doc_count
            }
          }));
          hideLoader();
        }
      })
      .catch(function (error) {
        hideLoader();
        console.log(error);
      });
  }

  function handleCloseModal() {
    setOpenModal(false);
    setApiResponse(null);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginLeft: '7%',
    height: 500,
    width: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const totalRows = data.length;

  const [modalpage, setModalPage] = useState(0);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const [showCompare, setShowCompare] = useState(false);
  
  useEffect(() => {
    if (showCompare === false) {
      setShowNewGrid(false);
    }
  }, [showCompare]);

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
    <SessionExpiredAlert />
    <SideNav />
    <Header />
    <div>
        <div className="content-wrapper">
            <br />
            <div style={{ marginLeft: '45%' }}>
            <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }} />}
                label="Compare"
                checked={showCompare}
                onChange={() => setShowCompare(!showCompare)}
            />
            </div>
            {showCompare ? (
                <Box>
                    <TextField
                        select
                        value={selectedTimeRange}
                        label="Select Current Time Range"
                        variant="outlined"
                        onChange={handleChange}
                        style={{ width: 250, marginLeft: '1%' }}
                        disabled
                        >
                        {timeRangeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </TextField>

                        {selectedTimeRange === 'custom' && (
                        <React.Fragment>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{ marginLeft: '20px', display: 'inline-block' }}>
                                <DatePicker value={startdate} onChange={setStartDate} disabled/>
                            </div>
                            <div style={{ marginLeft: '20px', display: 'inline-block' }}>
                                <DatePicker value={enddate} onChange={setEndDate} disabled/>
                            </div>
                            </LocalizationProvider>
                        </React.Fragment>
                        )}
                        <br />
                        <TextField
                        select
                        value={selectedOption}
                        label="Select Option"
                        variant="outlined"
                        onChange={handleOptionChange}
                        style={{ width: 250, marginLeft: '1%', marginTop: '1%' }}
                        disabled
                        >
                        <MenuItem value="Repository">Repository</MenuItem>
                        <MenuItem value="Engine">Engine</MenuItem>
                        <MenuItem value="Proxy">Proxy</MenuItem>
                        <MenuItem value="Scheduler">Scheduler</MenuItem>
                        <MenuItem value="WindowsEvents">WindowsEvents</MenuItem>
                        </TextField>

                        <TextField
                        select
                        value={selectedTimeRangePrevious}
                        label="Select Previous Time Range"
                        variant="outlined"
                        onChange={handleChangePrevious}
                        style={{ width: 260, marginLeft: '1%', marginTop: '1%' }}
                        >
                        {timeRangeOptionsPrevious.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                        {selectedTimeRangePrevious === 'custom' && (
                        <React.Fragment>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{ marginLeft: '20px', display: 'inline-block', marginTop: '1%' }}>
                                <DatePicker value={startdatep} onChange={setStartDatep} />
                            </div>
                            <div style={{ marginLeft: '20px', display: 'inline-block', marginTop: '1%' }}>
                                <DatePicker value={enddatep} onChange={setEndDatep} />
                            </div>
                            </LocalizationProvider>
                        </React.Fragment>
                        )}

                        <br />
                        <br />
                        <Button
                        variant="contained"
                        onClick={handleTimeRangeSubmitPrevious}
                        style={{ marginLeft: '1%' }}
                        >
                        Apply
                        </Button>
                </Box>
            ) : (
                <Box>
                        <TextField
                        select
                        value={selectedTimeRange}
                        label="Select Current Time Range"
                        variant="outlined"
                        onChange={handleChange}
                        style={{ width: 250, marginLeft: '1%' }}
                        >
                        {timeRangeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </TextField>

                        {selectedTimeRange === 'custom' && (
                        <React.Fragment>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{ marginLeft: '20px', display: 'inline-block' }}>
                                <DatePicker value={startdate} onChange={setStartDate} />
                            </div>
                            <div style={{ marginLeft: '20px', display: 'inline-block' }}>
                                <DatePicker value={enddate} onChange={setEndDate} />
                            </div>
                            </LocalizationProvider>
                        </React.Fragment>
                        )}
                        <br />
                        <TextField
                        select
                        value={selectedOption}
                        label="Select Option"
                        variant="outlined"
                        onChange={handleOptionChange}
                        style={{ width: 250, marginLeft: '1%', marginTop: '1%' }}
                        >
                        <MenuItem value="Repository">Repository</MenuItem>
                        <MenuItem value="Engine">Engine</MenuItem>
                        <MenuItem value="Proxy">Proxy</MenuItem>
                        <MenuItem value="Scheduler">Scheduler</MenuItem>
                        <MenuItem value="WindowsEvents">WindowsEvents</MenuItem>
                        </TextField>
                        <br />
                        <br />
                        <Button
                        variant="contained"
                        onClick={handleTimeRangeSubmit}
                        style={{ marginLeft: '1%' }}
                        >
                        Apply
                        </Button>
                </Box>
            )}
            <br />
            {
              shownewgrid === true ? 
              <Box style={{ height: 500, width: '98%', marginLeft: '1%' }}
                sx={{
                  '& .super-app-theme--header': {
                    backgroundColor: '#060694',
                    color: 'white',
                    fontSize: '18px'
                  },
                }}
              >
                <DataGrid
                  rows={newdata}
                  columns={newdatacolumns}
                  page={page}
                  pageSize={pageSize}
                  rowCount={totalRows}
                  pagination
                  components={{
                    Pagination: GridPagination,
                  }}
                  onPageChange={handleChangePage}
                  onPageSizeChange={handleChangePageSize}
                />
              </Box>
              :
              <Box style={{ height: 500, width: '98%', marginLeft: '1%' }}
                sx={{
                  '& .super-app-theme--header': {
                    backgroundColor: '#060694',
                    color: 'white',
                    fontSize: '18px'
                  },
                }}
              >
                <DataGrid
                  rows={data}
                  columns={columns}
                  page={page}
                  pageSize={pageSize}
                  rowCount={totalRows}
                  pagination
                  components={{
                    Pagination: GridPagination,
                  }}
                  onPageChange={handleChangePage}
                  onPageSizeChange={handleChangePageSize}
                />
            </Box>
            }
            <Modal open={openModal} onClose={handleCloseModal}>
                <>
                <Box sx={style}>
                  Details <CloseIcon style={{marginLeft: '92%', cursor:'pointer'}} onClick={handleCloseModal}/><hr />
                  <div style={{ height: '375px', width: '100%' }}>
                  <DataGrid
                    rows={apiResponse}
                    columns={detailcolumns}
                    pagination
                    page={modalpage}
                    onPageChange={handlePageChange}
                    components={{
                      Pagination: GridPagination,
                    }}
                  />
                </div>
                </Box>
                </>
            </Modal>
        </div>
        {loader}
    </div>
    <Footer />
    </>
  );
};

export default ClusterDataDemo;