import React, { useEffect, useState } from 'react'
import SideNav from '../components/SideNav'
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios';
import { Base_URL, Upload_History } from '../constants';
import { FcSynchronize } from 'react-icons/fc';
import { Close } from '@mui/icons-material';
import { TableChart } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { Box, Button, IconButton, MenuItem, Modal, Select, Switch, TextField, Tooltip } from '@mui/material';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import { DataGrid } from '@mui/x-data-grid';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import HistoricalSideNav from '../components/HistoricalSideNav';
import Uploader from '../components/Uploader';

const Anomaly = () => {
    const [data, setData] = React.useState({});

    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [totalRows, setTotalRows] = React.useState(0);
    const [mlStatus, setMLStatus] = useState(false);

    const navigate = useNavigate();

    const handleMLSearch = (uploadTag) => {
      showLoader();
      const requestData = {
        job_id: jobid,
        upload_tag: uploadTag,
        exec_mode: "all"
      };
    
      axios.post(Base_URL + '/mltasks/jobexec/get_details', requestData, {
        headers: {
          'Content-Type': 'application/json',
          'Cookie': cookies.access_token
        }
      })
      .then((response) => {
        hideLoader();
        if (response.data.status === "success") {
          const { issueTS } = response.data.ml_job_hist; 
          localStorage.setItem('issueTS', issueTS);
          const jobPhase = response.data.ml_job_hist.jobPhase;
          if (jobPhase === "initializing") {
            swal("Initializing AI Insights", "Please wait for some time", "info");
          } else if (jobPhase === "processing") {
            const detailedStatus = response.data.ml_job_hist.detailedStatus;
            if (detailedStatus && detailedStatus.warnerr && detailedStatus.warnerr.jobPhase === "processing") {
              const { total_docs, processed_docs, processed_percentage } = detailedStatus.warnerr;
              const message = `
                Total Docs: ${total_docs}\n
                Processed Docs: ${processed_docs}\n
                Progress: ${processed_percentage.toFixed(2)}%
              `;
              swal("Processing AI Insights", message, "info");
            }
            else if (detailedStatus && detailedStatus.info && detailedStatus.info.jobPhase === "processing") {
              const { total_docs, processed_docs, processed_percentage } = detailedStatus.info;
              const message = `
                Total Docs: ${total_docs}\n
                Processed Docs: ${processed_docs}\n
                Progress: ${processed_percentage.toFixed(2)}%
              `;
              swal("Processing AI Insights", message, "info");
            }
            else if (detailedStatus && detailedStatus.all && detailedStatus.all.jobPhase === "processing") {
              const { total_docs, processed_docs, processed_percentage } = detailedStatus.all;
              const message = `
                Total Docs: ${total_docs}\n
                Processed Docs: ${processed_docs}\n
                Progress: ${processed_percentage.toFixed(2)}%
              `;
              swal("Processing AI Insights", message, "info");
            }
          } else if (jobPhase === "completed") {
            navigate(`/ai_insights/${uploadTag}`);
          } else {
            swal("Initializing AI Insights", "Please wait for some time", "info");
          }
        } else {
          hideLoader();
        }
      })
      .catch((error) => {
        hideLoader();
        console.error("Error fetching ML job details:", error);
      });
    };             

    const [uploadTags, setUploadTags] = useState([]);

    const [cookies] = useCookies(['access_token']);
    const [jobid, setJobId] = useState('');

    const [jobIdFetched, setJobIdFetched] = useState(false);

    useEffect(() => {
      fetchJobId();
    }, []);

    const fetchJobId = () => {
      showLoader();
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: Base_URL + '/mltasks/',
        headers: { 
          'Cookie': cookies.access_token
        }
      };
      
      axios.request(config)
      .then((response) => {
        hideLoader();
        setJobId(response.data.job_detail.jobId);
        setJobIdFetched(true);
      })
      .catch((error) => {
        hideLoader();
        setJobIdFetched(false);
      });
    }    

    useEffect(() => {
      if (jobid) {
        fetchUploadTags();
        execHistory();
      }
    }, [jobid]);

    const fetchUploadTags = () => {
        showLoader();
        axios.post(Base_URL + '/mltasks/list_tags', {
            job_id: jobid
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Cookie': cookies.access_token
            }
        })
        .then(response => {
            hideLoader();
            setUploadTags(response.data.upload_tags);
        })
        .catch(error => {
            hideLoader();
        });
    };

    const [warnerrUploadTags, setWarnerrUploadTags] = useState([]);

    const execHistory = () => {
      showLoader();
      let data = JSON.stringify({
        "job_id": jobid
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/mltasks/jobexec/list',
        headers: { 
          'Content-Type': 'application/json', 
          'Cookie': cookies.access_token
        },
        data : data
      };

      axios.request(config)
      .then((response) => {
        hideLoader();
        if (response.data.status === "success") {
          const execHistoryList = response.data.exec_history_list;
          const warnerrUploadTags = execHistoryList
            .filter(entry => entry.qmode === "warnerr")
            .filter(entry => entry.status === "success")
            .map(entry => entry.uploadTag);
            setWarnerrUploadTags(warnerrUploadTags);
        } else {
          hideLoader();
        }
      })
      .catch((error) => {
        hideLoader();
        console.log(error);
      });
    }
    
    const fetchMLStatus = () => {
      showLoader();
      axios.get(Base_URL + '/mltasks/', { headers: { 'Cookie': 'access_token=' } })
          .then(response => {
              hideLoader();
              setMLStatus(response.data.job_detail.status);
          })
          .catch(error => {
              hideLoader();
          });
    };

    useEffect(() => {
        fetchMLStatus();
    }, []);

    const isUploadTagExists = (uploadTag) => {
      return !uploadTags.includes(uploadTag);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [executionMode, setExecutionMode] = useState('All');

    const handleExecutionModeChange = (event) => {
      setExecutionMode(event.target.value);
    };

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };

    const handleTimeChange = (time) => {
      setSelectedTime(time);
    };

    const openModal = () => {
      setIsModalOpen(true);
    };
    
    const closeModal = () => {
      setIsModalOpen(false);
    };
    
    const [selectedTag, setSelectedTag] = useState('')
    const handleGenAIInsight = (uploadTag) => {
      setSelectedTag(uploadTag);
      openModal();
    };    
    
    const fetchMore = (uploadTag) => {
      showLoader();
      let data = JSON.stringify({
        "job_id": jobid,
        "upload_tag": uploadTag,
        "exec_mode": "all"
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/mltasks/jobexec/get_details',
        headers: { 
          'Content-Type': 'application/json', 
          'Cookie': cookies.access_token
        },
        data : data
      };

      axios.request(config)
      .then((response) => {
        const data = JSON.stringify({
          "job_id": jobid,
          "upload_tag": uploadTag,
          "exec_mode": "all",
          "issue_ts": response.data.ml_job_hist.issueTS
        });
      
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: Base_URL + '/mltasks/jobexec',
          headers: {
            'Content-Type': 'application/json',
            'Cookie': cookies.access_token
          },
          data: data
        };
        axios.request(config)
          .then((response) => {
            hideLoader();
            window.location.reload();
          })
          .catch((error) => {
            hideLoader();
        });
      })
      .catch((error) => {
        hideLoader();
        console.log(error);
      });
    }

    const handleSubmit = () => {
      showLoader();
      if (!selectedDate || !selectedTime || !executionMode) {
        return;
      }
    
      const formattedDate = selectedDate.format('YYYY-MM-DD');
      const formattedTime = selectedTime.format('HH:mm:ss');
      const timezoneOffset = selectedDate.format('Z');
    
      const issue_ts = `${formattedDate}T${formattedTime}${timezoneOffset}`;
      
      if (!issue_ts) {
        return;
      }
    
      const data = JSON.stringify({
        "job_id": jobid,
        "upload_tag": selectedTag,
        "exec_mode": executionMode,
        "issue_ts": issue_ts
      });
    
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/mltasks/jobexec',
        headers: {
          'Content-Type': 'application/json',
          'Cookie': cookies.access_token
        },
        data: data
      };
    
      axios.request(config)
        .then((response) => {
          hideLoader();
          window.location.reload();
        })
        .catch((error) => {
          hideLoader();
        });
    };

    // const renderMLSearchButton = (uploadTag) => {
    //   if (jobIdFetched && isUploadTagExists(uploadTag)) {
    //     return (
    //       <Button variant='outlined' color='success' onClick={() => handleMLSearch(uploadTag)}>AI Insights</Button>
    //     );
    //   } else if (jobIdFetched && !isUploadTagExists(uploadTag)) {
    //     return (
    //       <Button variant='contained' color='primary' onClick={() => handleGenAIInsight(uploadTag)}>Gen AI Insight</Button>
    //     );
    //   } else {
    //     return null;
    //   }
    // };    
    const renderMLSearchButton = (uploadTag) => {
      if (jobIdFetched && isUploadTagExists(uploadTag)) {
        return (
          <>
            <Button variant='outlined' color='success' onClick={() => handleMLSearch(uploadTag)}>AI Insights</Button>
            {warnerrUploadTags.includes(uploadTag) && (
              <Tooltip title="Fetch More">
              <IconButton>
                <FcSynchronize onClick={() => fetchMore(uploadTag)}/>
              </IconButton>
              </Tooltip>
            )}
          </>
        );
      } else if (jobIdFetched && !isUploadTagExists(uploadTag)) {
        return (
          <Button variant='contained' color='primary' onClick={() => handleGenAIInsight(uploadTag)}>Gen AI Insight</Button>
        );
      } else {
        return null;
      }
    };    

    const toggleMLStatus = () => {
      const newJobStatus = !mlStatus;
      const jobStatusText = newJobStatus ? "Enable" : "Disable";
      
      swal({
        title: `Are you sure you want to ${jobStatusText} AI Insights?`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      })
      .then((willToggle) => {
        if (willToggle) {
          showLoader();
          const data = JSON.stringify({
            "jobstatus": newJobStatus
          });
          const config = {
            method: 'post',
            url: Base_URL + '/mltasks',
            headers: { 
              'Content-Type': 'application/json', 
              'Cookie': 'access_token='
            },
            data: data
          };
          axios.request(config)
            .then((response) => {
              hideLoader();
              window.location.reload();
            })
            .catch((error) => {
              hideLoader();
            });
        } else {
          
        }
      });
    };  
     

    const columns = [
        {
          field: 'uploadTime',
          headerName: 'Upload Date',
          headerClassName: 'super-app-theme--header',
          flex: 0.5,
          cellClassName: (params) => {
            return 'word-wrap';
          },
          valueGetter: (params) => {
            if (params.value === '2022-03-15T10:00:00.000Z') {
              return '-';
            }
            const date = new Date(params.value);
            const formattedDate = date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
            const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute:'2-digit', second: '2-digit', hour12: true });
            return `${formattedDate} ${formattedTime}`;
          },
        },      
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'Upload Tag', flex: 0.5, cellClassName: (params) => {
          return 'word-wrap';
        } },
        { field: 'ingestionStatus', headerClassName: 'super-app-theme--header', headerName: 'Status Data', flex: 0.5, cellClassName: (params) => {
          return 'word-wrap';
        }, valueGetter: (params) => {
            const status = params.value;
            if (status === 'completed') {
              return 'Ingestion-Completed';
            } else if (status === 'pending') {
              return 'Ingestion-Pending';
            } else if (status === 'in_progress') {
              return 'Ingestion-In Progress';
            }
             else {
              return status;
            }
          }, },
          {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            flex: 0.5,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              if (params.row.id === 'Sample Data') {
                const uploadTag = params.row.id;
                const qlikversion = params.row.qlikVersion;
                const to = `/clusterdata/${uploadTag}`;
                return (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Link to={to}>
                      <Button style={{backgroundColor: '#060694'}} variant="contained">Sample AI Insights</Button>
                    </Link>
                  </div>
                );
              } else {
                const uploadTag = params.row.id;
                const qlikversion = params.row.qlikVersion;
                const to = `/clusterdata/${uploadTag}`;

                const handleDelete = () => {
                  swal({
                    title: 'Are you sure you want to delete the file upload history?',
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                  })
                  .then((willDelete) => {
                    if (willDelete) {
                      showLoader();
                      const tenant = localStorage.getItem('tenant_name');
          
                      var data = JSON.stringify({
                        "upload_tag": uploadTag
                      });
                      
                      var config = {
                        method: 'delete',
                        url: Base_URL + '/uploadhistory/' + tenant,
                        headers: { 
                          'Content-Type': 'application/json'
                        },
                        data : data
                      };

                      axios(config)
                      .then(function (response) {
                        hideLoader();
                        swal({
                          icon: 'success',
                          title: 'File Upload History Deleted Successfully'
                        }).then(function() {
                          window.location.reload();
                        });
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                    } else {
                      swal('File Upload History was not deleted.');
                    }
                  });
                };
                
                if(params.row.deleteStatus) {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '17px' }}>
                      Deleted
                    </div>
                  );
                }else{
                  return (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Link to={to}>
                        <TableChart />
                      </Link>
                      <div style={{ marginLeft: '8px', marginRight: '8px' }}></div>
                      <DeleteForeverIcon onClick={handleDelete} style={{ cursor: 'pointer', fontSize: 30, color: 'red' }} />
                      &nbsp;&nbsp;{renderMLSearchButton(params.row.id)}
                    </div>
                  );
                }
              }
            },
          },
    ];

    React.useEffect(() => {
        fetchData('');
    }, []);

    // const fetchData = async page => {
    //     const tenant = localStorage.getItem('tenant_name');

    //     var config = {
    //     method: 'get',
    //     url: Base_URL + Upload_History + tenant + page,
    //     headers: { }
    //     };

    //     axios(config)
    //     .then(function (response) {
    //         const UploadHistory = response.data.data.uploadhsitory.map((element) => {
    //         return {
    //             id: element.uploadTag,
    //             uploadTime: element.uploadTime,
    //             ingestionStatus: element.ingestionStatus,
    //             qlikVersion: element.qlikVersion,
    //             deleteStatus: element.deleteStatus
    //         };
    //         });
    //         UploadHistory.sort((a, b) => new Date(b.uploadTime) - new Date(a.uploadTime));
            
    //         const randomDate = new Date('2022-03-15T10:00:00.000Z');
    //         UploadHistory.unshift({
    //             id: 'Sample Data',
    //             uploadTime: randomDate.toISOString(),
    //             ingestionStatus: 'Ingestion-Completed',
    //             qlikVersion: 'QV00'
    //         });

    //         setData(UploadHistory);
    //         setTotalRows(response.data.data.uploadhsitory.uploadTag);
    //     })
    //     .catch(function (error) {
    //     console.log(error);
    //     });
    // }

    const fetchData = async (page) => {
      const tenant = localStorage.getItem('tenant_name');
    
      var config = {
        method: 'get',
        url: Base_URL + Upload_History + tenant + page,
        headers: {},
      };
    
      axios(config)
        .then(function (response) {
          const UploadHistory = response.data.data.uploadhsitory.map((element) => {
            return {
              id: element.uploadTag,
              uploadTime: element.uploadTime,
              ingestionStatus: element.ingestionStatus,
              qlikVersion: element.qlikVersion,
              deleteStatus: element.deleteStatus,
            };
          });
    
          if (tenant === 'd293axplcju2mtyyodq') {
            UploadHistory.push({
            id: 'BOB-11Jul2024-NB-Logs-7-11-2024-12-12-45-PM-QV21',
            uploadTime: '2024-07-11T12:12:45.000Z',
            ingestionStatus: 'Ingestion-Completed',
            qlikVersion: 'QV21',
            deleteStatus: false,
            });
          }
          
          UploadHistory.sort((a, b) => new Date(b.uploadTime) - new Date(a.uploadTime));
    
          const randomDate = new Date('2022-03-15T10:00:00.000Z');
          UploadHistory.unshift({
            id: 'Sample Data',
            uploadTime: randomDate.toISOString(),
            ingestionStatus: 'Ingestion-Completed',
            qlikVersion: 'QV00',
          });
    
          setData(UploadHistory);
          setTotalRows(response.data.data.uploadhsitory.uploadTag);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    
    const CustomFooter = () => null;

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    
    const handleRedirect = () => {
      localStorage.setItem("logtype", "sample");
      navigate('/analyzestaticlogs/Sample Data/QV00/3');
      var data = JSON.stringify({
        "dash_type": "sample_dashboard",
        "upload_tag": cookies.sample_upload_tag
      });

      var config = {
        method: 'post',
        url: Base_URL + '/users/dashboard',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        
      })
      .catch(function (error) {
        
      });

    };

  return (
    <>
    <SessionExpiredAlert />
    <HistoricalSideNav />
    <Header />
    <div>
        <div className="content-wrapper">
            <div className="uploader-container" style={{ display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
              <div className="uploader">
                <Uploader />
              </div>
              <button
                style={{
                  backgroundColor: '#060694',
                  color: 'white',
                  height: '50px',
                  width: '200px',
                  marginLeft: '10px',
                }}
                type="button"
                className="btn sample-btn"
                onClick={handleRedirect}
              >
                <span>Try with Sample Data</span>
              </button>
            </div>
            <Box style={{ height: 500, width: '98%', marginLeft: '1%' }}
            sx={{
              '& .super-app-theme--header': {
                backgroundColor: '#060694',
                color: 'white',
                fontSize: '19px'
              },
            }}
            >
                <div>
                  {mlStatus ? "Disable AI Insights" : "Enable AI Insights"}
                  <Switch checked={mlStatus} onChange={toggleMLStatus} />
                </div>
                <DataGrid
                rows={data}
                columns={columns}
                components={{
                    Footer: CustomFooter,
                }}
                />
            </Box>
        </div>
    </div>   
    
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
        style={{ position: 'absolute', top: 0, right: 0 }}
        onClick={closeModal}
        >
          <Close />
        </IconButton>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '8px' }}>Select Issue Date:</span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '8px' }}>Select Issue Time:</span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={selectedTime}
                onChange={handleTimeChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '8px' }}>Execution Mode:</span>
            <Select value={executionMode} onChange={handleExecutionModeChange}>
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="warnerr">Warn/Error</MenuItem>
            </Select>
          </div>
          <Button variant="contained" onClick={handleSubmit} disabled={!selectedDate || !selectedTime || !executionMode}>Submit</Button>
        </div>
      </Box>
    </Modal>

    {loader}
    <Footer />
    </>
  )
}

export default Anomaly