import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Base_URL } from '../constants';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Card, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Modal, Select, Switch, Tab, Tabs, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Close } from '@mui/icons-material';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import { FcComboChart } from "react-icons/fc";
import { DatePicker, LocalizationProvider, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

const MlsearchDemo = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [searchQuery, setSearchQuery] = useState('');
  const { uploadTag } = useParams();
  const [cookies] = useCookies(['access_token']);

  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [ureqid, setUreqid] = useState('');
  const [showNewApplyButton, setShowNewApplyButton] = useState(false);
  const [disableSearchQuery, setDisableSearchQuery] = useState(false);

  const [modalData, setModalData] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);

  const [hostnames, setHostnames] = useState('');

  const [selectedHostname, setSelectedHostname] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('');

  const [compareData, setCompareData] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  
  const issueTS = localStorage.getItem('issueTS');
  const [value, setValue] = React.useState(dayjs(issueTS));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 1000,
    height: 525,
    marginTop: '-250px',
    marginLeft: '-500px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [jobid, setJobId] = useState('');

  useEffect(() => {
    fetchJobId();
  }, []);

  const fetchJobId = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: Base_URL + '/mltasks/',
      headers: { 
        'Cookie': cookies.access_token
      }
    };
    
    axios.request(config)
    .then((response) => {
      setJobId(response.data.job_detail.jobId);
    })
    .catch((error) => {
      console.log(error);
    });
  }    

  const handleViewDetails = (patternNumber) => {
    showLoader();
    setModalLoading(true);
    const data = JSON.stringify({
      "job_id": jobid,
      "upload_tag": uploadTag,
      "query_text": searchQuery,
      "ureqid": ureqid,
      "p_num": patternNumber.toString(),
    });
    
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Base_URL + '/mltasks/search',
      headers: {
        'Content-Type': 'application/json',
        'Cookie': cookies.access_token
      },
      data: data
    };
  
    axios.request(config)
      .then((response) => {
        hideLoader();
        const rowData = response.data.data.map((item, index) => ({
          ...item,
          id: index
        }));        
        setModalData(rowData);
        setModalLoading(false);
        setOpen(true);
      })
      .catch((error) => {
        hideLoader();
        console.log(error);
        setModalLoading(false);
      });
  };  
  
  const columns = [
    { field: 'patterns', headerName: 'Patterns',
    width: 200,
    headerClassName: 'column-header' },
    { field: 'total_doc_count', headerName: 'Total Doc Count', 
    width: 125,
    headerClassName: 'column-header' },
    { 
      field: 'log_source', 
      headerName: 'Log Source', 
      width: 250,
      headerClassName: 'column-header', 
      renderCell: (params) => {
        const initialDisplayLimit = 2;
        const initialSources = params.value.slice(0, initialDisplayLimit);
        const remainingSources = params.value.slice(initialDisplayLimit);
        
        return (
          <div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {initialSources.map((source, index) => (
                <div key={index} style={{ marginRight: '10px', marginBottom: '5px' }}>{`${source.name} (${source.count})`}</div>
              ))}
            </div>
            
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {remainingSources.map((source, index) => (
                <div key={index} style={{ marginRight: '10px', marginBottom: '5px' }}>{`${source.name} (${source.count})`}</div>
              ))}
            </div>
          </div>
        );
      },      
    },
    { 
      field: 'level', 
      headerName: 'Level', 
      width: 150, 
      headerClassName: 'column-header', 
      renderCell: (params) => {
        const initialDisplayLimit = 2;
        const initialLevels = params.value.slice(0, initialDisplayLimit);
        const remainingLevels = params.value.slice(initialDisplayLimit);
    
        return (
          <div style={{ position: 'relative' }}>
            <div>
              {initialLevels.map((level, index) => (
                <div key={index}>{`${level.name} (${level.count})`}</div>
              ))}
            </div>
    
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {remainingLevels.map((level, index) => (
                <div key={index} style={{ marginRight: '10px', marginBottom: '5px' }}>{`${level.name} (${level.count})`}</div>
              ))}
            </div>
          </div>
        );
      },
    },    
    { 
      field: 'wow_hostname', 
      headerName: 'Hostname', 
      width: 185, 
      headerClassName: 'column-header', 
      renderCell: (params) => {
        const initialDisplayLimit = 2;
        const initialHostnames = params.value.slice(0, initialDisplayLimit);
        const remainingHostnames = params.value.slice(initialDisplayLimit);
    
        return (
          <div style={{ position: 'relative' }}>
            <div>
              {initialHostnames.map((hostname, index) => (
                <div key={index}>{`${hostname.name} (${hostname.count})`}</div>
              ))}
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {remainingHostnames.map((hostname, index) => (
                <div key={index} style={{ marginRight: '10px', marginBottom: '5px' }}>{`${hostname.name} (${hostname.count})`}</div>
              ))}
            </div>
          </div>
        );
      },
    },    
    {
      field: 'actions',
      headerName: 'Actions',
      width: 125,
      headerClassName: 'column-header',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleViewDetails(params.row.pattern_number)}
        >
          View Details
        </Button>
      ),
    },
  ];
  
  const logCompareColumns = [
    { field: 'Pattern', headerName: 'Pattern', width: 250, headerClassName: 'column-header' },
    { field: 'count_of_pattern_Previous', headerName: 'Ok Count', width: 150, headerClassName: 'column-header' },
    { field: 'count_of_pattern_Current', headerName: 'Issue Count', width: 150, headerClassName: 'column-header' },
    { field: 'Difference', headerName: 'Difference', width: 150, headerClassName: 'column-header' },
    { field: 'Percent_change', headerName: '% Change', width: 150, headerClassName: 'column-header' },
    {
      field: 'tags',
      headerName: 'Tags',
      headerClassName: 'column-header',
      flex: 1.5,
      cellClassName: (params) => {
        let className = 'word-wrap ';
        const percentChange = params.row.Percent_change;
        const copPrev = params.row.count_of_pattern_Previous;
        if (copPrev === 0) {
          className += '';
        } else if (percentChange > 0) {
          className += '';
        } else if (percentChange === 0) {
          className += '';
        } else if (percentChange < 0 && percentChange !== -100) {
          className += '';
        } else if (percentChange === -100) {
          className += '';
        }
    
        return className;
      },
      valueGetter: (params) => {
        const percentChange = params.row.Percent_change;
        const copPrev = params.row.count_of_pattern_Previous;
        
        if (copPrev === 0) {
          return 'New';
        }
        else if (percentChange > 0) {
          return 'Increasing';
        }   else if (percentChange === 0) {
          return 'Unchanged';
        } else if (percentChange < 0 && percentChange !== -100) {
          return 'Decreasing';
        } else if (percentChange === -100) {
          return 'Disappeared';
        } else {
          return '';
        }
      },
    },
  ];  

  const modalColumns = [
    { field: 'wow_timestamp', headerName: 'Timestamp', width: 175, headerClassName: 'column-header',
      valueGetter: (params) => {
      const timestamp = new Date(params.value);
      return timestamp.toLocaleString();
      }
    },
    { field: 'message', headerName: 'Message', width: 430, headerClassName: 'column-header' },
    { field: 'log_source', headerName: 'Log Source', width: 100, headerClassName: 'column-header' },
    { 
      field: 'level', 
      headerName: 'Level', 
      width: 100, 
      headerClassName: 'column-header', 
      renderCell: (params) => params.value ? `${params.value}` : 'NONE'
    },
    { 
      field: 'view', 
      headerName: 'View', 
      width: 124, 
      headerClassName: 'column-header', 
      renderCell: (params) => (
        <Button variant="contained"
        color="primary"
        size="small"
        onClick={() => handleRowClick(params)}>View Nearby</Button>
      )
    }
  ];

  useEffect(() => {
    axios.post(Base_URL + '/logcluster/get_hostnames', {
      upload_tag: uploadTag
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Cookie': cookies.access_token
      }
    })
    .then(response => {
        setHostnames(response.data.data);
    })
    .catch(error => {
      console.error('Error fetching hostnames:', error);
    });
  }, [uploadTag]);

  const handleApply = () => {
    setLoading(true);
    setDisableSearchQuery(true);
    const data = JSON.stringify({
      "job_id": jobid,
      "upload_tag": uploadTag,
      "query_text": searchQuery,
      "host_name": selectedHostname,
      "log_src": Array.isArray(selectedItem) ? selectedItem : [selectedItem],
      "level_type": selectedLevel
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Base_URL + '/mltasks/search',
      headers: {
        'Content-Type': 'application/json',
        'Cookie': cookies.access_token
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        const rowData = response.data.data.map(item => ({
          ...item,
              log_source: item.log_source ? Object.entries(item.log_source).map(([key, value]) => ({ name: key, count: value })) : [], // Convert object to array of objects with name and count
              level: item.level ? Object.entries(item.level).map(([key, value]) => ({ name: key, count: value })) : [], // Convert object to array of objects with name and count
              wow_hostname: item.wow_hostname ? Object.entries(item.wow_hostname).map(([key, value]) => ({ name: key, count: value })) : [] // Convert object to array of objects with name and count
        }));        
        setRowData(rowData);
        setUreqid(response.data.ureqid)
        setLoading(false); 
        setShowNewApplyButton(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); 
        setShowNewApplyButton(false);
      });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleReset = () => {
    setSearchQuery('');
    setRowData([]);
    setSelectedHostname('');
    setSelectedItem('');
    setSelectedLevel('');
    setShowNewApplyButton(false);
    setDisableSearchQuery(false);
    setShowCompare(false);
    setCompareData(null);
  };

  useEffect(() => {
  }, [uploadTag]);
  
  const navigate = useNavigate();

  const handleRowClick = (event) => {
    const uploadtag = uploadTag;
    const wowTimestamp = new Date(event.row.wow_timestamp);
    const hostname = selectedHostname;
    const logsource = selectedItem;
    const level = selectedLevel;

    const startDate = new Date(wowTimestamp);
    startDate.setMinutes(startDate.getMinutes() - 1);

    const endDate = new Date(wowTimestamp);
    endDate.setMinutes(endDate.getMinutes() + 1);

    const startDateISOString = startDate.toISOString();
    const endDateISOString = endDate.toISOString();

    const url = `/mldiscover?uploadtag=${uploadtag}&startDate=${startDateISOString}&endDate=${endDateISOString}&hostname=${hostname}&logsource=${logsource}&level=${level}`;
    navigate(url);
  };

  const [showAdvanceQuery, setShowAdvanceQuery] = useState(false);
  const [showCompare, setShowCompare] = useState(false);
  
  useEffect(() => {
    if (showCompare) {
      setTabValue(1);
    }
  }, [showCompare]);

  useEffect(() => {
    if (!showCompare) {
      setTabValue(0);
      setCompareData(null);
    }
  }, [showCompare]);

  const callCompareAPI = (jobId, uploadTag, ureqid, value) => {
    setLoading(true);
    const currentTimestamp = new Date().getTime();

    const twentyFourHours = 24 * 60 * 60 * 1000;
    const startTsBefore24Hours = new Date(currentTimestamp - twentyFourHours).toISOString();
    const endTsAfter24Hours = new Date(currentTimestamp + twentyFourHours).toISOString();

    const data = {
      job_id: jobId,
      upload_tag: uploadTag,
      ureqid: ureqid,
      ok_ts: {
        start_ts: startTsBefore24Hours,
        end_ts: value
      },
      issue_ts: {
        start_ts: value,
        end_ts: endTsAfter24Hours
      }
    };
  
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Base_URL + '/mltasks/log_compare',
      headers: { 
        'Content-Type': 'application/json',
        'Cookie': cookies.access_token
      },
      data: JSON.stringify(data)
    };
    
    axios.request(config)
      .then((response) => {
        setCompareData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const matchingPatterns = compareData && compareData.log_compare ? compareData.log_compare.filter((row) =>
    compareData.ml_patterns_list.some((item) => item.patterns === row.Pattern)
  ) : [];
  
  const nonMatchingPatterns = compareData && compareData.log_compare ? compareData.log_compare.filter((row) =>
    !compareData.ml_patterns_list.some((item) => item.patterns === row.Pattern)
  ) : [];
  

  const getRowClassName = (params) => {
    const isMatchingPattern = matchingPatterns.some(
      (row) => row.Pattern === params.row.Pattern
    );
    return isMatchingPattern ? 'matching-pattern-row' : '';
  };
    
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  return (
    <>
  <SessionExpiredAlert />
  <SideNav />
  <Header />
  <div className="content-wrapper">
    <br />
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginLeft: '2%' }}>
  <TextField
    label="Enter search query"
    variant="outlined"
    value={searchQuery}
    onChange={handleSearchChange}
    style={{ marginRight: '10px' }}
    disabled={disableSearchQuery}
  />
  {showNewApplyButton ? (
    <>
      <>
        <Button variant="contained" onClick={handleApply} style={{ marginRight: '10px' }}>Apply</Button>
        <Button variant="outlined" onClick={handleReset}>Reset</Button>
      </>
      <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
        {showAdvanceQuery && (
          <div style={{ display: 'flex' }}>
            <FormControl style={{ width: '170px', marginRight: '10px' }}>
                        <InputLabel id="item-label">Log Source</InputLabel>
                        <Select
                            labelId="item-label"
                            id="item-select"
                            value={selectedItem}
                            label="Log Souurce"
                            onChange={(event) => setSelectedItem(event.target.value)}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Repository">Repository</MenuItem>
                            <MenuItem value="Engine">Engine</MenuItem>
                            <MenuItem value="Proxy">Proxy</MenuItem>
                            <MenuItem value="Scheduler">Scheduler</MenuItem>
                            <MenuItem value="WindowsEvents">WindowsEvents</MenuItem>
                        </Select>
                    </FormControl>
            <FormControl style={{ width: '170px', marginRight: '10px' }}>
                        <InputLabel id="item-label">Level</InputLabel>
                        <Select
                            labelId="item-label"
                            id="item-select"
                            value={selectedLevel}
                            label="Log Souurce"
                            onChange={(event) => setSelectedLevel(event.target.value)}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="WARNING">Warning</MenuItem>
                            <MenuItem value="ERROR">Error</MenuItem>
                            <MenuItem value="INFO">Info</MenuItem>
                        </Select>
                    </FormControl>
            <FormControl style={{ width: '170px', marginRight: '10px' }}>
                        <InputLabel id="demo-simple-select-label">Hostname</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedHostname}
                        label="Hostname"
                        onChange={(event) => setSelectedHostname(event.target.value)}
                        >
                        <MenuItem value=''>All</MenuItem>
                        {hostnames.map((hostname, index) => (
                                    <MenuItem key={index} value={hostname}>{hostname}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
          </div>
        )}
        <Button 
          variant="contained"
          onClick={() => setShowAdvanceQuery(!showAdvanceQuery)}
        >
          {showAdvanceQuery ? 'Cancel' : 'Advance Query'}
        </Button>
      </div>
      <div style={{ marginLeft: '2%' }}>
    <FormControlLabel
      control={<Switch checked={showCompare} onChange={() => setShowCompare(!showCompare)} />}
      label="Compare"
    />
    </div>
    </>
  ) : (
    <>
      <Button variant="contained" onClick={handleApply} style={{ marginRight: '10px' }}>Apply</Button>
      <Button variant="outlined" onClick={handleReset}>Reset</Button>
    </>
  )}
</div>
{showCompare && (
    <>
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '2%' }}>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
              <TimePicker
                value={selectedTime}
                onChange={handleTimeChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider> */}
             <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                  <DateTimePicker
                    label="Issue Date Time"
                    defaultValue={dayjs(value)}
                  />
                  {/* <DateTimePicker
                    label="Controlled picker"
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                  /> */}
                </DemoContainer>
              </LocalizationProvider>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant='outlined' onClick={() => callCompareAPI(jobid, uploadTag, ureqid, value)}>Compare</Button>
          </div>
      </>
    )}
    <div style={{ height: 300, width: '100%' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
        <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="AI Insight Data" />
        <Tab label="Compare Data" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
        <DataGrid
          rows={rowData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={(row) => row.pattern_number}
          style={{ height: 300, width: '100%' }}
        />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
        <DataGrid
          rows={[...matchingPatterns, ...nonMatchingPatterns]}
          columns={logCompareColumns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={(row) => row.Pattern}
          getRowClassName={getRowClassName}
          style={{ height: 300, width: '100%' }}
        />
        </TabPanel>
        </>
      )}
    </div>
  </div>
  <div>
    <Button onClick={handleOpen}>Open modal</Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton onClick={handleClose} style={{ position: 'absolute', top: 10, right: 20 }}>
          <Close />
        </IconButton>
        <br />
        <DataGrid
          rows={modalData}
          columns={modalColumns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          loading={modalLoading}
          getRowId={(row) => row.id}
        />
      </Box>
    </Modal>
    {loader}
  </div>
  <Footer />
</>

  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default MlsearchDemo;