import Footer from "../components/Footer";
import Header from "../components/Header";
import SideNav from "../components/SideNav";
import '../App.css';
import { useNavigate } from "react-router-dom";
import SessionExpiredAlert from "../components/SessionExpiredAlert";
import Uploader from "../components/Uploader";
import { Base_URL } from "../constants";
import axios from 'axios';
import { useCookies } from "react-cookie";

const Home = () => {
    
    const [cookies, removeCookie ] = useCookies(['access_token', 'sample_token','kibana_token']);
    
    const navigate = useNavigate();

    const handleRedirect = () => {
      localStorage.setItem("logtype", "sample");
      navigate('/analyzelogs/Sample Data/QV00/3');
      var data = JSON.stringify({
        "dash_type": "sample_dashboard",
        "upload_tag": cookies.sample_upload_tag
      });

      var config = {
        method: 'post',
        url: Base_URL + '/users/dashboard',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        
      })
      .catch(function (error) {
        
      });

    };

    return (
        <>
        <SessionExpiredAlert />
        <SideNav />
        <Header />
        <div>
            <div className="content-wrapper">
            <br />
            <div className="card" style={{width: '51%', marginLeft: '20%', marginTop:'3%'}}>
            <div className="card-header border-0">
            </div>
            <div className="card-body">
              <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card card-primary card-outline" style={{width : '510px', height: '100px', marginLeft: '2%'}}>
                      <div className="card-body">
                        <p className="card-text">
                          Here are the Instructions to collect logs via QlikSense Log Collector
                        </p>
                        <a href="https://github.com/wowizer/public/blob/428146cbedfa40e0c3171c11ab3a559754dbb16b/Sense%20Collector%20and%20Repository%20Data/QlikSenseLogCollectionSteps.pdf" className="card-link" target="_blank">Instructions</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '2%', marginTop: '9%' }}>
              <button style={{backgroundColor: '#060694'}} type="button" className="btn sample-btn" onClick={handleRedirect}>
                <span style={{ color: 'white' }}>Try with Sample Data</span>
              </button>
              <div className="uploader" style={{ marginLeft: '5%' }}>
                <Uploader />
              </div>
              </div>
              <div style={{ fontWeight: 'bold', fontSize: '14px',marginLeft: '2%', marginTop: '17%'}}>
              {
              localStorage.getItem('userRole') === "trialuser" ?
              <>
              *Note: WoWizer TPM Test Drive does not have the functionality to upload data. Kindly proceed with the complete <a href="/register" target="_blank">sign-up</a> to unlock all features.
              </>
              :
              <>
              *Note: Contact us at <a href="mailto: info@wowizer.ai">info@wowizer.ai</a> if you need a higher data upload limit.
              </>
              }
            </div>
            </div>
            </div>
            </div>
        </div>
        <Footer />
      </>
    );
  };
  
  export default Home;